import React, {useState, useEffect} from 'react';
import ContainerLoading from '../../../components/Theme/Loading';
import api from '../../../services/api';
import PropTypes from 'prop-types';
import stripTags from 'striptags';
import format from '../../../utils/format';
import {
  CursoItem,
  Cursoimage,
  InfoCurso,
  Stars,
  Duration,
  Views,
  DadosCurso,
  TitleCurso,
  DescriptionCurso,
  UserImg,
  Icon,
  ContainerPriceCourse,
  PriceCourse,
  DiscountPrice,
  NormalPrice,
  DeleteIcon,
} from './style';

import {useCheckout} from '../../../store/Checkout';

const Cart = ({course, ...props}) => {
  const [evaluation, setEvaluation] = useState(course.nota);
  const [loading, setLoading] = useState(true);
  const {RemoveItem} = useCheckout();

  // useEffect(() => {
  //   api
  //     .get(
  //       `/general/currentUserEvaluations?content_iri=/lms/courses/${course.id}`,
  //     )
  //     .then((response) => {
  //       const res = response.data || [];
  //       const ratings =
  //         res.filter((el) => el.type === 'RATING').length > 0
  //           ? res.filter((el) => el.type === 'RATING')[0].average
  //           : 5;
  //       setEvaluation({ratings});
  //       setLoading(true);
  //     })
  //     .catch((err) => {
  //       console.error('Erro na nota', err);
  //     });
  // }, []);

  const handleDeleteCourse = () => {
    RemoveItem(course.id);
  };

  const stars = [];
  for (var i = 0; i < 5; i++) {
    evaluation.ratings > i ? stars.push('fas') : stars.push('far');
  }
  return loading === false ? (
    <ContainerLoading />
  ) : (
    <CursoItem>
      {course.image && course.image.urlPublica && (
        <UserImg
          className="img"
          src={course.image.urlPublica}
          alt="{props.titulo}"
        />
      )}
      <DeleteIcon className="delete-icon" onClick={handleDeleteCourse}>
        <i className={`far fa-trash-alt`} />
      </DeleteIcon>
      <div>
        <DadosCurso>
          <TitleCurso>{course.titulo}</TitleCurso>
          <DescriptionCurso
            dangerouslySetInnerHTML={{
              __html: stripTags(course.description),
            }}></DescriptionCurso>
          <ContainerPriceCourse className="container-price">
            <InfoCurso>
              {
                <Stars>
                  {stars.map((star, i) => (
                    <i key={`star-${i}`} className={`${star} fa-star`} />
                  ))}
                  <span className="font-italic"> {evaluation.ratings}/5</span>
                </Stars>
              }
              <Duration>
                <Icon className="far fa-clock" />
                {course.duration}
              </Duration>
              <Views>
                <Icon className="fas fa-eye" />
                {course.views}
              </Views>
            </InfoCurso>
            <PriceCourse className="price">
              {course.price > 0 && (
                <DiscountPrice className="normal-price">
                  R$ {course.price.toFixed(2)}
                </DiscountPrice>
              )}
              {course.promotional_price > 0 && (
                <NormalPrice className="discount-price">
                  R$ {course.promotional_price.toFixed(2)}
                </NormalPrice>
              )}
            </PriceCourse>
          </ContainerPriceCourse>
        </DadosCurso>
      </div>
    </CursoItem>
  );
};

Cart.propTypes = {
  course: PropTypes.object.isRequired,
};

export default Cart;
