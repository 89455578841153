import React, { useEffect, useState } from 'react';
import { loadPage } from '../../utils/loadPage';
import { Container, Col, Row } from 'reactstrap';
import Header from './Header';
import Footer from './Footer';
import Main from './Main';
import ComponentFactory from '../ComponentFactory';
import { Spinner } from 'reactstrap';

const Base = ({ slug, ...props }) => {
    const [page, setPage] = useState([]);
    const [containers, setContainers] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        async function init() {
            setLoading(true);
            try {
                if (slug) {
                    const contents = await loadPage(slug);
                    setPage(contents);
                    setContainers(contents.blocks);
                    setLoading(false);
                } else {
                    setLoading(false);
                }
            } catch (err) {
                console.error('error', err);
                setLoading(false);
            }
        }

        init();

        return () => { };
    }, [slug]);

    return (
        <>
            <Header />
            <div className="box-content">
                <Row className="align-items-start m-0">
                    <Col xs={12} sm={12}>
                        <section>
                            {loading === true && (
                                <div className="init-loading text-center mt-4">
                                    <Spinner />
                                </div>
                            )}
                            {containers
                                .filter(({ container }) => container === 'main')
                                .map((block, index) => (
                                    <div
                                        key={`main-${index}`}
                                        className={`${block.componentName !== 'BannerMain'
                                            ? 'container'
                                            : 'BannerMain'
                                            }`}>
                                        <ComponentFactory
                                            block={block.block}
                                            componentName={block.componentName}
                                            content={block.content}
                                        />
                                    </div>
                                ))}
                        </section>
                        {!loading && page.content && page.content.content !== '' && (
                            <main className="page-content">
                                <div className="container pt-4">
                                    {page.content && <h1 className="title">{page.content.title}</h1>}
                                    {page.content && <div className="content content-d mb-4 pb-4" dangerouslySetInnerHTML={{ __html: page.content.content }} />}
                                </div>
                            </main>
                        )}
                        {/* {page !== [] && page.content === undefined && !loading  &&(
                <div className="container">
                  <h1 className="my-4 pt-4">Página não encontrada</h1>
                </div>
              )} */}

                        <Main {...props} />
                    </Col>
                </Row>
            </div>
            <Container className="p-0">
                <Footer />
            </Container>
        </>
    );
};

export default Base;
