import styled from 'styled-components';

export const FormStyle = styled.div`
  & .LoadMoreButtom {
    &.disabled {
      opacity: 0.6;
    }
    &:hover {
      opacity: 0.6;
    }
    margin: 0;
    .spinner-border {
      width: 1rem;
      height: 1rem;
      margin-right: 10px;
      color: #038e83 !important;
    }
  }
  & input {
    font-size: 1em !important;
  }
  button:disabled::before {
    content: '';
    color: #fff;
    font-family: sans-serif;
    animation: spin 0.7s linear infinite;
    position: absolute;
    left: 12px;
    top: 12px;
    border: 0.15em solid #ffffff;
    width: 1.4em;
    height: 1.4em;
    border-radius: 50%;
    border-left-color: transparent;
    @keyframes spin {
      to {
        transform: rotate(360deg);
      }
    }
  }
`;
export const RoundedPhoto = styled.div`
  position: relative;
  height: 0;
  overflow: hidden;
  padding-bottom: 100%;
  border-radius: 50%;
  & img {
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover;
    left: 0;
    top: 0;
  }
`;
export const PerfilPhoto = styled.div`
  text-align: center;
  & label {
    margin-top: 15px;
    text-decoration: underline;
    font-weight: 500;
    font-size: 0.9em;
    cursor: pointer;
  }
`;

export const ContainerMeusDados = styled.div`
  width: 100%;
  height: 100%;

  & .title {
    max-width: 100px;
  }
`;
