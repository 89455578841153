import api from '../api';

export const paymentForms = (order) => {
  return api.post('/ecom/checkout/payments', {
    order,
  });
};
export const getReview = (order) => {
  return api.get(`/ecom/checkout/${order}/review`, {
    order,
  });
};
export const setPaymentForms = (order, payment) => {
  return api.post('/ecom/checkout/payment/select', {
    order,
    payment,
  });
};
export const confirmPayment = (order) => {
  return api.post('/ecom/checkout/confirm', {
    order,
  });
};
export const setDataPayment = (body = {}) => {
  return api.post('/ecom/checkout/payment/set', body);
};

export const getCart = async () => {
  let {data} = await api.get('/ecom/cart/current');

  return data !== [] ? data : null;
};

export const clearCart = (id) => {
  return api.delete(`/ecom/cart/current${id}`);
};

export const addItemCart = (idCart, idITem) => {
  return api.post(`/ecom/cart/add`, {
    order: idCart,
    items: [{itemIri: `/lms/courses/${idITem}`, count: 1}],
  });
};

export const createCart = (idItem) => {
  return api.post(`/ecom/cart`, {
    items: [{itemIri: `/lms/courses/${idItem}`, count: 1}],
  });
};

export const removeItemCart = (idCart, idITem) => {
  return api.post(`/ecom/cart/remove`, {
    order: idCart,
    orderItems: [{id: idITem}],
  });
};
