import React, { useEffect, useState } from 'react';
import Base from '../../components/Theme/base';
import Title from '../../components/Theme/Title';
import { fetchRewards, fetchRanking } from '../../services/endpoints';
import { Spinner } from 'reactstrap';
import { PointsBox, Points, Modal } from './style';
import { Col, Row, Container } from 'reactstrap';
import PremioItem from '../../components/Premios/PremioItem';
import CheckIcon from '../../assets/images/check-circle.svg';

const Premios = () => {
	const [loading, setLoading] = useState(true);
	const [points, setPoints] = useState(0);
	const [premios, setPremios] = useState([]);
	const [refresh, setRefresh] = useState(false);
	const [modal, setModal] = useState(false);
	const [filter, setFilter] = useState('');

	const toggle = () => setModal(!modal);

	useEffect(() => {
		setLoading(true);
		fetchRanking(atob(window.localStorage.getItem('user-id')))
			.then((ranking) => {
				const temp =
					ranking.data && ranking.data[0] ? ranking.data[0].amount : 0;

				setPoints(temp);
				setLoading(false);
			})
			.catch((error) => {
				setLoading(false);
				console.error('ERROR AO BUSCAR PONTOS:', error);
			});

		fetchRewards()
			.then((premios) => {
				// filtrar por amount
				const temp = premios.data.filter((premio) => premio.amount > 0);
				setPremios(temp);
				setLoading(false);
			})
			.catch((err) => {
				setLoading(false);
				console.error('Erro ao buscar prêmios', err);
			});
	}, [refresh]);
	return (
		<Base>
			<Container className="p-0 my-5">
				<Title value="Loja de Prêmios" />
				{loading ? (
					<div className="init-loading text-center">
						<Spinner />
					</div>
				) : (
						<>
							<Row className="d-flex col-12 justify-content-end p-0 m-0">
								<Col xs="12" md="3" className="p-0 d-flex search-filter">
									<input
										onKeyUp={(event) => setFilter(event.target.value)}
										className="w-100"
										placeholder="Digite aqui.."
										type="text"
									/>
									<i className="fas fa-search " />
								</Col>
							</Row>
							<Row>
								<Col xs={12} className="my-3 my-md-3">
									<PointsBox>
										<span>Pontos disponíveis para resgate </span>
										<Points>
											<i className="fal fa-trophy-alt" /> {points}
										</Points>
									</PointsBox>
								</Col>
							</Row>
							{premios.length > 0 ? (
								<Row className="d-flex justify-content-center justify-content-md-start ">
									{premios.map((premio) => {
										return filter === '' ||
											premio['title']
												.toLowerCase()
												.toString()
												.indexOf(filter.toLowerCase()) > -1 ? (
												<Col
													key={premio.id}
													xs={10}
													sm={6}
													lg={4}
													xl={3}
													className="mb-5">
													<PremioItem
														id={premio.id}
														image={premio.image ? premio.image.urlPublica : null}
														name={premio.title}
														cost={premio.points}
														amount={points}
														status="available"
														setRefresh={setRefresh}
														setModal={setModal}
													/>
												</Col>
											) : (
												''
											);
									})}
								</Row>
							) : (
									<h2 className="text-center">
										Ainda não temos prêmios disponíveis :(
									</h2>
								)}
						</>
					)}
			</Container>
			<Modal
				isOpen={modal}
				toggle={toggle}
				className="modal-curso-presencial "
				centered={true}>
				<img src={CheckIcon} alt="" />
				<p className="px-2 text-center">Troca realizada com sucesso</p>
			</Modal>
		</Base>
	);
};

export default Premios;
