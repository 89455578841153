import React from 'react';
import SubTitle from '../../components/Theme/Title/SubTitle';
import {LogoStyle, CadastarStyle, EsqueceuStyle, BoxLogin} from './styles';
import {Container, Row, Col} from 'reactstrap';
import Logo from '../../assets/images/logo.png';


const Login = () => {


   
    return (
            
        <Container className="p-0 col-10 mb-5 pb-5">
            <BoxLogin className="col-md-8 my-5 col-lg-6">
                <form action="/" method="post">                    
                    <Row className=" d-flex flex-column align-items-center justify-content-center">
                        <Row className="d-flex mt-5 col-9">
                            <Col xs="3"  className="pl-0  pt-2">
                                <LogoStyle src={Logo} className="img-fluid "/>
                            </Col>
                            <Col xs="9"  className=" pr-0 ">
                                <SubTitle value="Fazer Login"/>  
                            </Col>
                                           
                        </Row> 
                        <Col xs="10" md="9"  className="d-flex flex-column mt-4">
                            <Col xs="12" md="12" >
                                <div className="has-float-label">
                                    <input id="input_name" type="text" className="w-100" placeholder="Nome"/>
                                    <label htmlFor="input_name">Digite aqui seu <b>Nome</b></label>                            
                                </div>                            
                            </Col>
                            <Col xs="12" md="12" className="mt-4">
                                <div className="has-float-label">
                                    <input id="input_name" type="password" className="w-100" placeholder="Senha"/>
                                    <label htmlFor="input_name">Digite aqui sua <b>Senha</b></label>                            
                                </div>                            
                            </Col>    
                        </Col> 
                        <Col xs="10" md="9"  className="d-flex flex-wrap">
                            <Col xs="12" sm="6" className="d-flex flex-column pt-5">
                                <CadastarStyle to="#" >Cadastrar-se agora</CadastarStyle>
                                <EsqueceuStyle to="#" >Esqueceu a <b>senha ?</b></EsqueceuStyle>
                            </Col>
                            <Col xs="12" sm="6" className="d-flex">
                                <button  className="col-12  LoadMoreButtom">Entrar</button> 
                            </Col>
                        </Col>                             
                    </Row> 
                </form> 
            </BoxLogin>         
        </Container>
    )

};
export default Login;
