const passToPascalCase = string => {
  let newString = string.charAt(0).toUpperCase()
  for (let i = 1; i < string.length; i++) {
    if (string.charAt(i - 1) === '-') {
      newString += string.charAt(i).toUpperCase()
    } else if (string.charAt(i) !== '-') {
      newString += string.charAt(i)
    }
  }

  return newString
};

export default passToPascalCase;