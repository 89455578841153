function removeAccents(str) {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

function removeSpaces(str) {
  return str.replace(/\s/g, '');
}

function toSnakeCase(str) {
  const result = str.replace(/([A-Z])/g, '$1');
  return removeSpaces(removeAccents(result.split(' ').join('_').toLowerCase()));
}

export { toSnakeCase, removeSpaces, removeAccents };
