import React, {createContext, useState, useContext, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import api from '../services/api';
import {
  addItemCart,
  createCart,
  getCart,
  removeItemCart,
  paymentForms,
  setPaymentForms,
  getReview,
  confirmPayment,
} from '../services/ecom';
const Context = createContext();

const CheckoutProvider = ({children}) => {
  const [items, setItems] = useState([]);
  const [discount, setDiscount] = useState(0);
  const [cart, setCart] = useState(null);
  const [isProfileImcompleted, setIsProfileImcompleted] = useState(false);
  const [lastOpenCartUrl, setLastOpenCartUrl] = useState('');

  const save = (aux) => {
    const temp = JSON.stringify(aux || items);
    const temp2 = JSON.stringify(discount);

    localStorage.setItem('item', temp);
    localStorage.setItem('discount', temp2);
  };

  const getItems = async (newCart) => {
    const coursesPromisses = [];
    let cart = null;
    if (!newCart) {
      cart = await getCart();
    } else {
      cart = newCart;
    }
    setCart(cart);
    if (cart && cart.orderItems) {
      cart.orderItems.forEach((item) =>
        coursesPromisses.push(api.get(item.itemIri)),
      );
      let response = await Promise.all(coursesPromisses);

      setItems(response);
    }
  };

  // useEffect(() => {
  //   (async () => {
  //     await getItems();
  //   })();

  //   // if (items.length === 0) {
  //   //   const temp = JSON.parse(localStorage.getItem('item'));
  //   //   const temp2 = JSON.parse(localStorage.getItem('discount'));
  //   //   if (temp) {
  //   //     setItems(temp);
  //   //   }
  //   //   if (temp2) {
  //   //     setDiscount(temp2);
  //   //   }
  //   // }
  // }, []);

  const ClearItems = () => {
    setItems([]);
    save();
  };

  const getTotal = () => {
    return cart.totalPrice;
  };

  const PushItem = async (id) => {
    let resp = null;

    if (cart && cart.id) {
      resp = await addItemCart(cart.id, id);
    } else {
      resp = await createCart(id);
    }
    await getItems(resp.data);
    return resp;
  };

  const PushDiscount = (cupom) => {
    setDiscount(cupom);
    save();
  };

  const RemoveItem = async (id) => {
    const {data} = await removeItemCart(
      cart.id,
      cart.orderItems.find((item) => item.itemIri.indexOf(id) > 0).id,
    );
    await getItems(data);
  };

  const getSubtotal = () => {
    return cart.subtotalPrice;
  };

  const getDiscount = () => {
    return cart.totalDiscounts;
  };
  const getPaymentsForm = async (idCart) => {
    return await paymentForms(idCart);
  };
  const getReviewPayment = async (idCart) => {
    return await getReview(idCart);
  };
  const setPaymentsForm = async (order, payment) => {
    return await setPaymentForms(order, payment);
  };
  const Total = () => {
    const temp = items.reduce((accumulator, item) => {
      return accumulator + item.price;
    }, 0);
    return temp;
  };
  const confirmPaymentCheckout = async () => {
    return await confirmPayment(cart.id);
  };
  return (
    <Context.Provider
      value={{
        cart,
        setCart,
        items,
        ClearItems,
        PushItem,
        RemoveItem,
        getSubtotal,
        getDiscount,
        getTotal,
        PushDiscount,
        getPaymentsForm,
        setPaymentsForm,
        getReviewPayment,
        confirmPaymentCheckout,
        isProfileImcompleted,
        setIsProfileImcompleted,
        lastOpenCartUrl,
        setLastOpenCartUrl,
      }}>
      {children}
    </Context.Provider>
  );
};

export default CheckoutProvider;

export function useCheckout() {
  const context = useContext(Context);
  const {
    cart,
    items,
    ClearItems,
    PushItem,
    RemoveItem,
    getSubtotal,
    getDiscount,
    getTotal,
    PushDiscount,
    getPaymentsForm,
    setPaymentsForm,
    getReviewPayment,
    confirmPaymentCheckout,
    isProfileImcompleted,
    setIsProfileImcompleted,
    lastOpenCartUrl,
    setLastOpenCartUrl,
  } = context;

  return {
    cart,
    items,
    ClearItems,
    PushItem,
    RemoveItem,
    getSubtotal,
    getDiscount,
    getTotal,
    PushDiscount,
    getPaymentsForm,
    setPaymentsForm,
    getReviewPayment,
    confirmPaymentCheckout,
    isProfileImcompleted,
    setIsProfileImcompleted,
    lastOpenCartUrl,
    setLastOpenCartUrl,
  };
}
