import React, {useState, useEffect} from 'react';
import Base from '../../components/Theme/base';
import Title from '../../components/Theme/Title';
import InternalTitle from '../../components/Theme/Title/InternalTitle';
import ProgressBar from '../../components/Theme/ProgressBar';
import {Cursos, RespCursos, ContainerMeusCursos} from './styles';
import {Container, Row, Col} from 'reactstrap';
import CategoriasCursos from './categorias';
import CourseItem from '../../components/Home/Cursos/Distancia/CourseItem';
import api from '../../services/api';
import userPlaceholder from './../../assets/images/materdei_placeholder.png';
import CourseRefreshContext from './coursesContext';
import getCurrentUser from '../../utils/getCurrentUser';
import axios from 'axios';
import {getToken} from '../../services/auth';

const MeusCursos = () => {
  const [categoriesCourses, setCategoriesCourses] = useState([]);
  const [courses, setCourses] = useState([]);
  const [titleCategory, setTitleCategory] = useState('');

  function getCountCategory(title, endpoint) {
    api
      .get(endpoint)
      .then((res) => {
        let data = categoriesCourses;
        if (title === 'Meus Certificados') {
          let qtd = 0;
          res.data.map((courseCategory) => {
            if (checkedCoursesFinishedApproved(courseCategory)) {
              qtd++;
            }
          });
          data.push({
            title: title,
            qtd: (qtd < 10 && qtd !== '' ? '0' + qtd : qtd) || '00',
          });
        } else {
          data.push({
            title: title,
            qtd:
              (res.data < 10 && res.data !== '' ? '0' + res.data : res.data) ||
              '00',
          });
        }
        setCategoriesCourses(data);
        getListCourses(title);
      })
      .catch((err) => {
        console.error('Erro ao buscar quantidade de cursos avaliados' + err);
      });
  }
  function setListCourses(title) {
    switch (title) {
      case 'Cursos Assistidos':
        getListCourses(title, '/lms/course_stats?course_status=CONCLUIDO');
        break;
      case 'Cursos Favoritos':
        getListCourses(
          title,
          '/general/evaluations?match[content_iri]=/lms/courses&type=FAVORITE',
        );
        break;
      case 'Cursos Em Andamento':
        getListCourses(title, '/lms/course_stats?course_status=EM_ANDAMENTO');
        break;
      case 'Cursos Avaliados':
        getListCourses(
          title,
          '/general/evaluations?match[content_iri]=/lms/courses&type=RATING',
        );
        break;
        //   case 'Videos Assistidos':
        //     getListCourses(
        //       title,
        //       '/general/watcheds?match[content_iri]=/lms/lesson_contents&watched=true',
        //     );
        break;
      case 'Meus Certificados':
        getListCourses(title, '/lms/course_stats?course_status=CONCLUIDO');
        break;
      default:
        break;
    }
    setTitleCategory(title);
  }
  function getIcon(title) {
    switch (title) {
      case 'Cursos Favoritos':
        return <i className="fas fa-heart"></i>;
      case 'Cursos Avaliados':
        return <i className="fas fa-star"></i>;
      case 'Meus Certificados':
        return (
          <button className=" m-0 ml-2 btn-cert LoadMoreButtom">
            Baixar Certificado
          </button>
        );
      default:
        return '';
    }
  }
  function getCertificado(url, course_id) {
    if (url === '#') {
      axios({
        url: `${process.env.REACT_APP_API_URL}/lms/courses/${course_id}/certificate_issuance`,
        method: 'GET',
        responseType: 'arraybuffer', // important
        headers: {Authorization: getToken()},
      }).then((response) => {
        const url = window.URL.createObjectURL(
          new Blob([response.data], {type: 'application/pdf'}),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('Certificado', 'file.pdf');
        link.setAttribute('target', '_blank');
        document.body.appendChild(link);
        link.click();
      });
    }
  }

  function getListCourses(title, endpoint) {
    const data = [];
    api
      .get(endpoint)
      .then((res) => {
        if (res.data.length > 0) {
          res.data.forEach((el) => {
            if (el !== null) {
              let element = el.course;
              let elementDataCertificates = null;
              //   if (title === 'Videos Assistidos' && el.content !== undefined) {
              //     element = el.content.lesson.course;
              //   } else if (
              if (
                title === 'Cursos Favoritos' ||
                (title === 'Cursos Avaliados' && el.content !== undefined)
              ) {
                element = el.content;
              } else if (title === 'Meus Certificados') {
                elementDataCertificates = el;
                if (!checkedCoursesFinishedApproved(elementDataCertificates)) {
                  element = undefined;
                }
              }
              console.log(element);

              if (element !== undefined) {
                if (el.progress) {
                  element.progress = el.progress;
                } else if (
                  element.course_stats &&
                  element.course_stats.filter(
                    (stat) => stat.user_iri === getCurrentUser(),
                  ).length > 0
                ) {
                  element.progress = element.course_stats.filter(
                    (stat) => stat.user_iri === getCurrentUser(),
                  )[0].progress;
                } else {
                  element.progress = 0;
                }

                data.push({
                  _id: `/lms/courses/${element.id}`,
                  id: element.id,
                  image: {
                    urlPublica:
                      element.image !== null
                        ? element.image.urlPublica
                        : userPlaceholder,
                  },
                  nota: 5,
                  category_complement: getIcon(title),
                  duration: element.duration,
                  data: element.created_at,
                  views: element.views,
                  photo:
                    el.user.profile.avatar && el.user.profile.avatar.file
                      ? el.user.profile.avatar.file.urlPublica
                      : userPlaceholder,
                  autor: el.user.name,
                  titulo: element.title,
                  progresso: element.progress,
                  price: element.price,
                  promotional_price: element.promotional_price,
                  description: element.summary,
                  approval_percentage: elementDataCertificates
                    ? elementDataCertificates.approval_percentage
                    : null,
                  progressStr: elementDataCertificates
                    ? elementDataCertificates.progressStr
                    : null,
                  max_grade: elementDataCertificates
                    ? elementDataCertificates.max_grade
                    : null,
                  caterory_title: title,
                  url:
                    title === 'Meus Certificados'
                      ? '#'
                      : `/online/lms/courses/${element.id}`,
                });
              }
            }
          });
          setCourses(data);
        } else {
          setCourses([]);
        }
      })
      .catch((err) => {
        setCourses([]);
        console.error('Erro ao buscar Cursos: ', err);
      });
  }

  const checkedCoursesFinishedApproved = (dataCourses) => {
    const existExamCourse =
      dataCourses &&
      dataCourses.course &&
      dataCourses.course.course_exams &&
      dataCourses.course.course_exams.length &&
      dataCourses.course.course_exams[0] &&
      dataCourses.course.course_exams[0].exam
        ? dataCourses.course.course_exams[0].exam
        : null;
    if (existExamCourse && existExamCourse.mandatory_approval) {
      return (
        dataCourses.approval_percentage &&
        dataCourses.max_grade &&
        dataCourses.max_grade >= dataCourses.approval_percentage
      );
    } else if (existExamCourse && !existExamCourse.mandatory_approval) {
      return dataCourses.attempts && dataCourses.attempts > 1;
    } else if (
      !existExamCourse &&
      dataCourses &&
      dataCourses.course &&
      dataCourses.course.certificate_template
    ) {
      return true;
    }
  };

  useEffect(() => {
    // CURSOS ASSISTIDOS
    getCountCategory(
      'Cursos Assistidos',
      `/lms/course_stats/count_by?course_status=CONCLUIDO`,
    );
    // CURSOS FAVORITADOS
    getCountCategory(
      'Cursos Favoritos',
      `/general/evaluations/count_by?match[content_iri]=/lms/courses&type=FAVORITE`,
    );
    // CURSOS Em ANDAMENTO
    getCountCategory(
      'Cursos Em Andamento',
      `/lms/course_stats/count_by?course_status=EM_ANDAMENTO`,
    );
    // CURSOS AVALIADOS
    getCountCategory(
      'Cursos Avaliados',
      `/general/evaluations/count_by?match[content_iri]=/lms/courses&type=RATING`,
    );
    // VIDEOS ASSISTIDOS
    // getCountCategory(
    //   'Videos Assistidos',
    //   `/general/watcheds/count_by?match[content_iri]=/lms/lesson_contents&watched=true`,
    // );
    // Meus Certificados
    getCountCategory(
      'Meus Certificados',
      `/lms/course_stats?course_status=CONCLUIDO`,
    );
  }, []);

  return (
    <Base>
      <ContainerMeusCursos>
        <Title value="Meus Cursos" />
        <Container className="p-0 mb-5 pb-5">
          <CourseRefreshContext.Provider value={setListCourses}>
            <Row>
              {categoriesCourses.map((categoria, i) => (
                <CategoriasCursos key={`category-${i}`} list={categoria} />
              ))}
            </Row>
          </CourseRefreshContext.Provider>
          {courses.length > 0 && <InternalTitle value={titleCategory} />}
          <Row>
            <RespCursos className="p-0 col-12">
              {courses.length > 0 && (
                <Cursos className="d-flex flex-wrap">
                  {courses.map((course, i) => (
                    <Col
                      onClick={(e) => getCertificado(course.url, course.id)}
                      md="6"
                      lg="3"
                      key={`courseItem-${i}`}
                      className="p-0 mb-5 d-flex flex-column">
                      <CourseItem
                        categoryCourse={course.category_complement}
                        course={course}
                        noPrice
                      />
                      <div className="pl-4 pr-2 d-flex ">
                        <b className="mr-2">
                          {parseInt(course.progresso.toFixed(2))}%
                        </b>{' '}
                        <ProgressBar progress={course.progresso} />
                      </div>
                    </Col>
                  ))}
                </Cursos>
              )}
            </RespCursos>
          </Row>
        </Container>
      </ContainerMeusCursos>
    </Base>
  );
};
export default MeusCursos;
