import React, {useState, useEffect} from 'react';
import {
  StarsStyle,
  Professor,
  ImgProfessor,
  UserImg,
  NomeProfessor,
  ActionsStyle,
  Views,
  Icon,
  ModalExamSytled,
  AvalieStyle,
  FavortitosStyle,
  ProgressCurso,
  ProvaStyle,
} from './styles';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import InternalTitle from '../../../components/Theme/Title/InternalTitle';
import ProgressBar from '../../../components/Theme/ProgressBar';
import {Container, Col, Row} from 'reactstrap';
import Aula from './Aula';
import api from '../../../services/api';
import Exame from '../../Exames';
import {Spinner} from 'reactstrap';
import getCurrentUser from '../../../utils/getCurrentUser';
import {isApproved} from '../../../utils/exam';
import {InternalTitleStyle} from '../../Theme/Title/styles';
import Scorm from '../../ContentMedia/Scorm';

const CursoPageOnline = ({course, reload, ...props}) => {
  const [progress, setProgress] = useState([]);
  const [evaluationUserRating, setEvaluationUserRating] = useState(5);
  const [evaluationUserFavorite, setEvaluationUserFavorite] = useState(0);
  const [openExamModal, setOpenExamModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [evaluation, setEvaluation] = useState(course ? course.nota : 5);

  function openExam() {
    setOpenExamModal(!openExamModal);
  }

  useEffect(() => {
    api
      .get(
        `/general/currentUserEvaluations?content_iri=/lms/courses/${course.id}`,
      )
      .then((response) => {
        const res = response.data || [];
        const ratings =
          res.filter((el) => el.type === 'RATING').length > 0
            ? res.filter((el) => el.type === 'RATING')[0].average
            : 5;
        setEvaluation(ratings);
      })
      .catch((err) => {
        console.error('Erro na nota', err);
      });
    api
      .get(
        `/general/evaluations?content_iri=${course._id}&user_iri=${atob(
          localStorage.getItem(`user-endpoint`),
        )}`,
      )
      .then((responseUser) => {
        const resUser = responseUser.data || [];
        const ratingsUser =
          resUser.filter((el) => el.type === 'RATING').length > 0
            ? resUser.filter((el) => el.type === 'RATING')[0].value
            : 0;
        const favoritesUser =
          resUser.filter((el) => el.type === 'FAVORITE').length > 0
            ? resUser.filter((el) => el.type === 'FAVORITE')[0].value
            : 'false';
        setEvaluationUserRating(ratingsUser);
        setEvaluationUserFavorite(favoritesUser);
        setLoading(true);
      });

    return () => {
      setEvaluationUserRating([]);
      setEvaluationUserFavorite([]);
      setEvaluation([]);
    };
  }, [course]);

  useEffect(() => {
    handleLessonExamSequence(course);
  }, [course, reload]);

  const handleLessonExamSequence = async (course) => {
    const handleLessonsAvailability = (lessons, firstIndex, availability) => {
      for (let i = firstIndex; i < lessons.length; i++) {
        lessons[i].availability = availability;
      }
    };

    if (course && course.aulas) {
      const lessons = course.aulas || [];

      for (let i = 0; i < lessons.length; i++) {
        const lesson = lessons[i];
        const firstIndex = i + 1;
        let availability = 'available';

        lesson.availability = availability;

        if (!course.force_lessons_sequence) {
          handleLessonsAvailability(lessons, firstIndex, availability);
          break;
        }

        if (lesson.exams.length) {
          if (await isApproved(lesson.id, 'lesson')) {
            handleLessonsAvailability(lessons, firstIndex, availability);
          } else {
            availability = 'blocked';
            handleLessonsAvailability(lessons, firstIndex, availability);
            break;
          }
        } else {
          handleLessonsAvailability(lessons, firstIndex, availability);
        }
      }
    }
  };

  function updateRating(val) {
    api
      .post('/general/evaluations', {
        user_iri: atob(localStorage.getItem(`user-endpoint`)),
        content_iri: course._id,
        value: val,
        type: 'RATING',
      })
      .then((response) => {
        setEvaluationUserRating(val);
      })
      .catch((err) => {
        console.error('ERRO AO ATUALIZAR RATING', err);
      });
  }
  function updateFavorite() {
    api
      .post('/general/evaluations', {
        user_iri: atob(localStorage.getItem(`user-endpoint`)),
        content_iri: course._id,
        value: 1,
        type: 'FAVORITE',
      })
      .then((response) => {
        setEvaluationUserFavorite(evaluationUserFavorite === '1' ? 0 : '1');
      })
      .catch((err) => {
        console.error('Erro ao Setar Favorite', err);
      });
  }

  const stars = [];
  for (var i = 0; i < 5; i++) {
    evaluation > i ? stars.push('fas') : stars.push('far');
  }
  const starsUser = [];
  for (var i = 0; i < 5; i++) {
    evaluationUserRating > i ? starsUser.push('fas') : starsUser.push('far');
  }

  const showExameButton = () => {
    const array = course
      ? course.course_stats.filter((item) => item.user_iri === getCurrentUser())
      : [];

    if (array.length > 0) {
      const myCourseData = array[0];
      if (myCourseData.attempts > 0) {
        // resolveu a prova 1x ou mais
        if (myCourseData.max_grade >= myCourseData.approval_percentage) {
          // se ja alcancou a nota para aprovacao
          return false;
        }
      }
    }

    return true;
  };

  return (
    <>
      {!loading ? (
        <div className="mt-30 mb-30 text-center">
          <Spinner />
        </div>
      ) : (
        <>
          <Container className="px-0 py-4">
            <InternalTitleStyle>{course.titulo}</InternalTitleStyle>

            <Row>
              <Col
                xs="12"
                className="d-flex justify-content-between justify-content-lg-start flex-wrap">
                <StarsStyle className="d-flex align-items-baseline mt-lg-0 col-5 col-lg-2">
                  {stars.map((star, i) => (
                    <i key={`star-${i}`} className={`${star} fa-star ml-1`} />
                  ))}
                  <p> {evaluation}/5</p>
                </StarsStyle>
                <Views className="d-flex align-items-baseline mt-lg-0  col-3 col-lg-2">
                  <Icon className="fas fa-eye" />
                  {course.views}
                </Views>
              </Col>
            </Row>
            <Row>
              <Col xs="12" className="d-flex ">
                <ActionsStyle className="p-0 py-1 d-flex flex-wrap justify-content-lg-between align-items-center my-2 col-12">
                  <Col
                    xs={12}
                    md={course.duration ? 6 : 12}
                    lg={course.duration ? 6 : 4}
                    xl={course.duration ? 3 : 4}
                    className="px-0 px-md-3 pb-4 p-lg-0  d-flex justify-content-lg-between align-items-center">
                    {course.aulas.length > 0 && (
                      <AvalieStyle>
                        <b>Avalie este Curso</b>{' '}
                        {starsUser.map((star, i) => (
                          <i
                            key={`starAction-${i}`}
                            onClick={() => updateRating(i + 1)}
                            className={`${star} fa-star ml-1`}
                          />
                        ))}
                      </AvalieStyle>
                    )}
                  </Col>
                  <Col
                    xs={12}
                    md={`${course.duration ? '6' : '12'}`}
                    lg={`${course.duration ? '6' : '4'}`}
                    xl={`${course.duration ? '4' : '5'}`}
                    className="px-0 px-md-3 pb-4 p-lg-0 d-flex justify-content-xl-center align-items-center">
                    <ProgressCurso className="d-flex flex-wrap w-100">
                      <b>Progresso do curso: </b>{' '}
                      <b className="ml-1">
                        {' '}
                        {` ${
                          progress.filter(
                            (stat) => stat.user_iri === getCurrentUser(),
                          ).length > 0
                            ? parseInt(
                                progress
                                  .filter(
                                    (stat) =>
                                      stat.user_iri === getCurrentUser(),
                                  )[0]
                                  .progress.toFixed(2),
                              )
                            : 0
                        }%`}
                      </b>
                      <Col
                        xs={5}
                        md={course.duration ? 5 : 6}
                        lg={course.duration ? 6 : 4}
                        xl={course.duration ? 5 : 6}
                        className="pt-2 pt-lg-0 d-flex align-items-center">
                        <ProgressBar
                          progress={
                            progress.filter(
                              (stat) => stat.user_iri === getCurrentUser(),
                            ).length > 0
                              ? progress.filter(
                                  (stat) => stat.user_iri === getCurrentUser(),
                                )[0].progress
                              : 0
                          }
                        />
                      </Col>
                    </ProgressCurso>
                  </Col>
                  {course.duration && (
                    <Col
                      xs={12}
                      md={6}
                      xl={2}
                      className="px-0 px-md-3 pb-4 p-lg-0 d-flex mt-lg-1 align-items-xl-center">
                      {course.aulas.length > 0 && (
                        <div>
                          <b>Duração do curso: </b>
                          <span>{course.duration}</span>
                        </div>
                      )}
                    </Col>
                  )}
                  <Col
                    xs={12}
                    md={course.duration ? 6 : 12}
                    lg={course.duration ? 6 : 4}
                    xl={3}
                    className="px-0 px-md-3 pb-4 p-lg-0 d-flex justify-content-xl-end align-items-center">
                    <FavortitosStyle
                      onClick={updateFavorite}
                      className="d-flex justify-content-lg-end align-items-center">
                      <b>Adicione Aos Favoritos </b>{' '}
                      <i
                        className={`ml-2 ${
                          evaluationUserFavorite === '1' ? 'fas' : 'far'
                        } fa-heart`}
                      />
                    </FavortitosStyle>
                  </Col>
                </ActionsStyle>
              </Col>
            </Row>
            {course.scorm ? (
              <Scorm
                content={course}
                contentType="course"
                refreshProgress={setProgress}
              />
            ) : (
              <Row>
                {typeof course.aulas !== 'undefined' &&
                  course.aulas.length > 0 &&
                  course.aulas.map((aula, i) => (
                    <Col key={`aula-${i}`} xs="12">
                      <Aula
                        refreshProgress={setProgress}
                        index={i}
                        aula={aula}
                        reload={reload}
                      />
                    </Col>
                  ))}
              </Row>
            )}

            {showExameButton() &&
              progress.filter((stat) => stat.course_status === 'CONCLUIDO')
                .length > 0 &&
              course.exams.length > 0 && (
                <ProvaStyle className="d-flex flex-wrap justify-content-center  justify-content-lg-between ">
                  <h3 className="col-12 p-lg-0 col-lg-8 text-center text-lg-left">
                    Ao finalizar 100% de todas aulas deste curso, faça a prova e
                    receba o certificado.
                  </h3>

                  <Link
                    className={
                      progress.filter(
                        (stat) => stat.user_iri === getCurrentUser(),
                      )[0].progress >= 100
                        ? ''
                        : 'disabled'
                    }
                    onClick={
                      progress.filter(
                        (stat) => stat.user_iri === getCurrentUser(),
                      )[0].progress >= 100
                        ? () => openExam()
                        : (e) => e.preventDefault()
                    }
                    to="#">
                    Fazer prova agora
                  </Link>
                </ProvaStyle>
              )}
          </Container>
          {openExamModal === true && (
            <ModalExamSytled>
              <Container className="exam-style">
                <Row className="d-flex justify-content-end">
                  <button
                    onClick={() => {
                      reload();
                      openExam();
                    }}
                    className="close-button">
                    <i className="fas fa-times"></i>
                  </button>
                </Row>

                <Exame
                  course={course}
                  exams={course.exams}
                  typeExame={`course_id=${course.id}`}
                />
              </Container>
            </ModalExamSytled>
          )}
        </>
      )}
    </>
  );
};
CursoPageOnline.prototype = {
  course: PropTypes.object.isRequired,
};

export default CursoPageOnline;
