import styled from 'styled-components';
import {Modal as ModalReactStrap} from 'reactstrap';

export const PointsBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px 0 30px;
  padding: 20px;
  border: 1px solid #e1e1e1;
  background-color: #e1e1e1;
  color: #333;
  font-size: 25px;
  text-align: center;

  span {
    margin-right: 10px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    font-size: 16px;
  }
`;

export const Points = styled.span`
  color: #038e83;
  font-size: 25px;
  font-weight: 700;

  i {
    color: #038e83;
    font-size: 23px;
  }
`;

export const Modal = styled(ModalReactStrap)`
  .modal-content {
    background-color: #038e83;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px 10px;
    color: #fff;
    border-radius: 0px;
    border: none;

    img {
      height: 120px;
    }
    p {
      font-size: 25px;
      margin: 25px 0;
    }
  }
`;
