import styled from 'styled-components';


export const FormStyle = styled.div`
    & .LoadMoreButtom{
        margin: 0;
    }
    & input {
        font-size: 1em!important;
    }
`;
export const RoundedPhoto = styled.div`
    position: relative;
    height: 0;
    overflow: hidden;
    padding-bottom: 100%;
    border-radius: 50%;
`;
export const PerfilPhoto = styled.div`
    text-align: center;
    & label{
        margin-top: 15px;
        text-decoration: underline;
        font-weight: 500;
        font-size: 0.9em;  
        cursor:pointer;
    }
`;