import styled from 'styled-components';

export const ReviewItem = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #eeeeee;
    
`;
export const DadosReview = styled.div`
    padding: 15px;
    background-color: #f3f3f3;
`;
export const Photo = styled.div`
    display: block;
    width: 70px;
    height: 70px;
    overflow: hidden;
    position: relative;
    margin-right: 5px;
    background-color: #f3f3f3;
`;
export const UserImg = styled.img`
    position: absolute;
    left: 0;
    object-fit: cover;
    background-color: #f3f3f3;
`;
export const Autor = styled.p`
    margin: 0;
    font-size: 14px;
    text-align: left;
    color: #000;
    font-weight: bold;
    margin-top: 10px;
`;
export const TitleReview = styled.h4`
    margin: 0;
    font-size: 16px;
    text-align: left;
    color: #000;
    font-weight: bold;
    margin-bottom: 25px;
`;
export const DescriptionReview = styled.p`
    font-weight: 100;
    text-align: left;
    font-size: 14px;
    font-style:italic;    
    margin-bottom: 25px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 6;
    overflow: hidden;
    & p, & span,& div {
        color: #000!important;
        background-color: transparent!important;
        word-break: break-word!important;
        white-space: normal;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 6;
        overflow: hidden;
    }
`;