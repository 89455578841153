import styled from 'styled-components';

export const BlockSection = styled.div`
  margin: 40px 0;
  position: relative;
`;
export const TextContent = styled.div`
  position: absolute !important;
  margin: 15px 0px;
  @media (max-width: 991px) {
    position: relative !important;
  }
`;
export const Background = styled.div`
  z-index: -1;
  @media (max-width: 991px) {
    position: absolute !important;
    height: 100%;
    & img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
`;
export const TitleSobre = styled.span`
  background-color: #fcc06d;
  color: #fff;
  padding: 25px 15px;
  margin: 0px 0;
`;
export const BoxSobre = styled.div`
  margin: 0px 0;
  background-color: #038e83;

  color: #fff;
  padding: 15px;
  & h3 {
    font-size: 2em;
    font-weight: 100;
  }
  & p {
    font-weight: 300;
  }
  & a {
    color: #fff;
    font-size: 25px;
  }
  & a:hover {
    color: #fff;
    opacity: 0.85;
  }
`;
