import React, {useState, useEffect} from 'react';
import {Row, Col} from 'reactstrap';
import {Formik, Form} from 'formik';
import SubTitle from '../../../components/Theme/Title/SubTitle';
import MaskedInput from 'react-text-mask';
import {StyledField, Label, ContainerForm} from '../style';
import * as Yup from 'yup';
import {Spinner} from 'reactstrap';
import {useCheckout} from '../../../store/Checkout';
import {priceFormat} from '../../../utils/format';
import {setDataPayment} from '../../../services/ecom';
import {useHistory} from 'react-router-dom';

const cardNumberMask = [
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

const monthMask = [/\d/, /\d/];
const yearMask = [/\d/, /\d/, /\d/, /\d/];
const codeMask = [/\d/, /\d/, /\d/];

const CreditCardPagSeguro = () => {
  const history = useHistory();

  const {cart} = useCheckout();
  const [brand, setBrand] = useState(null);
  const [instalments, setInstalments] = useState([]);
  const [showErros, setShowErrors] = useState(false);
  const [parcelas, setParcelas] = useState(1);
  const [loading, setLoading] = useState(false);

  const getBrand = (input) => {
    const inputText = input.replace(' ', '').toString().substr(0, 6);
    if (inputText.indexOf('_') === -1) {
      window.PagSeguroDirectPayment.getBrand({
        cardBin: inputText,
        success: function (response) {
          //bandeira encontrada
          setBrand(response.brand.name);
          window.PagSeguroDirectPayment.getInstallments({
            amount: cart.totalPrice,
            maxInstallmentNoInterest: 2,
            brand: response.brand.name,
            success: function (res) {
              const options = Object.keys(res.installments).map(
                (item) => res.installments[item],
              );
              setInstalments(options[0]);

              // Retorna as opções de parcelamento disponíveis
            },
          });
        },
      });
    }
  };

  const submitForm = (form) => {
    setLoading(true);
    window.PagSeguroDirectPayment.createCardToken({
      cardNumber: form.card_number.toString().replace(/( )+/g, ''), // Número do cartão de crédito
      brand: brand, // Bandeira do cartão
      cvv: form.code.toString(), // CVV do cartão
      expirationMonth: form.month.toString(), // Mês da expiração do cartão
      expirationYear: form.year.toString(), // Ano da expiração do cartão, é necessário os 4 dígitos.
      success: function (response) {
        window.PagSeguroDirectPayment.onSenderHashReady(async function (hash) {
          const user = JSON.parse(localStorage.getItem(`user-data`));
          const responseData = await setDataPayment({
            order: cart.id,
            paymentData: {
              method: 'CREDIT_CARD',
              creditcard: {
                token: card.token,
                installment: {
                  quantity: parseInt(parcelas),
                  value: instalments.find((item) => item.quantity === parcelas)
                    .installmentAmount,
                },
                holder: {
                  cpf: user.profile.cpf.value,
                  birth_date: user.profile.data.value,
                  name: form.name,
                  phone: user.profile.telefone.value,
                },
              },
              sender_hash: hash.senderHash,
            },
          });
          history.push({
            pathname: '/checkout/review',
            state: cart,
          });
        });
        const {card} = response;
        setLoading(false);
      },
      error: function (response) {
        alert('Erro ao processar Cartão de Crédito.');
        console.error(response);
        setLoading(false);
        // Callback para chamadas que falharam.
      },
      // complete: function (response) {
      //   console.log({
      //     cardNumber: form.card_number.toString().replace(/( )+/g, ''), // Número do cartão de crédito
      //     brand: brand, // Bandeira do cartão
      //     cvv: form.code.toString(), // CVV do cartão
      //     expirationMonth: form.month.toString(), // Mês da expiração do cartão
      //     expirationYear: form.year.toString(), // Ano da expiração do cartão, é necessário os 4 dígitos.
      //   });
      //   // Callback para todas chamadas.
      // },
    });
  };

  const schema = Yup.object().shape({
    card_number: Yup.string()
      .max(19)
      .required('Número do Cartão é um campo obrigatório'),
    name: Yup.string().required('Nome do titular é um campo obrigatório'),
    month: Yup.number('Mês deve ter 2 digitos')
      .min(1, 'Mês precisar ser maior que 0')
      .max(12, 'Mês precisar ser menor ou igual a 12')
      .required('Mês é um campo obrigatório'),
    year: Yup.number()
      .min(20, 'Cartão expirado')
      .required('Ano é um campo obrigatório'),
    code: Yup.string()
      .min(3, 'Código de segurança deve ter 3 digitos')
      .max(3, 'Código de segurança deve ter 3 digitos')
      .required('Código de segurança é um campo obrigatório'),
  });

  return (
    <div className="formas-pagamento">
      <SubTitle value="Cartão de crédito" />
      <Formik
        onSubmit={submitForm}
        validationSchema={schema}
        initialValues={{
          card_number: '',
          name: '',
          month: '',
          year: '',
          code: '',
          payment: '',
        }}>
        {({errors, touched, values}) => (
          <Form>
            <ContainerForm>
              <Row className="col-10">
                <Col xs={12} className="p-0">
                  <Label>Número do Cartão</Label>
                </Col>
                <Col xs={12} className="p-0">
                  <StyledField
                    name="card_number"
                    render={({field}) => (
                      <MaskedInput
                        {...field}
                        mask={cardNumberMask}
                        type="text"
                        onInput={(e) => {
                          getBrand(e.target.value);
                        }}
                      />
                    )}
                  />
                  {showErros && errors.card_number ? (
                    <div className="Form-Error">{errors.card_number}</div>
                  ) : null}
                </Col>
              </Row>
              <Row className="col-10">
                <Col xs={12} className="p-0">
                  <Label>Nome do titular (como está gravado no Cartão)</Label>
                </Col>
                <Col xs={12} className="p-0">
                  <StyledField type="text" name="name" />
                  {showErros && errors.name ? (
                    <div className="Form-Error">{errors.name}</div>
                  ) : null}
                </Col>
              </Row>
              <Row className="col-10">
                <Col xs={12} className="p-0">
                  <Label>Data de Validade</Label>
                </Col>
                <Col xs={12} className="p-0">
                  <Row>
                    <Col xs={4}>
                      <StyledField
                        className="text-center"
                        type="text"
                        name="month"
                        render={({field}) => (
                          <MaskedInput
                            className="text-center"
                            {...field}
                            placeholder="Mês"
                            mask={monthMask}
                            placeholderChar=" "
                          />
                        )}
                      />
                    </Col>
                    <Col xs={4}>
                      <StyledField
                        className="text-center"
                        type="text"
                        name="year"
                        render={({field}) => (
                          <MaskedInput
                            className="text-center"
                            {...field}
                            placeholder="Ano"
                            mask={yearMask}
                            placeholderChar=" "
                          />
                        )}
                      />
                    </Col>
                  </Row>
                  {(showErros && errors.month) || (showErros && errors.year) ? (
                    <>
                      <div className="Form-Error">{errors.month}</div>
                      <div className="Form-Error">{errors.year}</div>
                    </>
                  ) : null}
                </Col>
              </Row>
              <Row className="col-6">
                <Col xs={12} className="p-0">
                  <Label>Código de segurança</Label>
                </Col>
                <Col xs={12} className="p-0">
                  <StyledField
                    type="text"
                    name="code"
                    render={({field}) => (
                      <MaskedInput
                        className="text-center"
                        {...field}
                        mask={codeMask}
                        placeholderChar=" "
                      />
                    )}
                  />
                  {showErros && errors.code ? (
                    <div className="Form-Error">{errors.code}</div>
                  ) : null}
                </Col>
              </Row>
              <Row className="col-6">
                <Col xs={12} className="p-0">
                  <Label>Opções de pagamento</Label>
                </Col>
                <Col xs={12} className="p-0">
                  <StyledField
                    as="select"
                    name="payment"
                    onChange={(e) => setParcelas(e.target.value)}>
                    {instalments.map((item) => (
                      <option value={item.quantity}>
                        {`${item.quantity}x de R$ ${priceFormat(
                          item.installmentAmount,
                        )} | (R$${item.totalAmount})`}
                      </option>
                    ))}
                  </StyledField>
                  {(errors.payment && touched.payment) ||
                  (showErros && errors.payment) ? (
                    <div className="Form-Error">{errors.payment}</div>
                  ) : null}
                </Col>
              </Row>
              <Row className="col-10">
                <Col className="p-0">
                  <button
                    className="LoadMoreButtom col py-2"
                    type="submit"
                    onClick={() =>
                      Object.values(errors).length > 0
                        ? setShowErrors(true)
                        : null
                    }>
                    {loading ? (
                      <Spinner />
                    ) : (
                      'Continuar com o cartão de crédito'
                    )}
                  </button>
                </Col>
              </Row>
            </ContainerForm>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CreditCardPagSeguro;
