import React from 'react';
import PropTypes from 'prop-types';
import {
  Banner,
  BannerLink,
  DescriptionBanner,
  TitleBanner,
  BannerItem,
} from './style';
import stripTags from 'striptags';

const BannerImg = (props) => {
  return (
    <Banner className="Banner-item">
      <img src={props.image} alt="banner" />
      <BannerItem className="legend-custom">
        <TitleBanner>{props.title}</TitleBanner>
        <DescriptionBanner
          dangerouslySetInnerHTML={{__html: stripTags(props.description)}}
        />
        <BannerLink to={props.url}>
          {props.textButton}
          <i className="far fa-arrow-alt-circle-right" />
        </BannerLink>
      </BannerItem>
    </Banner>
  );
};
BannerImg.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

export default BannerImg;
