import styled from 'styled-components';

export const FaqItem = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  margin-bottom: 20px;
`;

export const DadosFaq = styled.div`
  padding: 0px !important;
  margin: 0px;
  height: 0px;
  flex: 0 0 0 !important;
  background-color: #fff;
  &.active {
    height: 100% !important;
  }
`;

export const TitleQuestion = styled.div`
  margin: 0;
  font-size: 16px;
  text-align: left;
  color: #000;
  display: flex;
  justify-content: space-between;
  padding: 0px;
  align-items: center;
  font-weight: bold;
  &.active {
    background-color: #f3f3f3;
  }
  & i {
    height: 100%;
    background-color: #fcc06d;
    color: #fff;
    font-size: 22px;
    padding: 10px 15px;
  }
  & button {
    border: none;
    padding: 0;
  }
`;

export const DescriptionFaq = styled.p`
  font-weight: 100;
  text-align: left;
  font-size: 15px;
  /* font-style: italic; */
  margin-bottom: 25px;
  padding: 15px;
`;

export const LabelButtom = styled.label`
  cursor: pointer;
  & i {
    transition: ease all 300ms;
  }
  &:hover i {
    opacity: 0.8;
  }
`;

export const Checkbox = styled.input`
  display: none;
  &:checked + div {
    height: 100% !important;
    & p {
      opacity: 1;
    }
  }
`;
