import {getExamApproval} from '../services/endpoints';

export const isApproved = async (contentId, contentType) => {
  try {
    const {data} = await getExamApproval(
      atob(localStorage.getItem('user-id')),
      contentType === 'course'
        ? `[course.id]=${contentId}`
        : `[lesson.id]=${contentId}`,
    );
    return data && data[0] && data[0].approved;
  } catch (err) {
    console.error(err);
  }
};
