import React from 'react';
import Base from '../../components/Theme/base';
import Title from '../../components/Theme/Title';
import { FormStyle,RoundedPhoto,PerfilPhoto } from './styles';
import { Container, Row, Col } from 'reactstrap';
import photo from '../../assets/images/photo.png'

const Cadastrar = () => {

   
    return (
        <Base>
            <Title value="Meus Dados"/>
            <Container className="p-0 mb-5 pb-5">
                
                 <FormStyle className="d-flex flex-column">
                    <form action="#" method="post">
                        <Row className="mb-3 justify-content-center justify-content-lg-start">
                            <Col xs="12" md="3" lg="2">
                                  <PerfilPhoto className="px-4">
                                      <RoundedPhoto>
                                          <img src={photo} alt="foto de perfil"/>
                                      </RoundedPhoto>
                                        <input id="photo" type="file" className="d-none"/>
                                        <label className="text-center" htmlFor="photo"><b>Alterar foto</b></label>      
                                  </PerfilPhoto>
                            </Col>
                            <Col xs="12" md="12" lg="4">
                                <Col xs="12">
                                    <div className="has-float-label">
                                        <input id="input_name" type="text" className="w-100" placeholder="Nome" />
                                        <label htmlFor="input_name"><b>Nome</b></label>                            
                                    </div>                            
                                </Col>
                                <Col xs="12" className="pt-4" >
                                    <div className="has-float-label">
                                        <input id="input_tel" type="text" className="w-100" placeholder="Telefone" />
                                        <label htmlFor="input_tel"><b>Telefone</b></label>                            
                                    </div>                            
                                </Col>                           
                            </Col>
                            <Col xs="12" md="12" lg="4"className="pt-4 pt-lg-0">
                                <Col xs="12" >
                                    <div className="has-float-label">
                                        <input id="input_email" type="text" className="w-100" placeholder="Email" />
                                        <label htmlFor="input_email"><b>Email</b></label>                            
                                    </div>  
                                </Col>
                            </Col>
                        </Row>
                        <Row className="mt-5 pt-5">
                            
                            <Col xs="12" md="12" lg="6">
                                <Col xs="12">
                                    <div className="has-float-label">
                                        <input id="input_address" type="text" className="w-100" placeholder="Endereço" />
                                        <label htmlFor="input_address"><b>Endereço</b></label>                            
                                    </div>                            
                                </Col>
                                <Col xs="12" className="pt-4" >
                                    <div className="has-float-label">
                                        <input id="input_city" type="text" className="w-100" placeholder="Cidade" />
                                        <label htmlFor="input_city"><b>Cidade</b></label>                            
                                    </div>                            
                                </Col>                           
                            </Col>
                            <Col xs="12" md="12" lg="2" className="pt-4 pt-lg-0">
                                <Col xs="12">
                                    <div className="has-float-label">
                                        <input id="input_number" type="text" className="w-100" placeholder="Número" />
                                        <label htmlFor="input_number"><b>Número</b></label>                            
                                    </div>                            
                                </Col>
                                <Col xs="12" className="pt-4" >
                                    <div className="has-float-label">
                                        <input id="input_cep" type="text" className="w-100" placeholder="CEP" />
                                        <label htmlFor="input_cep"><b>CEP</b></label>                            
                                    </div>                            
                                </Col>                           
                            </Col>
                            <Col xs="12" md="12" lg="4" className="pt-4 pt-lg-0">
                                <Col xs="12">
                                    <div className="has-float-label">
                                        <input id="input_bairro" type="text" className="w-100" placeholder="Bairro" />
                                        <label htmlFor="input_bairro"><b>Bairro</b></label>                            
                                    </div>                            
                                </Col>
                                <Col xs="12" className="pt-4" >
                                    <div className="has-float-label">
                                        <input id="input_uf" type="text" className="w-100" placeholder="UF" />
                                        <label htmlFor="input_uf"><b>UF</b></label>                            
                                    </div>                            
                                </Col>                           
                            </Col>
                        </Row>
                        
                        <Row className="pt-5 mt-5">
                            <Col xs="12" md="12" lg="3">
                                <Col xs="12">
                                    <div className="has-float-label">
                                        <input id="input_pass" type="password" className="w-100" placeholder="senha" />
                                        <label htmlFor="input_pass"><b>Senha</b></label>                            
                                    </div>                            
                                </Col>
                                <Col xs="12" className="pt-4" >
                                    <div className="has-float-label">
                                        <input id="input_confirm" type="password" className="w-100" placeholder="Confirmar Senha" />
                                        <label htmlFor="input_confirm"><b>Confirmar Senha</b></label>                            
                                    </div>                            
                                </Col>                           
                            </Col>
                            <Col xs="12" sm="12" lg="9" className="pt-4 pt-lg-0 d-flex justify-content-end align-items-center">
                                <button className="col-12 col-lg-3 LoadMoreButtom">Cadastrar</button> 
                            </Col>
                        </Row>
                     </form>
                     


                 </FormStyle>
                
                
            </Container>
        </Base>
    )

};
export default Cadastrar;
