import React, {useState, useEffect} from 'react';
import SubTitle from '../../../components/Theme/Title/SubTitle';
import {Col, Row} from 'reactstrap';
import {Spinner} from 'reactstrap';
import PagSeguroLogo from '../../../assets/images/pagseguro-logo.png';
import {useCheckout} from '../../../store/Checkout';
import CreditCardPagSeguro from './CreditCard';
import {useHistory} from 'react-router-dom';
import {setDataPayment} from '../../../services/ecom';

const PagSeguro = () => {
  const [showForm, setShowForm] = useState(false);
  const {cart, setPaymentsForm} = useCheckout();
  const [loading, setLoading] = useState(false);
  const [typePayment, setTypePayment] = useState(null);
  const history = useHistory();

  const payment = async () => {
    try {
      setLoading(true);
      const {data} = await setPaymentsForm(cart.id, 'pagseguro_direct');
      window.PagSeguroDirectPayment.setSessionId(data.paymentData.sessionId);
      window.PagSeguroDirectPayment.onSenderHashReady(function (response) {
        setShowForm(true);
      });

      window.PagSeguroDirectPayment.getPaymentMethods({
        amount: 500.0,
        error: function (response) {
          alert('Erro ao carregar métodos de pagamento');
          // Callback para chamadas que falharam.
        },
        success: function (response) {
          setLoading(false);
          // Callback para todas chamadas.
        },
      });
    } catch (error) {
      setLoading(false);
      console.error(error);
      alert(
        (error &&
          error.response &&
          error.response.data &&
          error.response.data.detail) ||
          'Ocorreu um erro.',
      );
    }
  };

  const sendMethod = (method) => {
    window.PagSeguroDirectPayment.onSenderHashReady(async function (hash) {
      try {
        const responseData = await setDataPayment({
          order: cart.id,
          paymentData: {
            method: method,
            sender_hash: hash.senderHash,
          },
        });
        history.push('/checkout/review');
      } catch (err) {
        console.error(err);
      }
    });
  };

  return (
    <div className="formas-pagamento">
      <SubTitle value="Pague com a Pagseguro" />
      <Row className="col-10 boleto my-5">
        <Col xs={12} className="mb-5">
          <img src={PagSeguroLogo} />
        </Col>
        {showForm && typePayment === null && (
          <>
            <Col xs={6}>
              <button
                onClick={() => setTypePayment('CREDIT_CARD')}
                className="LoadMoreButtom col py-2 m-0">
                Cartão de Crédito
              </button>
            </Col>
            <Col xs={6}>
              <button
                onClick={() => sendMethod('BOLETO')}
                className="LoadMoreButtom col py-2 m-0">
                Boleto
              </button>
            </Col>
          </>
        )}
        {typePayment === 'CREDIT_CARD' && <CreditCardPagSeguro />}
        {!showForm && (
          <Col xs={12} className="mt-5">
            <button
              onClick={() => payment()}
              disabled={loading ? 'disabled' : false}
              className="LoadMoreButtom col py-2 m-0">
              {loading ? <Spinner /> : 'Continuar com a Pagseguro'}
            </button>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default PagSeguro;
