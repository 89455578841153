import React, { useState } from 'react';
import { Card, ImageCont, BtnAvaliable, BtnUnavaliable } from './style';
import { getReward } from '../../services/endpoints';
import userPlaceholder from '../../assets/images/user_placeholder.png';

const PremioItem = ({
    setModal,
    setRefresh,
    name,
    cost,
    status,
    amount,
    id,
    image,
}) => {
    const [loading, setLoading] = useState(false);

    const getAward = (id) => {
        setLoading(true);
        getReward({
            reward: {
                id: id,
            },
        })
            .then(() => {
                setLoading(false);
                setModal(true);
                setRefresh((prev) => !prev);
            })
            .catch((err) => {
                setLoading(false);
                console.error('Erro ao registrar premio', err);
            });
    };

    return (
        <Card>
            <ImageCont>
                <img src={image || userPlaceholder} alt="" />
            </ImageCont>
            <p>{name}</p>
            <h4>{cost}</h4>
            {status === 'available' ? (
                amount >= cost ? (
                    <BtnAvaliable disabled={loading} onClick={() => getAward(id)}>
                        {loading ? 'Carregando...' : 'Trocar pontos'}
                    </BtnAvaliable>
                ) : (
                        <BtnUnavaliable>Pontos insuficientes</BtnUnavaliable>
                    )
            ) : (
                    <BtnUnavaliable>
                        {status === 'CONCLUIDO' ? 'Concluido' : 'Entrege'}
                    </BtnUnavaliable>
                )}
        </Card>
    );
};

export default PremioItem;
