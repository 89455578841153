import styled from 'styled-components';

export const Item = styled.div`
  font-size: 20px;
  border-bottom: 1px solid #e1e1e1;
  display: flex;
  justify-content: space-between;
  padding-top: 1em;

  & .discount {
    color: red;
  }
  & .total {
    color: #038e83;
  }
`;
export const Value = styled.p`
  font-size: 20px;
`;
