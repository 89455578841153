import api from "../../../api";

export const getCourses = (params = {}) => {
  return api.get("/lms/courses?active=true", {
    params: {
      ...params,
    },
  });
};

export const getCoursesTotal = (params = {}) => {
  return api.get("/lms/courses/count_by?active=true", {
    params: {
      ...params,
    },
  });
};

export const getCoursesBySlug = (slug, params = {}) =>
  api.get("/lms/courses", {
    params: {
      slug,
      ...params,
    },
  });

export const getCoursesByKeyword = (keyword) => {
  return api.get(`/lms/courses?course_search=${keyword}&active=true`);
};

export const getCoursesByCategory = (category) => {
  return api.get(`/lms/courses?category_id=${category}`);
};

export const getCoursesOngoing = () => {
  return api.get(`/lms/course_stats?course_status=EM_ANDAMENTO`);
};

export const getCoursesCompleted = () => {
  return api.get(`/lms/course_stats?course_status=CONCLUIDO`);
};

export const getCoursesMostWatcheds = () => {
  return api.get(`/lms/courses?order[views]=desc`);
};

export const postCourse = (body) => {
  return api.post("lms/course_stats", body);
};

export const updateCourse = (courseId, params = {}) => {
  return api.put(`lms/course_stats/${courseId}`, {
    ...params,
  });
};

export const getCourseStatus = (courseId) => {
  return api.get(`lms/course_stats?wherein[course.id]=${courseId}`);
};
