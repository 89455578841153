import React from 'react';
import Base from '../../components/Theme/base';

const Internal = ({ match }) => {
   const {slug} = match.params;

  return(
    <Base slug={slug} />
  )
}

export default Internal;
