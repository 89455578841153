import styled from 'styled-components';

export const ContentFaq = styled.div`
    display:flex;
    padding:20px
    align-items:center;
    justify-content:start;
    .faqItem{
        flex:unset!important;
        overflow:hidden;
        
    }
    .contentFaq{
        border: 1px solid #f3f3f3;
        border-top: none;
    }
`;

export const ContainerFaq = styled.div`
  width: 100%;
  height: 100%;

  & .title {
    max-width: 500px;
  }
`;
