import React, {useState, useEffect} from 'react';
import ContainerLoading from '../../components/Theme/Loading';
import api from '../../services/api';
import PropTypes from 'prop-types';
import stripTags from 'striptags';
import format from '../../utils/format';
import {
  CursoItem,
  Cursoimage,
  InfoCurso,
  Stars,
  Duration,
  Views,
  DadosCurso,
  TitleCurso,
  DescriptionCurso,
  UserImg,
  Icon,
  ContainerPriceCourse,
  PriceCourse,
  DiscountPrice,
  NormalPrice,
} from './style';

const CourseDetailItem = ({course, handleBuyNow, ...props}) => {
  const [evaluation] = useState(course.nota);
  const [loading] = useState(true);

  // useEffect(() => {}, []);
  const stars = [];
  for (var i = 0; i < 5; i++) {
    evaluation.ratings > i ? stars.push('fas') : stars.push('far');
  }

  return loading === false ? (
    <ContainerLoading />
  ) : (
    <CursoItem className={`ml-0 mx-sm-2 ml-lg-4 mr-0`}>
      <Cursoimage className={props.situation}>
        {course.image && course.image.urlPublica && (
          <UserImg src={course.image.urlPublica} alt="{props.titulo}" />
        )}
      </Cursoimage>
      <InfoCurso>
        {
          <Stars>
            {stars.map((star, i) => (
              <i key={`star-${i}`} className={`${star} fa-star`} />
            ))}
            <span className="font-italic"> {evaluation.ratings}/5</span>
          </Stars>
        }
        <div className="d-flex">
          <Duration>
            <Icon className="far fa-clock" />
            {course.duration}
          </Duration>
          <Views>
            <Icon className="fas fa-eye" />
            {course.views}
          </Views>
        </div>
      </InfoCurso>
      <DadosCurso>
        <TitleCurso>{course.titulo}</TitleCurso>
        <DescriptionCurso
          dangerouslySetInnerHTML={{
            __html: stripTags(course.description),
          }}></DescriptionCurso>
        <ContainerPriceCourse>
          <PriceCourse>
            {course.price > 0 && (
              <DiscountPrice className="discount-price">
                R$ {course.price.toFixed(2)}
              </DiscountPrice>
            )}
            {course.promotional_price > 0 && (
              <NormalPrice className="normal-price">
                R$ {course.promotional_price.toFixed(2)}
              </NormalPrice>
            )}
          </PriceCourse>
        </ContainerPriceCourse>
        <div className="button">
          {course.enrollments &&
          course.enrollments.length &&
          course.enrollments[0].status === 'PENNDING' ? (
            <div>Pagamento em análise</div>
          ) : (
            <button
              className="LoadMoreButtom my-3 px-5"
              onClick={() => handleBuyNow(course)}>
              Comprar agora
            </button>
          )}
        </div>
      </DadosCurso>
    </CursoItem>
  );
};

CourseDetailItem.propTypes = {
  course: PropTypes.object.isRequired,
};

export default CourseDetailItem;
