import React, {useState, useLayoutEffect, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Row, Col, Container} from 'reactstrap';
import ProgressBar from '../../../components/Theme/ProgressBar';
import SingleVideo from './SingleVideo';
import Anexo from './Anexo';
import Question from './Questions';
import NewQuestion from './NewQuestion';
import {
  BlockSection,
  DescriptionSection,
  ModalExamSytled,
  LikeBar,
  BoxNextVideos,
  ContentStyle,
  RowAula,
  NumberAula,
  TitleAula,
  ProgressAula,
  LabelButtom,
  ViewedCheckbox,
  ActionsBar,
} from './styles';
import api from '../../../services/api';
import userPlaceholder from '../../../assets/images/user_placeholder.png';
import {
  HandleRefreshContext,
  HandleRefreshContextVideo,
} from './handleRefreshContext';
import PlayerVideo from '../../ContentMedia';
import Exame from '../../Exames';
import getCurrentUser from '../../../utils/getCurrentUser';
import stripTags from 'striptags';
import SambaPlaylist from '../../ContentMedia/SambaPlaylist';

const Aula = ({aula, reload, ...props}) => {
  const [lesson, setLesson] = useState({});
  const [questions, setQuestions] = useState([]);
  const [actualVideo, setActualVideo] = useState(0);
  const [like, setLike] = useState('none');
  const [viewed, setViewed] = useState(false);
  const [expand, setExpand] = useState(false);
  const [openExamModal, setOpenExamModal] = useState(false);
  const [progressLesson, setProgressLesson] = useState(0);
  const [refreshProgress, setRefreshProgress] = useState(false);
  const [contentType, setContentType] = useState('video/mp4');

  const getProgressLesson = () => {
    api
      .get(aula._id)
      .then((res) => {
        setLesson(res.data || {});
        let progresso =
          res.data.lesson_stats.length > 0 &&
          res.data.lesson_stats.filter(
            (lesson_stat) => lesson_stat.user_iri === getCurrentUser(),
          ).length > 0
            ? res.data.lesson_stats.filter(
                (lesson_stat) => lesson_stat.user_iri === getCurrentUser(),
              )[0].progress
            : 0;
        setProgressLesson(progresso);
      })
      .catch((err) => {
        console.error('Erro ao buscar Cursos', err);
      });
  };
  const getProgressCourse = () => {
    api
      .get(aula.course_iri)
      .then((res) => {
        props.refreshProgress(res.data.course_stats);
      })
      .catch((err) => {
        console.error('Erro ao buscar Cursos', err);
      });
  };

  function openExam() {
    setOpenExamModal(!openExamModal);
  }

  function changeVideo(idVideo) {
    setActualVideo(idVideo);
  }

  function commentsMount(questionList) {
    let questions = [];
    const userId = parseInt(atob(localStorage.getItem('user-id')), 10)
    questionList.filter(q => q.user && q.user.id === userId).forEach((element) => {
      let temp = [];
      questions.push({
        _id: `/lms/course_questions/${element.id}`,
        id: element.id,
        lesson_id: aula.id,
        user: {
          name: element && element.user ? element.user.name : 'Sem Nome',
          photo:
            element &&
            element.user &&
            element.user.profile &&
            element.user.profile.avatar &&
            element.user.profile.avatar.file
              ? element.user.profile.avatar.file.urlPublica
              : userPlaceholder,
        },
        data: element.created_at,
        content: element.question ? element.question : element.answer,
        answers: temp,
        question: !!element.question
      });
    });

    return questions;
  }
  function updateLike(value) {
    let endpoint = `/general/evaluations`;
    let data = {
      user_iri: atob(localStorage.getItem(`user-endpoint`)),
      content_iri: `${aula._id}/lesson_contents/${aula.videoaula[actualVideo].lessonContent}`,
      value: value,
      type: 'LIKE',
    };

    api.post(endpoint, data).then((res) => {
      if (res.data !== '' && res.data.type === 'LIKE') {
        setLike(res.data.value);
        setExpand((prev) => prev);
      }
    });
  }
  function getLikeVideo(index) {
    let endpoint = `/general/evaluations?content_iri=${aula._id}/lesson_contents/${aula.videoaula[index].lessonContent}`;
    api.get(endpoint).then((res) => {
      if (res.data.length > 0 && res.data[0].type === 'LIKE') {
        setLike(res.data[0].value);
      } else {
        setLike(-1);
      }
    });
  }

  function getViewedLesson() {
    api
      .get(
        `general/watcheds?match[content_iri]=/lms/lesson_contents/${aula.videoaula[actualVideo].lessonContent}&watched=true`,
      )
      .then((response) => {
        setViewed(!!response.data);
      });
  }

  async function handleViewed() {
    setViewed(true);

    await api.post(`/general/watcheds`, {
      user_iri: atob(localStorage.getItem(`user-endpoint`)),
      progress: 100,
      content_iri: `/lms/lesson_contents/${aula.videoaula[actualVideo].lessonContent}`,
      watched: false,
    });

    await api.post(`/general/watcheds`, {
      user_iri: atob(localStorage.getItem(`user-endpoint`)),
      progress: 100,
      content_iri: `/lms/lesson_contents/${aula.videoaula[actualVideo].lessonContent}`,
      watched: true,
    });

    getProgressCourse();
    getProgressLesson();
  }

  function handleRefreshComments() {
    api
      .get(`/lms/course_questions?wherein[lesson.id]=${aula.id}&order[id]=Desc`)
      .then((response) => {
        if (response.data.length) {
          setQuestions(commentsMount(response.data));
        } else {
          setQuestions([]);
        }
      });
  }

  const handlePlaylistClick = (data) => {
    let newEpisode = actualVideo;
    aula.videoaula.forEach((_episode, index) => {
      if (data.id === _episode.samba_media_reference) {
        newEpisode = index;
      }
    });
    setActualVideo(newEpisode);
  };

  useEffect(() => {
    getProgressLesson();
    getProgressCourse();
  }, [refreshProgress]);

  useLayoutEffect(() => {
    handleRefreshComments();
    if (aula.videoaula.length > 0) {
      getLikeVideo(actualVideo);
      getViewedLesson(actualVideo);
    }
  }, [actualVideo, expand]);

  const user = {
    photo: atob(localStorage.getItem(`user-photo`)),
    nome: atob(localStorage.getItem(`user-name`)),
  };

  const handleLessonLabelClick = (event) => {
    if (aula.availability === 'available') {
      setExpand((prev) => !prev);
      setContentType(aula.videoaula[actualVideo].contentType);
    } else {
      event.preventDefault();
    }
  };

  return (
    <BlockSection className="aula">
      <RowAula className={`d-flex flex-wrap ${expand ? 'expand' : ''}`}>
        <NumberAula className="p-0 d-flex justify-content-center py-2 col-12 col-md-1">
          Aula{' '}
          {props.index + 1 < 10 ? '0' + (props.index + 1) : props.index + 1}
        </NumberAula>
        <TitleAula className="col-6 col-md-8 title-aula">
          <h4>{aula.title}</h4>
        </TitleAula>
        {aula.videoaula.length > 0 ? (
          <ProgressAula className="col-3 col-md-2">
            <Col lg="12" className="p-0 d-flex align-items-center">
              <h4 className="progressTitle m-0 mr-1">
                {parseInt(progressLesson.toFixed(2))}%
              </h4>
              <ProgressBar progress={progressLesson} />
            </Col>
          </ProgressAula>
        ) : (
          <spam className="col-3 col-md-2 d-flex align-items-center justify-content-center title-type">
            Texto
          </spam>
        )}
        <LabelButtom
          onClick={(e) => handleLessonLabelClick(e)}
          htmlFor={`check-${props.index}`}
          data-index={props.index}
          className="m-0 p-0 col-1 d-flex align-items-center justify-content-center"
          style={{
            cursor: `${
              aula.availability === 'available' ? 'pointer' : 'default'
            }`,
          }}>
          <i
            className={
              aula.availability !== 'available'
                ? 'fas fa-lock'
                : `fal fa-${expand ? 'minus' : 'plus'}-circle`
            }
          />
        </LabelButtom>
        <input
          id={`check-${props.index}`}
          type="checkbox"
          value="expand"
          name="input-expand "
          className="input-expand d-none"
        />
        <ContentStyle className="col-12  flex-wrap expand-content">
          <h2 className="pl-0 mt-5 mb-4 col-12">{aula.title}</h2>
          {aula.videoaula.length > 0 && (
            <h5>
              <b>Video </b>
              {actualVideo + 1} de {aula.videoaula.length}{' '}
            </h5>
          )}
          <Row className="w-100" noGutters>
            <Col lg={aula.videoaula.length > 1 ? 8 : 12} className="pr-3">
              {expand &&
                aula.videoaula.length > 0 &&
                (aula.videoaula.length === 1 ? (
                  <PlayerVideo
                    setRefreshProgress={setRefreshProgress}
                    video={aula.videoaula[actualVideo]}
                  />
                ) : (
                  <SambaPlaylist
                    playlist={aula.videoaula}
                    currentContentPosition={actualVideo}
                    onPlaylistClick={handlePlaylistClick}
                    setRefreshProgress={setRefreshProgress}
                    key={actualVideo}
                  />
                ))}

              <ActionsBar className={`${viewed ? 'justify-content-end' : ''}`}>
                {aula.videoaula.length > 0 &&
                  !viewed &&
                  contentType === 'application/pdf' && (
                    <ViewedCheckbox
                      onClick={handleViewed}
                      className="d-flex align-items-center">
                      <b>Marcar como visto </b>{' '}
                      <i
                        className={`ml-2 mr-5 ${
                          viewed ? 'fas fa-check-square' : 'far fa-square'
                        }`}
                      />
                    </ViewedCheckbox>
                  )}
                <LikeBar className="justify-self-flex-end">
                  {aula.videoaula.length > 0 && (
                    <>
                      <button onClick={(e) => updateLike(1)}>
                        <i
                          className={`${
                            like === '1' ? 'fas' : 'far'
                          } fa-thumbs-up`}
                        />
                      </button>
                      <button onClick={(e) => updateLike(0)}>
                        <i
                          className={`${
                            like === '0' ? 'fas' : 'far'
                          } fa-thumbs-down`}></i>
                      </button>
                    </>
                  )}
                </LikeBar>
              </ActionsBar>
            </Col>
            {aula.videoaula.length > 1 && (
              <Col lg={4}>
                <h4>Próximos videos desta aula</h4>
                <HandleRefreshContextVideo.Provider value={{changeVideo}}>
                  <BoxNextVideos>
                    {aula.videoaula.map((aula, i) => (
                      <SingleVideo
                        key={`video-${i}-${refreshProgress}`}
                        aula={aula}
                        lesson={
                          (lesson.lesson_contents &&
                            lesson.lesson_contents[i]) ||
                          {}
                        }
                        indice={i}
                        isActualVideo={actualVideo === i}
                      />
                    ))}
                  </BoxNextVideos>
                </HandleRefreshContextVideo.Provider>
              </Col>
            )}
          </Row>
          <DescriptionSection className="row w-100 no-gutters">
            <Col lg={aula.complemento.length > 0 ? 8 : 12}>
              <div className="description">
                <h2 className="my-4">Descrição</h2>
                <p
                  dangerouslySetInnerHTML={{__html: stripTags(aula.descricao)}}
                />
              </div>
              <div className="questions">
                <h2 className="my-4">Anotações</h2>
                <HandleRefreshContext.Provider value={{handleRefreshComments}}>
                  <div className="list-questions d-flex flex-column pr-4">
                    <NewQuestion
                      answer={false}
                      course_id={aula.course_id}
                      lesson_id={aula.id}
                      user={user}
                    />
                    {typeof questions !== 'undefined' &&
                      questions.length > 0 &&
                      questions.map((question, i) => (
                        <Question
                          key={`question-${i}`}
                          index={`${aula.id}-${i}`}
                          pergunta={question}
                        />
                      ))}
                  </div>
                </HandleRefreshContext.Provider>
              </div>
            </Col>
            {aula.complemento.length > 0 && (
              <Col lg="4">
                {aula.videoaula.length > 0 && (
                  <>
                    <h2 className="my-4">Material Complementar</h2>
                    <small>Baixe o material complementar desta aula</small>
                    <div className="anexos">
                      {aula.complemento.map((anexo, i) => (
                        <Anexo
                          key={`att-${i}`}
                          url={anexo.url}
                          title_file={anexo.title}
                        />
                      ))}
                    </div>
                  </>
                )}
              </Col>
            )}

            {progressLesson >= 100 && aula && aula.exams && aula.exams[0] && (
              <button
                onClick={(e) => openExam()}
                className="col-12 LoadMoreButtom">
                Fazer prova dessa Aula Agora
              </button>
            )}
          </DescriptionSection>
        </ContentStyle>
      </RowAula>
      {openExamModal === true && (
        <ModalExamSytled>
          <Container className="exam-style">
            <Row className="d-flex justify-content-end">
              <button
                onClick={(e) => {
                  reload();
                  openExam();
                }}
                className="close-button">
                <i className="fas fa-times"></i>
              </button>
            </Row>

            <Exame
              exams={aula.exams}
              isLesson={true}
              typeExame={`lesson_id=${aula.id}`}
            />
          </Container>
        </ModalExamSytled>
      )}
    </BlockSection>
  );
};

Aula.propTypes = {
  aula: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
};
export default Aula;
