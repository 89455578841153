import styled from 'styled-components';
import {Link} from 'react-router-dom';

export const CursoItem = styled(Link)`
    display: flex;
    flex-direction: column;
    background-color: #f3f3f3;
    transition:ease all 300ms;
    text-decoration: none;
    cursor:pointer
    color:#252527;
    &:hover {
        color:#fff;
        text-decoration: none;
        background-color:#038E83;
        & h2,& p,& h4,& .fas,
        fas,& .far{
            color:#fff;
        }

        & .discount-price {
          color: #a1a1a1;
        }
        & .normal-price {
          zoom: 1.05;
        }
    }
    &.oneQuater{
        max-width:25%!important;
    }
    
`;
export const Cursoimage = styled.div`
  position: relative;
  padding-bottom: 58%;
  overflow: hidden;
  background-color: #ffffff;
  &.atual:before {
    content: 'Em andamento';
    position: absolute;
    width: 100%;
    background-color: #00000087;
    /* padding-bottom: 58%; */
    height: 100%;
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
  }
  &.locked:before {
    content: '\f30d';
    position: absolute;
    width: 100%;
    background-color: #00000087;
    /* padding-bottom: 58%; */
    height: 100%;
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-family: 'Font Awesome 5 Pro';
    font-size: 1.3em;
  }
  &.concluido-locked:before {
    content: 'Concluído';
    position: absolute;
    width: 100%;
    background-color: #000000db;
    /* padding-bottom: 58%; */
    height: 100%;
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
  }
  &.concluido-atual:before {
    content: 'Concluído';
    position: absolute;
    width: 100%;
    background-color: #000000db;
    /* padding-bottom: 58%; */
    height: 100%;
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
  }
`;
export const InfoCurso = styled.div`
  display: flex;
  padding: 15px 0px;
  justify-content: space-between;
  font-size: 12px;
  border-bottom: 1px solid #8591a526;
  margin: 0 15px;
  align-items: center;
  @media (max-width: 1200px) {
    font-size: 0.6em;
  }
  @media (max-width: 768px) {
    font-size: 0.8em;
  }
`;
export const TextContent = styled.div`
  position: absolute !important;
  margin: 15px 0px;
`;
export const Stars = styled.div``;
export const Icon = styled.i`
  margin-right: 3px;
`;
export const Duration = styled.p`
  margin: 0 0 0px;
`;
export const Views = styled.p`
  margin: 0 0 0px;
`;
export const DadosCurso = styled.div`
  padding: 15px;
  padding-top: 0;
`;
export const Professor = styled.div`
  display: flex;
`;
export const ImgProfessor = styled.div`
  border-radius: 50%;
  display: block;
  width: 30px;
  height: 30px;
  overflow: hidden;
  position: relative;
  margin-right: 5px;
`;
export const UserImg = styled.img`
  position: absolute;
  left: 0;
  object-fit: cover;
  height: 100%;
`;
export const NomeProfessor = styled.p`
  margin: 0;
  font-size: 13px;
  text-align: left;
  color: #000;
`;
export const DataCurso = styled.p`
  margin: 0;
  font-size: 13px;
  text-align: left;
  color: #000;
`;
export const TitleCurso = styled.h4`
  font-weight: bold;
  text-align: left;
  font-size: 1.1em;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  min-height: 52px;
`;
export const DescriptionCurso = styled.p`
  margin: 10px 0;
  font-size: 13px;
  text-align: left;
  color: #000;
  font-style: italic;
  font-weight: 100;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;
export const CursoLink = styled.span`
  text-align: left;
  color: #fcc06d;
  display: block;
  font-size: 22px;
`;
export const BlockSection = styled.div`
  margin: 40px 0;
  .slider {
    margin: 60px 0 0;
  }
`;
export const TitleCursos = styled.span`
  background-color: #fcc06d;
  color: #fff;
  padding: 25px 15px;
  margin: 60px 0;
  @media (max-width: 991px) {
    margin: 0px 0;
  }
`;
export const BoxCursos = styled.div`
  margin: 60px 0;
  background-color: #038e83;
  color: #fff;
  padding: 15px;
  & h3 {
    font-size: 2em;
    font-weight: 100;
  }
  & p {
    font-weight: 300;
  }
  & a {
    color: #fff;
    font-size: 25px;
  }
  & a:hover {
    color: #fff;
    opacity: 0.85;
  }
  @media (max-width: 991px) {
    margin: 0px 0;
  }
`;

export const ContainerPriceCourse = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const PriceCourse = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const NormalPrice = styled.h4`
  font-size: 20px;
  color: #038e83;
  background-color: transparent;
  font-weight: bold;
  margin-bottom: 0px;
`;

export const DiscountPrice = styled.h4`
  font-style: italic;
  font-size: 18px;
  text-decoration: line-through;
  margin-right: 10px;
  margin-bottom: 0px;
`;

export const Info = styled.div`
  padding: 0;
`;

export const InfoItem = styled.p`
  position: relative;
  font-size: 12px;
  margin-left: 12px;

  &:before {
    content: '';
    position: absolute;
    width: 6px;
    height: 6px;
    background-color: #fcc06d;
    border-radius: 50%;
    left: -12px;
    top: 5px;
  }
`;
