import React, {useState, useEffect} from 'react';
import {Spinner, Col, Row, Container} from 'reactstrap';
import Base from '../../components/Theme/base';
import Title from '../../components/Theme/Title';
import {useCheckout} from '../../store/Checkout';
import Resume from '../../components/Cart/Resume';
import Pagseguro from './PagSeguro';
import {Review} from './style';
import {useHistory} from 'react-router-dom';

const CheckoutReview = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [review, setReview] = useState();
  const [isSubmitting, setSubmitting] = useState(false);
  const {
    cart,
    setCart,
    items,
    getSubtotal,
    getDiscount,
    getTotal,
    getReviewPayment,
    confirmPaymentCheckout,
  } = useCheckout();
  useEffect(() => {
    if (cart) {
      (async () => {
        try {
          setLoading(true);
          const {data} = await getReviewPayment(cart.id);
          setReview(data);
          setLoading(false);
        } catch (error) {
          console.error(error);
          alert(
            (error &&
              error.response &&
              error.response.data &&
              error.response.data.detail) ||
              'Ocorreu um erro.',
          );
          setLoading(false);
        }
      })();
    }
  }, [cart]);

  // const getMethod = (name) => {
  //   console.log(methods.find((item) => item.method === name));
  //   return methods.find((item) => item.method === name);
  // };

  const submit = async () => {
    try {
      setSubmitting(true);
      const resp = await confirmPaymentCheckout();
      if (review.paymentData.method === 'BOLETO') {
        window.open(resp.data.paymentData.url, '_blank');
      }
      setSubmitting(false);
      history.push('/');
    } catch (error) {
      console.error(error);
      alert(
        (error &&
          error.response &&
          error.response.data &&
          error.response.data.detail) ||
          'Ocorreu um erro.',
      );
      setSubmitting(false);
    }
    setCart(null);
  };

  return (
    <Base>
      <Title value="Finalize sua compra" />
      {loading ? (
        <Spinner />
      ) : (
        <Container>
          <Review>
            <Row className="justify-content-between my-5">
              {items.length > 0 ? (
                <>
                  <Col xs={12} lg={6} className="p-0">
                    <h2>Items</h2>
                    {review && review.orderItems && (
                      <div className="review-items">
                        <b>Quantidade:</b>
                        <b>Curso:</b>
                        <b>Valor:</b>
                        {review.orderItems.map((item) => (
                          <React.Fragment key={item.name}>
                            <div className="row-grid">{item.count}</div>
                            <div className="row-grid">{item.name}</div>
                            <div className="row-grid">{item.price}</div>
                          </React.Fragment>
                        ))}
                      </div>
                    )}
                    <h2>Dados do pagamento</h2>
                    {review && review.paymentData.method === 'CREDIT_CARD' && (
                      <div className="review-credit-card">
                        <b>Método:</b>
                        <div className="row-grid">
                          {review.paymentData.method === 'CREDIT_CARD'
                            ? 'CARTÃO DE CRÉDITO'
                            : 'BOLETO'}
                        </div>

                        <b>Nome do titular:</b>
                        <div className="row-grid">
                          {review.paymentData.creditcard.holder.name}
                        </div>

                        <b>Telefone do titular:</b>
                        <div className="row-grid">
                          {review.paymentData.creditcard.holder.phone}
                        </div>

                        <b>CPF do titular:</b>
                        <div className="row-grid">
                          {review.paymentData.creditcard.holder.cpf}
                        </div>

                        <b>Data de Nascimento do Titular:</b>
                        <div className="row-grid">
                          {review.paymentData.creditcard.holder.birth_date}
                        </div>

                        <b>Quantidade de Parcelas:</b>
                        <div className="row-grid">
                          {review.paymentData.creditcard.installment.quantity}x
                        </div>
                      </div>
                    )}
                    {review && review.paymentData.method === 'BOLETO' && (
                      <div className="review-credit-card">
                        <b>Método:</b>
                        <div className="row-grid">BOLETO</div>
                      </div>
                    )}
                  </Col>
                  <Col xs={12} lg={5} className="mt-4 mt-lg-0">
                    <Resume
                      items={items}
                      getSubtotal={getSubtotal}
                      getDiscount={getDiscount}
                      getTotal={getTotal}
                    />
                  </Col>
                </>
              ) : (
                <div>
                  <h2>Ainda não possui itens no carrinho. :(</h2>
                </div>
              )}
              {items.length > 0 && (
                <Col xs={12}>
                  <button
                    className="LoadMoreButtom col py-2"
                    type="submit"
                    onClick={() => submit()}>
                    {isSubmitting ? (
                      <Spinner />
                    ) : review && review.paymentData.method === 'BOLETO' ? (
                      'Finalizar compra e gerar Boleto'
                    ) : (
                      'Finalizar Compra'
                    )}
                  </button>
                </Col>
              )}
            </Row>
          </Review>
        </Container>
      )}
    </Base>
  );
};

export default CheckoutReview;
