import React from 'react';
import Base from '../../components/Theme/base';

const Home = () => {

  return(
    <Base slug="homepage" />
  )
}

export default Home;
