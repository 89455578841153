import React from 'react';
import PropTypes from 'prop-types';
import ComponentsHome from './ComponentList';
import ComponentsPage from './ComponetListPage';

const ComponentFactory = ({componentName, content, block, page}) => {
  let ComponentToRender;
  if (page) {
    ComponentToRender = ComponentsPage[componentName];
  } else {
    ComponentToRender = ComponentsHome[componentName];
  }
  if (ComponentToRender) {
    return <ComponentToRender block={block} content={content} />;
  }
  return null;
};

ComponentFactory.propTypes = {
  componentName: PropTypes.string.isRequired,
  content: PropTypes.any.isRequired,
};

export default ComponentFactory;
