import React, {useState, useEffect} from 'react';
import Base from '../../components/Theme/base';
import Title from '../../components/Theme/Title';
import InternalTitle from '../../components/Theme/Title/InternalTitle';
import CourseItem from '../../components/Home/Cursos/Distancia/CourseItem';
import {CourseBox} from './styles';
import photo from '../../assets/images/photo.png';
import {Container, Col, Row} from 'reactstrap';
import api from '../../services/api';
import {toSnakeCase} from '../../utils/toSnakeCase';
import Slider from 'react-slick';
import {FilterStyle, DivSlider} from './styles';
import userPlaceholder from '../../assets/images/user_placeholder.png';
import { TitleStyle } from '../../components/Theme/Title/styles'

const Trilhas = () => {
  const [trilhas, setTrilhas] = useState([]);

  useEffect(() => {
    api
      .get(`/lms/trails?active=true`)
      .then((trails) => {
        let arrayTrilhas = [];
        trails &&
          trails.data &&
          trails.data.forEach((trail, i) => {
            let dataCourses = [];

            trail &&
              trail.trails_courses &&
              trail.trails_courses.forEach((trail_course) => {
                let element = trail_course && trail_course.course;

                if (element) {
                  dataCourses.push({
                    _id: `/lms/courses/${element.id}`,
                    id: element.id,
                    image: {
                      urlPublica: element.image
                        ? element.image.urlPublica
                        : userPlaceholder,
                    },
                    nota: 5 || '',
                    duration: element.duration || '',
                    data: element.created_at || '',
                    views: element.views || '',
                    photo:
                      element.hasOwnProperty('created_by') &&
                      element.created_by.hasOwnProperty('profile') &&
                      element.created_by.profile.hasOwnProperty('avatar') &&
                      element.created_by.profile.avatar !== undefined &&
                      element.created_by.profile.avatar.file != null
                        ? element.created_by.profile.avatar.file.urlPublica
                        : userPlaceholder,
                    autor: element.hasOwnProperty('created_by')
                      ? element.created_by.name
                      : '',
                    titulo: element.title || '',
                    description: element.summary || '',
                    url: `/trails/${trail.id}`,
                  });
                }
              });

            if (dataCourses.length) {
              arrayTrilhas.push(
                Object.assign(trail, {
                  titulo: trail.title,
                  cursos: dataCourses,
                }),
              );
            }
          });

        setTrilhas(arrayTrilhas);
      })
      .catch((err) => {
        console.error('Erro ao buscar Trilhas: ', err);
      });

    return () => {
      setTrilhas([]);
    };
  }, []);
  const [loading, setLoading] = useState(false);
  const [qtd, setQtd] = useState(2);
  const [filter, setFilter] = useState('');

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1920,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  function expand() {
    setQtd((prevState) => prevState + 1);
  }
  return (
    <Base>
      <Container className="p-0 py-4">
        <Row>
          <Col xs={12} md={7} lg={8}>
            <TitleStyle className="title">Trilhas</TitleStyle>
          </Col>
          <Col xs={12} md={5} lg={4}>
            <FilterStyle>
              <div className="search-filter">
                <input
                  onKeyUp={(event) => setFilter(event.target.value)}
                  className="w-100"
                  placeholder="Digite aqui.."
                  type="text"
                />
                <i className="fas fa-search " />
              </div>
            </FilterStyle>
          </Col>
        </Row>
        {trilhas.length > 0 ? (
          <DivSlider className="mb-4">
            {trilhas.slice(0, qtd).map((trilha, i) => (
              <Row key={`TrilhaRow-${i}`}>
                <InternalTitle key={`TrilhaTitle-${i}`} value={trilha.titulo} />
                <Col xs="12" key={`TrilhaItem-${i}`} className="CourseBlock">
                  {trilha.cursos.length > 0 && (
                    <Slider {...settings}>
                      {trilha.cursos.map((course, j) => {
                        return filter === '' ||
                          course.titulo
                            .toLowerCase()
                            .indexOf(filter.toLowerCase()) > -1 ? (
                          <CourseBox key={`courseItem-${j}`}>
                            <span className="trilha"/>
                            <CourseItem course={course} />
                          </CourseBox>
                        ) : (
                          ''
                        );
                      })}
                    </Slider>
                  )}
                </Col>
              </Row>
            ))}
          </DivSlider>
        ) : (
          <h1>Nenhuma Trilha disponível</h1>
        )}
        {trilhas.length > 0 && trilhas.length > qtd ? (
          <Row className="p-4 p-lg-0">
            <button onClick={expand} className="col-12 col-lg-3 LoadMoreButtom">
              Carregar Mais
            </button>
          </Row>
        ) : (
          ''
        )}
      </Container>
    </Base>
  );
};
export default Trilhas;
