import React from 'react';
import PropTypes from 'prop-types';
import {
  MenuItem as MenuItemStyle,
  MenuLink,
  SubMenuContainer,
  MenuNoLink,
  SubMenuChildrenContainer,
} from './styles';

const MenuItem = (props) => {
  //menu_items
  if (props.menu_items && props.menu_items.length > 0) {
    return (
      <>
        <SubMenuContainer
          className={`${
            props.url && window.location.pathname.indexOf(props.url) > -1
              ? 'active'
              : ''
          } ${props.className || ''}`}>
          <MenuLink to={props.url} className="title-submenu">
            {props.title} <i className="fas fa-chevron-down ml-1" />{' '}
          </MenuLink>
          <SubMenuChildrenContainer className="submenu-container">
            {props.menu_items.map((menu, indice) => (
              <MenuItem
                className="menu-item"
                child
                key={`menu-item-${indice}`}
                title={menu.title}
                url={menu.url}
              />
            ))}
          </SubMenuChildrenContainer>
        </SubMenuContainer>
      </>
    );
  }

  return (
    <MenuItemStyle
      className={`${
        !props.child && props.url && window.location.pathname.indexOf(props.url) > -1
          ? 'active'
          : ''
      } ${props.className || ''}`}>
      <MenuLink to={props.url}>{props.title}</MenuLink>
    </MenuItemStyle>
  );
};

MenuItem.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

export default MenuItem;
