import React from 'react';
import {Item, Value} from './style';

const Resume = ({items = [], getSubtotal, getDiscount, getTotal}) => {
  const toBRL = (valor) =>
    Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(valor);

  return (
    <>
      <h1>Resumo da compra</h1>
      <Item>
        Subtotal (
        {items.length === 1 ? `${items.length} item` : `${items.length} itens`})
        <Value className="subtotal">{toBRL(getSubtotal())}</Value>
      </Item>
      <Item>
        Descontos{' '}
        <Value className="discount">
          - {getDiscount() ? `${getDiscount()}%` : ''}
        </Value>
      </Item>
      <Item>
        Total <Value className="total">{toBRL(getTotal())}</Value>
      </Item>
    </>
  );
};

export default Resume;
