import React, {useState, useEffect} from 'react';
import Script from 'react-load-script';
import api from '../../services/api';
import {SambaContainer, GoBackButton} from './styles';

const SambaVideo = ({reference, id, setRefreshProgress}) => {
  const [playerKey, setPlayerKey] = useState();
  const [load, setLoad] = useState(false);
  const GOBACKTIME = 10; // in seconds
  const progress = true;
  const player = 'player';
  let sambaPlayer = false;
  let setProgress = false;

  useEffect(() => {
    api.get('/settings/sambatech_player_key').then((response) => {
      setPlayerKey(response.data.value);
    });
  }, []);

  const goBack = () => {
    sambaPlayer.getStatus((media) => {
      sambaPlayer.seek(media.status.time - GOBACKTIME);
    });
  };

  if (load && playerKey) {
    const eventListener = (player) => {
      switch (player.event) {
        case 'onLoad':
          break;
        case 'onStart':
          api.post(`/general/watcheds`, {
            user_iri: atob(localStorage.getItem(`user-endpoint`)),
            progress: 0,
            content_iri: `/lms/lesson_contents/${id}`,
            watched: false,
          });
          break;
        case 'onListen':
          if (
            !setProgress &&
            player.eventParam > 1 &&
            parseInt(player.eventParam) % 15 === 0
          ) {
            setProgress = true;
            api.post(`/general/watcheds`, {
              user_iri: atob(localStorage.getItem(`user-endpoint`)),
              progress: (player.eventParam * 100) / player.duration,
              content_iri: `/lms/lesson_contents/${id}`,
            });
            setTimeout(() => {
              setProgress = false;
            }, 1000);
          }
          break;
        case 'onPause':
          break;
        case 'onResume':
          break;
        case 'onSeek':
          break;
        case 'onCuepoint':
          break;
        case 'onMediaView':
          break;
        case 'onProgress':
          break;
        case 'onFinish':
          api
            .post(`/general/watcheds`, {
              user_iri: atob(localStorage.getItem(`user-endpoint`)),
              progress: 100,
              content_iri: `/lms/lesson_contents/${id}`,
              watched: true,
            })
            .then((res) => {
              setRefreshProgress(new Date().toLocaleString());
            });
          break;
        case 'onError':
          break;
        default:
          break;
      }
    };

    if (!sambaPlayer) {
      sambaPlayer = new window.SambaPlayer(player, {
        ph: playerKey,
        m: reference,
        playerParams: {
          enableShare: false,
          resume: progress,
          enableControls: ['settings', 'fullscreen', 'play', 'time', 'volume'],
        },
        events: {
          '*': eventListener,
        },
      });
    }
  }

  return (
    <SambaContainer>
      <Script
        url="https://player.sambatech.com.br/v3/samba.player.api.js"
        onLoad={function () {
          setLoad(true);
        }}
      />

      <div className="embed-responsive embed-responsive-16by9 ">
        <div className="embed-responsive-item" id="player" />
      </div>
      {sambaPlayer && (
        <GoBackButton onClick={() => goBack()}>
          <i className="far fa-undo " />
          <span>Voltar {GOBACKTIME} segundos</span>
        </GoBackButton>
      )}
    </SambaContainer>
  );
};

export default SambaVideo;
