import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const ProgressBar = (props) => {
  const ProgessBarStyle = styled.div`
    flex-grow: 1;
    .Bar {
      height: 6px;
      border: 1px solid #fcc06d;
      width: 100%;
      border-radius: 12px;
      &:before {
        content: '';
        background-color: #fcc06d;
        width: ${props.progress}%;
        display: block;
        height: 100%;
        border-radius: 12px;
        transition: ease all 300ms;
      }
    }
  `;
  return (
    <ProgessBarStyle className="d-flex align-items-center">
      <span className="Bar"></span>
    </ProgessBarStyle>
  );
};
ProgressBar.propTypes = {
  progress: PropTypes.number.isRequired,
};

export default ProgressBar;
