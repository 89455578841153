import React, {useState, useEffect} from 'react';
import Base from '../../../components/Theme/base';
import CourseItem from '../../../components/Home/Cursos/Distancia/CourseItem';
import {Container, Col, Row} from 'reactstrap';
import api from '../../../services/api';
import userPlaceholder from './../../../assets/images/materdei_placeholder.png';
import {FilterStyle} from '.././../../components/Theme/Filter/styles';
import stripTags from 'striptags';
import {TitleStyle} from '../../../components/Theme/Title/styles';

const CursoDistancia = () => {
  const [loading, setLoading] = useState(true);
  const [courses, setCourses] = useState([]);
  const [filter, setFilter] = useState('');
  const filterType = 'titulo';

  useEffect(() => {
    api
      .get('/lms/courses', {
        params: {
          'order[highlight asc,courses.published_at]': 'desc',
          active: true,
          off_trails: true,
        },
      })
      .then((response) => {
        const getNota = (evaluations) => {
          const average = evaluations.filter((e) => e.type === 'RATING');
          return average.length ? average[0].average : 5;
        };

        const data = response.data
          .filter((item) => item.lessons.length || item.scorm)
          .map((element) => ({
            _id: `/lms/courses/${element.id}`,
            id: element.id,
            image: {
              urlPublica: element.image
                ? element.image.urlPublica
                : userPlaceholder,
            },
            nota: getNota(element.evaluations),
            duration: element.duration,
            data: element.created_at,
            views: element.views,
            price: element.price,
            promotional_price: element.promotional_price,
            titulo: element.title,
            description: element.summary,
            url: `/online/lms/courses/${element.id}`,
          }));
        setCourses(data);
        setLoading(false);
      })
      .catch((err) => {
        console.error('Erro ao buscar Cursos: ', err);
      });

    return () => {
      setCourses([]);
    };
  }, []);

  const [qtd, setQtd] = useState(4);
  const expand = () => {
    setQtd((prevState) => prevState + 4);
  };
  return (
    <Base>
      <Container className="py-4">
        <Row>
          <Col xs={12} md={7} lg={8}>
            <TitleStyle className="title">Cursos online</TitleStyle>
          </Col>
          <Col xs={12} md={5} lg={4}>
            <FilterStyle>
              <div className="search-filter">
                <input
                  onKeyUp={(event) => setFilter(event.target.value)}
                  className="w-100"
                  placeholder="Digite aqui.."
                  type="text"
                />
                <i className="fas fa-search " />
              </div>
            </FilterStyle>
          </Col>
        </Row>

        <Row>
          {courses.slice(0, qtd).map(
            (course, i) =>
              (filter === '' ||
                stripTags(course[filterType])
                  .toLowerCase()
                  .toString()
                  .indexOf(filter.toLowerCase()) > -1) && (
                <Col
                  key={`courseItem${i}`}
                  xs={12}
                  md={6}
                  lg={3}
                  className="mb-4 p-md-0">
                  <CourseItem key={`courseItem${i}`} course={course} />{' '}
                </Col>
              ),
          )}
        </Row>
        {!loading && courses.length === 0 && (
          <Row>
            <h1>Nenhum Curso disponível</h1>
          </Row>
        )}
        {courses.length > 0 && courses.length > qtd ? (
          <Row className="p-4 p-lg-0">
            <button onClick={expand} className="col-12 col-lg-3 LoadMoreButtom">
              Carregar Mais
            </button>
          </Row>
        ) : (
          ''
        )}
      </Container>
    </Base>
  );
};
export default CursoDistancia;
