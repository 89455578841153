import axios from 'axios';
import { getToken } from './auth';

const apiUrl = process.env.REACT_APP_API_URL;

const api = axios.create({
  baseURL: apiUrl,
  headers: { Authorization: getToken() },
});

api.interceptors.request.use(
  async (config) => config,
  (error) => Promise.reject(error),
);

api.interceptors.response.use(
  (response) => response,
  (error) => {
    const status = error && error.response ? error.response.status : error;

    switch (status) {
      case 401:
        window.location = `${process.env.REACT_APP_SIGNIN_URL}?logout=${btoa(
          document.location.origin,
        )}`;
        break;
      case 403:
        if (error.response.data.reason === 'expired_token') {
          window.location = `${process.env.REACT_APP_SIGNIN_URL}?login=${btoa(
            document.location.origin,
          )}`;
        } else {
          window.location = `${process.env.REACT_APP_SIGNIN_URL}?logout=${btoa(
            document.location.origin,
          )}`;
        }
        break;
      case 405:
        console.error('Method Not Allowed');
        break;
      case 406:
        console.error('Not Acceptable');
        break;
      case 422:
        console.error('Unprocessable Entity');
        break;
      default:
        console.error(`Error ${status}`);
    }

    return Promise.reject(error);
  },
);

export default api;
