import styled from 'styled-components';

export const TitleStyle = styled.h2`
   background: transparent;
   color: #252527;
   font-weight: 200;
   font-size: 2.4em;
   max-width: 240px;
   line-height: 1;
   margin: 0 0 1em;
   position: relative;

   &:before {
    content: "";
    width: 40px;
    height: 3px;
    position: absolute;
    background-color: #fcc06d;
    bottom: -13px;
  }
  @media(max-width:919px){
    margin: .5em 0 1.5em
    font-size: 2em;
  }
`;
export const SubTitleStyle = styled.h2`
   background: transparent;
   color: #252527;
   font-weight: 200;
   font-size: 1.5em;
   line-height: 1;
   margin: 0em 0 1.5em;
   position: relative;

   &:before {
    content: "";
    width: 40px;
    height: 3px;
    position: absolute;
    background-color: #fcc06d;
    bottom: -13px;
  }
  @media(max-width:919px){
    margin: .5em 0 1.5em
    font-size: 2em;
  }
`;
export const InternalTitleStyle = styled.h2`
  background: transparent;
  color: #252527;
  font-weight: 600;
  font-size: 2.4em;
  line-height: 1;
  margin-bottom: 1em;
  position: relative;
  @media (max-width: 919px) {
    margin: 0.5em 0 1.5em;
    font-size: 2em;
  }
`;
