import React, { useState, useEffect } from 'react';
import api from './services/api';
import { Spinner } from 'reactstrap';
import ReactNotification from 'react-notifications-component';
import Routes from './routes';
import userPlaceholder from './assets/images/user_placeholder.png';
import CheckoutContext from './store/Checkout';
import { getToken } from './services/auth';

function App() {

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    console.log(getToken());
    api
      .get('/users/me')
      .then((response) => {
        localStorage.setItem(
          `user-endpoint`,
          btoa(`/users/${response.data.id}`),
        );
        localStorage.setItem(`user-id`, btoa(response.data.id));
        localStorage.setItem(`user-name`, btoa(response.data.name));
        localStorage.setItem(`user-email`, btoa(response.data.email));
        localStorage.setItem(`user-roles`, btoa(response.data.roles));
        localStorage.setItem(`user-data`, JSON.stringify(response.data));
        localStorage.setItem(
          `user-photo`,
          btoa(
            response.data.profile &&
              response.data.profile.avatar &&
              response.data.profile.avatar.file
              ? response.data.profile.avatar.file.urlPublica
              : userPlaceholder,
          ),
        );
        setLoading(true);
      })
      .catch((error) => {
        console.error('Erro ao armazenar dados de usuario no local', error);
      });
  }, []);

  return (
    <CheckoutContext>
      {!loading ? (
        <div className="mt-30 mb-30 text-center">
          <Spinner />
        </div>
      ) : (
        <>
          <ReactNotification />
          <Routes />
        </>
      )}
    </CheckoutContext>
  );
}

export default App;
