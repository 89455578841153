import styled from 'styled-components';

export const CourseBox = styled.div`
  display: flex;
  flex-direction: column;
  & .trilha {
    height: 30px;
    display: block;
    position: relative;
    &:before {
      content: '';
      width: 20px;
      height: 10px;
      display: block;
      background-color: #038e83;
      left: 50%;
      position: absolute;
      transform: translateX(-50%);
      /* margin:  0 5px; */
      z-index: 2;
      border: 5px solid #ffffff;
      border-bottom: 0;
      border-top: 0;
    }

    &:after {
      content: '';
      top: 5px;
      width: 100%;
      height: 1px;
      display: block;
      background-color: #038e83;
      left: 50%;
      position: absolute;
      /* transform: translateY(-50%); */
      z-index: 1;
    }
  }
`;
export const FilterStyle = styled.div`
  input,
  select {
    border: none;
    border-bottom: 1px solid #c7c7c7;
    color: #000000;
    font-size: 13px;
    outline: none;
    padding: 7px 5px;
    font-weight: 300;
  }
  .search-filter {
    position: relative;
    i {
      position: absolute;
      right: 20px;
      top: 0;
      height: 100%;
      display: flex;
      align-items: center;
      font-weight: 400;
    }
  }
`;

export const DivSlider = styled.div`
  .CourseBlock {
    & .slick-next:before,
    .slick-prev:before {
      color: #038e83;
    }
  }
`;
