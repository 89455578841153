import styled from 'styled-components';

export const StarsStyle = styled.div`
  font-size: 0.8em;
  display: flex;
  align-items: center;
  & p {
    font-style: italic;
    display: flex;
    align-items: center;
    margin: 0 0px 0 3px;
  }
`;
export const Professor = styled.div`
  display: flex;
`;
export const ImgProfessor = styled.div`
  border-radius: 50%;
  display: block;
  width: 30px;
  height: 30px;
  overflow: hidden;
  position: relative;
  margin-right: 5px;
`;
export const UserImg = styled.img`
  position: absolute;
  left: 0;
  height: 100%;
  object-fit: cover;
`;
export const NomeProfessor = styled.p`
  margin: 0;
  font-size: 13px;
  text-align: left;
  color: #000;
`;
export const DataCurso = styled.p`
  margin: 0;
  font-size: 13px;
  text-align: left;
  color: #000;
`;
export const Icon = styled.i`
  margin-right: 3px;
`;
export const Views = styled.p`
  margin: 0 0 5px;
  font-size: 0.8em;
  display: flex;
  align-items: center;
  & i {
    position: relative;
    top: 1px;
  }
`;
export const ActionsStyle = styled.div`
  border: 1px solid #e5e5e5;
  border-left: 0;
  border-right: 0;
  b {
    font-weight: bold;
  }
`;
export const AvalieStyle = styled.div`
  .far,
  & .fas {
    color: #fcc06d;
    font-size: 1.3em;
    cursor: pointer;
  }

  .far:hover {
    font-weight: 900;
  }
  & i {
    position: relative;
    top: 1px;
  }
`;
export const FavortitosStyle = styled.div`
  cursor: pointer;
  transition: ease all 300ms;

  .far,
  & .fas {
    color: #fcc06d;
    font-size: 1.3em;
  }
  &:hover {
    text-decoration: none;

    i {
      zoom: 1.2;
      font-weight: bold;
    }
  }
`;
export const ProgressCurso = styled.div`
  width: 250px;
`;
export const RowAula = styled.div`
  background-color: #e1e1e1;
  justify-content: space-between;
  margin-bottom: 10px;
  transition: ease all 400ms;
  &.expand {
    background-color: #038e83;
    .progressTitle {
      color: #fff;
    }
    .title-aula {
      color: #fff;
    }
    .title-type {
      color: #fff;
    }
  }
`;
export const NumberAula = styled.span`
  background-color: #038e83;
  color: #fff;
  padding: 10px;
`;
export const TitleAula = styled.span`
  flex-grow: 1;
  padding-left: 30px;
  display: flex;
  align-items: center;
  border-right: 1px solid #fcc06d;
  & h4 {
    margin: 0;
  }
`;
export const ProgressAula = styled.span`
  padding: 0 15px;
  display: flex;
  align-items: center;
`;
export const LabelButtom = styled.label`
  text-align: left;
  background-color: #fcc06d;
  color: #fff;
  font-size: 22px;
  cursor: pointer;
  padding: 10px 0 !important;
  @media (min-width: 991px) {
    max-width: 5% !important;
  }
`;
export const BlockSection = styled.div`
  .input-expand:checked + .expand-content {
    height: 100%;
  }

  .expand-content {
    height: 0;
    overflow: hidden;
    h5 {
      font-weight: normal;
    }
  }
`;

export const ActionsBar = styled.div`
  border-bottom: 1px solid #e5e5e5;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const LikeBar = styled.div`
  padding: 10px 0;
  & button {
    background-color: transparent;
    border: none;
    font-size: 25px;
    &:hover {
      zoom: 1.2;
    }
  }
  & i {
    color: #fcc06d;
  }
`;
export const BoxNextVideos = styled.div`
  max-height: 366px;
  overflow-y: auto;
  padding-right: 45px;
  overflow-x: hidden;
  /* width */
  &::-webkit-scrollbar {
    width: 7px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 10px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #fcc06d;
    border-radius: 10px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #fcc06d;
  }
`;
export const DescriptionSection = styled.div`
  font-family: 'HelveticaLTStd';
  font-weight: 300;
  .anexos {
    margin: 30px 0 0 0;
    a:hover {
      color: #2a2a2a;
      opacity: 0.85;
    }
  }
  a {
    color: #2a2a2a;
    white-space: nowrap;
    width: 18em;
    overflow: hidden;
    text-overflow: clip;
    text-decoration: underline;
    font-weight: 300;
    display: flex;
    font-size: 0.9em;
    align-items: center;
    margin: 15px 0;
    font-family: 'HelveticaLTStd';
  }
  .description {
    padding-bottom: 30px;
    padding-right: 20px;
    border-bottom: 1px solid #e5e5e5;
  }
  & .list-questions {
    max-height: 600px;
    overflow-y: auto;
    overflow-x: hidden;
    margin-bottom: 30px;
    /* width */
    &::-webkit-scrollbar {
      width: 7px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: transparent;
      border: 1px solid #fcc06d;
      border-radius: 10px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #fcc06d;
      border-radius: 10px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #fcc06d;
    }
    & input[type='checkbox']:checked + .sub-resp {
      height: 100%;
    }
    & .sub-resp {
      height: 0;
      overflow: hidden;
      display: block;
      width: 100%;
      &.active {
        height: 100%;
        & .newQuestion {
          display: flex !important;
        }
      }
      & .newQuestion {
        display: none !important;
      }
    }
  }
`;
export const FeaturedBoxVideo = styled.div`
  height: 0;
  display: flex;
  align-items: center;
  padding: 35px 0;
  position: relative;
  cursor: pointer;
  width: 100%;

  & video {
    position: absolute;
  }
  & .embed-responsive {
    position: absolute;
    height: 100%;
    top: 0;
  }
`;

export const SingleVideoThumb = styled.div`
  width: 99px;
  height: 68px;
  position: relative;
  background-image: url(${({url}) => url});
  background-position: 50% 50%;
  background-size: cover;
  cursor: pointer;

  &:before {
    font-size: 15px;
    content: '\f144';
    width: 100%;
    height: 100%;
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.53);
    color: #fff;
    font-family: 'Font Awesome 5 Pro';
    z-index: 100;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    cursor: pointer;
  }
`;

export const ProvaStyle = styled.div`
  background-color: transparent;
  border: 2px solid #e1e1e1;
  color: #212529;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18px 30px;
  margin: 40px 0 100px;
  & h3 {
    margin: 0 0px 0 0;
  }
  & a {
    background-color: #038e83;
    padding: 10px 60px;
    font-weight: bold;
    color: #fff;
    &:hover {
      opacity: 0.85;
      text-decoration: none;
    }
  }
  .disabled {
    background-color: #038e83;
    opacity: 0.85;
  }
`;
export const VideoStyle = styled.video`
  width: 100%;

  display: block;
  object-fit: cover;
  top: 0;
`;
export const ContentStyle = styled.div`
  background-color: #eeeeee;
  border: 1px solid #fcc06d;
  border-top: 0;
  display: flex;
  #featuredPDFView {
    width: 100%;
    min-height: 400px;
  }
`;
export const TitleVideo = styled.h5`
  // background-color:#fff;
`;
export const ModalExamSytled = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.82);
  width: 100%;
  height: 100vh;
  z-index: 9999;
  display: flex;
  align-items: center;
  .exam-style {
    background-color: #fff;
    overflow: auto;
    height: 95vh;
    border-radius: 8px;
    padding: 40px 40px !important;
    .slick-list {
      margin-bottom: 30px;
    }
    & button#next-question {
      right: 0;
      position: absolute;
    }

    & button#prev-question {
      bottom: -74px;
      position: absolute;
    }
    & button#submitAnswers {
      width: 100%;
      max-width: 100%;
      top: 60px;
      background-color: #038e83;
    }
    & button.close-button {
      border: none;
    }
    & .slick-disabled {
      opacity: 0;
      visibility: hidden;
    }
  }
`;
export const NextVideo = styled.div`
  margin: 5px 0 0;
  padding: 0 5px 5px 0;
  width: 100%;
  display: flex;
  align-items: flex-end;
  border-bottom: 1px solid
    ${({isVideoWatched}) => (isVideoWatched ? '#00d323' : 'transparent')};
  background-color: ${({isActualVideo}) =>
    isActualVideo ? '#252527c2' : 'transparent'};
  color: ${({isActualVideo}) => (isActualVideo ? '#fff' : 'default')};

  cursor: pointer;
  transition: ease all 300ms;
  &:hover {
    background-color: #252527c2;
    color: #fff;
  }

  .video-line {
    flex: 1;
    & video {
      height: 100%;
    }
  }
`;

export const QuestionStyle = styled.div`
  .preview-anotation {
    text-align: left;
    max-width: 50ch;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 0;
  }
  & .headerQuestion {
    display: flex;
    justify-content: space-between;
    position: relative;
    color: #6f6f6f;
    height: 30px;
    span {
      font-size: 12px;
    }
    i {
      font-size: 1.2em;
    }

    & .anotationsActions {
      display: flex;
      flex-direction: row;
      & .button {
        display: flex;
        align-items: baseline;
        border: 1px solid transparent;
        font-weight: bold;
        color: #fff;
        border-radius: 3px;
        transition: ease all 100ms;
        i {
          margin-left: 0.3em;
        }
        :hover {
          opacity: 0.8;
        }
      }

      & .buttonConfirm {
        background-color: #fcc06d;
      }

      & .buttonCancel {
        background-color: #038e83;
      }
    }

    @media (max-width: 767px) {
      flex-direction: column;
      justify-content: center;
      height: 100%;

      & .div-expand {
        display: contents;
        max-width: 100%;
      }
      & .anotationsActions {
        justify-content: space-around;
      }
      .buttonExpand {
        position: absolute;
        top: 0px;
        right: 0px;
      }
    }
  }

  & .questionContent {
    font-size: 14px;
    display: flex;
    flex: 1;
    align-items: center;
    & p {
      -webkit-hyphens: auto;
      -moz-hyphens: auto;
      -ms-hyphens: auto;
      hyphens: auto;
    }
    & input {
      background-color: transparent;
      white-space: wrap;
      border: none !important;
      width: 100%;
    }
    & textarea {
      border: none;
      width: 100%;
      background-color: transparent;
      resize: max-content;
      ::placeholder {
        color: #777777;
      }
    }
    .buttonSend {
      height: 40px;
      padding: 0 30px;
      margin-left: 10px;
      background-color: #038e83;
      border: 1px solid transparent;
      font-weight: bold;
      color: #fff;
      border-radius: 3px;
      transition: ease all 300ms;
    }

    @media (max-width: 991px) {
      flex-direction: column;
      & .buttonSend {
        width: 100%;
        margin-top: 10px;
      }
    }

    button.buttonSend:hover {
      border: 1px solid #038e83;
      background-color: transparent;
      color: #038e83;
    }
  }
  & .content-question {
    background-color: #fff;
    padding: 20px;
    /*&:before {
      content: '';
      display: block;
      border-right: 20px solid #eeeeee;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      position: absolute;
      left: 10px;
    }*/
  }
`;
export const UserPhotoStyle = styled.div`
  padding-top: 10px;
  & .user-img {
    border-radius: 50%;
    position: relative;
    height: 100%;
    width: 42px;
    display: block;
    height: 42px;
    overflow: hidden;
    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`;
export const QuestionActions = styled.div`
  background-color: #fff;
  label,
  button {
    cursor: pointer;
    background-color: transparent;
    border: none;
    font-weight: bold;
  }
  .btn-resp {
    color: #8591a5;
    margin-right: 30px;
  }
  & .fa-sort-down:before {
    position: relative;
    top: -3px;
    color: #8591a5;
  }
`;

export const ViewedCheckbox = styled.div`
  color: #fcc06d;
  cursor: pointer;
  transition: ease all 300ms;
  &:hover {
    text-decoration: none;
    i {
      zoom: 1.2;
    }
  }
`;

export const WatchedIcon = styled.i`
  color: #00d323;
`;
