import styled from 'styled-components';

export const ReviewItem = styled.div`
  display: flex;
  flex-direction: column;
`;
export const TextContent = styled.div`
  position: absolute !important;
  margin: 15px 0px;
`;
export const DadosReview = styled.div`
  padding: 15px;
  background-color: #f3f3f3;
`;
export const Photo = styled.div`
  display: block;
  width: 70px;
  height: 70px;
  overflow: hidden;
  position: relative;
  margin-right: 5px;
`;
export const UserImg = styled.img`
  position: absolute;
  left: 0;
  object-fit: cover;
`;
export const Autor = styled.p`
  margin: 0;
  font-size: 14px;
  text-align: left;
  color: #000;
  font-weight: bold;
  margin-top: 10px;
`;
export const TitleReview = styled.h4`
  margin: 0;
  font-size: 16px;
  text-align: left;
  color: #000;
  font-weight: bold;
  margin-bottom: 25px;
`;
export const DescriptionReview = styled.p`
  font-weight: 100;
  text-align: left;
  font-size: 14px;
  font-style: italic;
  margin-bottom: 25px;
`;
export const BlockSection = styled.div`
  margin: 40px 0;
`;
export const TitleReviews = styled.span`
  background-color: #fcc06d;
  color: #fff;
  padding: 25px 15px;
  margin: 0px 0;
`;
export const BoxReviews = styled.div`
  margin: 0px 0;
  background-color: #038e83;
  color: #fff;
  padding: 15px;
  flex: unset !important;
  & h3 {
    font-size: 2em;
    font-weight: 100;
  }
  & p {
    font-weight: 300;
  }
  & a {
    color: #fff;
    font-size: 25px;
  }
  & a:hover {
    color: #fff;
    opacity: 0.65;
  }
`;
