import styled from 'styled-components';

export const BoxSobre = styled.div`
  margin: 0px 0;
  background-color: #ffffff;
  color: #000;
  padding: 15px;
  border: 1px solid #fcc06d;
  width: 100%;
  font-family: 'HelveticaLTStd', sans-serif;
  & h3 {
    font-size: 1.5em;
    font-weight: 100;
  }
  & p {
    font-weight: 300;
  }
  & a {
    color: #fff;
    font-size: 25px;
  }
  & i {
    font-size: 1.3em;
    color: #fcc06d;
  }
`;
export const TitleSobre = styled.span`
  background-color: #fcc06d;
  color: #fff;
  padding: 5px 15px;
  margin: 0px 0;
  width: 100%;
  font-size: 2.5em;
  font-family: 'HelveticaLTStd', sans-serif;
  font-weight: 100;
`;
export const TextContent = styled.div`
  margin: 15px 0px;
  background: none;
  border: none;
  text-align: left;
  cursor: pointer;
  .BoxSobre {
    transition: ease all 300ms;
  }

  &:hover .BoxSobre {
    background-color: #038e83;
    border-color: #038e83;
    color: #fff;
    i {
      color: #fff;
    }
  }
  @media (max-width: 991px) {
    position: relative !important;
  }
`;
export const Cursos = styled.div`
  .btn-cert {
    font-size: 15px;
    :hover {
      background-color: #fcc06d;
      opacity: 1;
    }
  }
  .respCursos {
    max-height: 460px;
    overflow: auto;
    /* width */
    &::-webkit-scrollbar {
      width: 7px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: 10px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #fcc06d;
      border-radius: 10px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #fcc06d;
    }
  }
`;
export const RespCursos = styled.div`
  max-height: 460px;
  overflow: auto;
  /* width */
  &::-webkit-scrollbar {
    width: 7px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #fcc06d;
    border-radius: 10px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #fcc06d;
  }
`;

export const ContainerMeusCursos = styled.div`
  width: 100%;
  height: 100%;

  & .title {
    max-width: 100px;
  }
`;
