import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import stripTags from 'striptags';
import format from '../../../../utils/format';
import ContainerLoading from '../../../Theme/Loading'

import {
  CursoItem,
  InfoCurso,
  DadosCurso,
  DataCurso,
  TitleCurso,
  DescriptionCurso,
  CursoLink,
} from './style';

const CourseItem = ({ course, ...props }) => {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setLoading(true);
    }, 1000)
  })

  return (
    loading === false ? <ContainerLoading /> :
      <CursoItem to={course.url} >
        <div className="container-Curso ">
          <InfoCurso className="info-curso container">
            <div className="box-dia"><h2 className="mr-2">{format.data(course.data, 'numberDay')}</h2></div>
            <div>
              <DataCurso>{format.data(course.data, 'stringMonth')}</DataCurso>
              <p className="m-0">{format.data(course.data, 'stringDay')} | {format.data(course.data, 'hour').split(':')[0]}h{format.data(course.data, 'hour').split(':')[1]}</p>
            </div>

          </InfoCurso>
          <DadosCurso>
            <TitleCurso>{course.titulo}</TitleCurso>
            <DescriptionCurso dangerouslySetInnerHTML={{ __html: course.description }} />
            <CursoLink to={course.url} ><i className="far fa-arrow-alt-circle-right"></i></CursoLink>
          </DadosCurso>
        </div>
      </CursoItem>
  )
};

CourseItem.propTypes = {
  course: PropTypes.object.isRequired
}

export default CourseItem;
