import api from '../../../api';

export const getWatcheds = (content_iri) => {
  return api.get(`/general/watcheds`);
};

export const getWatched = (content_iri) => {
  return api.get(`/general/watcheds?content_iri=${content_iri}`);
};

export const updateWatcheds = (body) => {
  return api.post('/general/watcheds', body);
};
