import React from 'react';
import Base from '../../components/Theme/base';
import Title from '../../components/Theme/Title';
import { VideoStyle } from './styles';
import {  Container } from 'reactstrap';




const Live = () =>{
 
  
  return(
    <Base>
      <Title value="Ao Vivo"/>
      
      <Container className="p-0 mb-5 pb-5">
        <VideoStyle controls>
        </VideoStyle>
      </Container> 
    </Base>
  )
    
};
export default Live;
