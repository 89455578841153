import React, { useEffect, useState } from 'react';
import Base from '../../components/Theme/base';
import Title from '../../components/Theme/Title';
import { fetchRankingList } from '../../services/endpoints';
import RankItem from '../../components/Ranking/RankItem';
import currenteUser from '../../utils/getCurrentUser';
import { Spinner, Container, Row, Col } from 'reactstrap';

const Ranking = () => {
    const [ranking, setRanking] = useState([]);
    const [loading, setloading] = useState(true);

    useEffect(() => {
        fetchRankingList()
            .then((response) => {
                let rankList = [];

                response.data.map((rank) => {
                    return rankList.push({
                        name:
                            rank.user && rank.user.name ? rank.user.name : 'Não Informado',
                        position: rank.position,
                        avatar:
                            rank.user &&
                                rank.user.profile &&
                                rank.user.profile.avatar &&
                                rank.user.profile.avatar.file
                                ? rank.user.profile.avatar.file.urlPublica
                                : null,
                        authenticated: rank.user_iri === currenteUser() ? true : false,
                        score: rank.amount,
                    });
                });

                setRanking(rankList);
                setloading(false);
            })
            .catch((error) => {
                setloading(false);
                console.error('ERROR AO CARREGAR RANKING LIST:', error);
            });
    }, []);

    return (
        <Base>
            <Container className="mb-5">
                <Title value="Ranking" />
                {loading ? (
                    <div className="init-loading text-center">
                        <Spinner />
                    </div>
                ) : ranking.length > 0 ? (
                    ranking.map((rank) => (
                        <RankItem
                            key={rank.position}
                            position={rank.position}
                            avatar={rank.avatar}
                            name={rank.name}
                            score={rank.score}
                            authenticated={rank.authenticated}
                        />
                    ))
                ) : (
                            <h1>Não disponível</h1>
                        )}
            </Container>
        </Base>
    );
};

export default Ranking;
