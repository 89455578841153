import api from './api';
import getCurrentUser from '../utils/getCurrentUser';

// endpoints /social/posts
export const loadPosts = (_params = {}) =>
  api.get('/social/posts', {
    params: {
      'order[created_at]': 'desc',
      ..._params,
    },
  });

// endpoints /general/users
// export const loadUsers = () => api.get('/users');

export const updateUsers = (userId, values) => api.put(userId, values);

// endpoints /general/posts
export const loadStates = () =>
  api.get('/general/field_domain_values?field.title=UF');

// change password
export const changePasswordUser = (params) => {
  return api.post(`${getCurrentUser()}/change_password`, params);
};

// endpoints /general/fields
export const loadFields = () => api.get('/general/fields');

// endpoints pages
export const getPage = (slug) => api.get(`/cms/pages?slug=${slug}`);

export const getEndpoints = (endpoints, params = {}) =>
  api.get(endpoints.replace('/v1/', '/'), {
    params: {
      ...params,
    },
  });

export const loadFieldsAsync = async () => api.get('/general/fields');

// endpoints /general/field_values
export const loadFieldValues = (userIri) =>
  api.get(`/general/field_values?user_iri=${userIri}`);

export const loadFieldValuesAsync = async (userIri) =>
  api.get(`/general/field_values?user_iri=${userIri}`);

// export const updateFieldValues = (data = []) => api.post('/general/fieldValuesBatch', data);

// endpoints /cms/contact_messages
export const sendContactMessages = (values) =>
  api.post('/cms/contact_messages', {
    content: values.message,
    user_iri: `/users/me`,
    ip: '0.0.0.0',
    user_agent: navigator.userAgent,
  });

// endpoints gamification: Ranking
export const fetchRankingList = () => api.get('/gamification/ranking');

// endpoints gamification: Ranking of user
export const fetchRanking = (id) => api.get(`/gamification/ranking/user/${id}`);

// endpoints gamification: Premios
export const fetchRewards = () => api.get('/gamification/rewards');

// endpoints gamification: Premios
export const fetchRedeem = (params) =>
  api.get('/gamification/redeem', {
    params: {
      ...params,
    },
  });

// endpoints gamification: get premio
export const getReward = (body) =>
  api.post('/gamification/redeem/exchange', body);

export const getWatched = (content_iri) => {
  return api.get(`/general/watcheds?content_iri=${content_iri}`);
};

export const getSettings = () => {
  return api.get('/settings');
};

export const getCourse = (id) => {
  return api.get(`/lms/courses/${id}`);
};

export const getForumUrl = async () => {
  const settings = await getSettings();
  const flarumId = 'flarum_url';

  const sambaSettings = settings.data.filter(
    (setting) => setting.id === flarumId,
  );

  return sambaSettings[0];
};

export const getFlarumCookie = async () => {
  return api.get('/flarum_sso/flarum/login');
};

export const getExamApproval = (userId, contentId) =>
  api.get(
    `/lms/exam_applications?user_iri=/users/${userId}&wherein${contentId}&order[grade_obtained]=desc`,
  );
