import styled from 'styled-components';

export const Item = styled.div`
  min-width: 400px;
  display: flex;
  align-items: center;
  border: 1px solid #dadada;
  box-shadow: none;
  transition: box-shadow 400ms ease, transform 400ms ease;
  padding: 5px 10px;
  max-width: 90vw;
  margin: 0 auto 10px;

  div {
    display: flex;
    align-items: center;
  }

  img {
    width: 30px;
    height: 30px;
    object-fit: cover;
    border-radius: 100%;
  }

  .position {
    font-size: 23px;
    color: #949494;
    justify-content: center;
    align-items: center;
  }
  .name {
    margin-left: 10px;
    color: #616161;
    font-size: 14px;
  }

  .score {
    color: #616161;
    font-size: 14px;
    font-weight: 600;
  }

  .medal {
    justify-content: flex-end;
    i {
      color: #949494;
      font-size: 20px;
    }

    @media screen and (max-width: 768px) {
      display: none !important;
    }
  }
  .authenticated {
    border: 1.2px solid #038e83 !important;
    font-weight: 600;
  }
`;
