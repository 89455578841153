import React, {useState, useContext, useEffect} from 'react';
import {QuestionStyle, UserPhotoStyle} from './styles';
import api from '../../../services/api';
import {HandleRefreshContext} from './handleRefreshContext';

const EditQuestion = ({pergunta, setEdit, ...props}) => {
  const [description, setDescription] = useState(pergunta.content || '');
  const [loading, setLoading] = useState(false);
  const {handleRefreshComments} = useContext(HandleRefreshContext);

  function sendQuestion() {
    setLoading(true);
    api
      .put(pergunta._id, {
        question: description,
      })
      .then(() => {
        handleRefreshComments();
        setTimeout(() => {
          setLoading(false);
          setEdit(false);
        }, 1000);
      });
  }

  return (
    <>
      <QuestionStyle className="row d-flex justify-content-between my-3 newQuestion">
        {/*
          <UserPhotoStyle className="col-2 col-md-1 pr-0">
            <div className="user-img">
              <img src={user.photo} alt={user.name} />
            </div>
          </UserPhotoStyle>
            */}
        <div className="col-12">
          {/* <Col lg="12" className="headerQuestion p-0 mb-3 d-flex justify-content-lg-between">
              <h4>{.user.name}</h4>
              <span>{pergunta.data} | {pergunta.hora}</span>
          </Col> */}
          <div className="questionContent">
            <textarea
              className="p-0"
              type="text"
              data-autoresize
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Edite a sua anotação..."
              value={description}
            />
            <button
              onClick={sendQuestion}
              disabled={loading}
              className="buttonSend">
              {!loading ? 'Salvar' : 'Salvando...'}
            </button>
          </div>
        </div>
      </QuestionStyle>
    </>
  );
};

export default EditQuestion;
