import React from 'react';
import PropTypes from 'prop-types';


const FilePDF = ({url}) => {
  return(
    
        <iframe id="featuredPDFView" src={url}/>
  )
};

FilePDF.propTypes ={
  url: PropTypes.string.isRequired
}
export default FilePDF;