import React, {useState, useEffect} from 'react';
import Script from 'react-load-script';
import api from '../../services/api';
import {getClosest, findMediaPosition, getMedias} from '../../utils/samba';
import {SambaContainer, GoBackButton} from './styles';

const SambaPlaylist = ({
  playlist,
  currentContentPosition,
  onPlaylistClick,
  setRefreshProgress,
}) => {
  const [playerKey, setPlayerKey] = useState();
  const [loadScript, setLoadScript] = useState(false);
  const [numMediaObj, setNumMediaObjt] = useState(currentContentPosition);
  const GOBACKTIME = 10; // in seconds
  const progress = true;
  const player = 'player';
  let sambaPlayer = false;
  let setProgress = false;

  useEffect(() => {
    api.get('/settings/sambatech_player_key').then((response) => {
      setPlayerKey(response.data.value);
    });
  }, []);

  useEffect(() => {
    setNumMediaObjt(currentContentPosition);
  }, [currentContentPosition]);

  const goBack = () => {
    sambaPlayer.getStatus((media) => {
      sambaPlayer.seek(media.status.time - GOBACKTIME);
    });
  };

  window.onClick = (mediaId, evt) => {
    const isSambaTrigger = getClosest(evt.target, '.samba-playlist-trigger');
    if (!isSambaTrigger) {
      return;
    }

    const mediaid = isSambaTrigger.dataset.mediaid;

    if (evt.isTrusted && window.sambaPlayer.sequence) {
      setNumMediaObjt(
        findMediaPosition(window.playlistObj.medias, 'id', mediaid) + 1,
      );
    }
    document
      .getElementsByClassName('samba-playlist-trigger active')[0]
      .classList.toggle('active');
    isSambaTrigger.classList.toggle('active');
  };

  window.onLoad = () => {
    if (window.sambaPlayer.autoplay) {
      if (numMediaObj < window.playlistObj.medias.length) {
        setNumMediaObjt(numMediaObj + 1);
        window.sambaPlayer.play();
      }
    }
  };

  const eventListener = (player) => {
    switch (player.event) {
      case 'onLoad':
        onPlaylistClick(player);
        break;
      case 'onStart':
        api.post(`/general/watcheds`, {
          user_iri: atob(localStorage.getItem(`user-endpoint`)),
          progress: 0,
          content_iri: `/lms/lesson_contents/${playlist[currentContentPosition].lessonContent}`,
          watched: false,
        });
        break;
      case 'onListen':
        if (
          !setProgress &&
          player.eventParam > 1 &&
          parseInt(player.eventParam) % 15 === 0
        ) {
          setProgress = true;
          api.post(`/general/watcheds`, {
            user_iri: atob(localStorage.getItem(`user-endpoint`)),
            progress: (player.eventParam * 100) / player.duration,
            content_iri: `/lms/lesson_contents/${playlist[currentContentPosition].lessonContent}`,
          });
          setTimeout(() => {
            setProgress = false;
          }, 1000);
        }
        break;
      case 'onPause':
        break;
      case 'onResume':
        break;
      case 'onSeek':
        break;
      case 'onCuepoint':
        break;
      case 'onMediaView':
        break;
      case 'onProgress':
        break;
      case 'onFinish':
        api
          .post(`/general/watcheds`, {
            user_iri: atob(localStorage.getItem(`user-endpoint`)),
            progress: 100,
            content_iri: `/lms/lesson_contents/${playlist[currentContentPosition].lessonContent}`,
            watched: true,
          })
          .then((res) => {
            setRefreshProgress(new Date().toLocaleString());
          });
        break;
      case 'onError':
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (loadScript && !!playlist.length) {
      window.sambaPlayer = new window.SambaPlayer('player', {
        playlist:
          window.playlistObj.medias && !!window.playlistObj.medias.length
            ? window.playlistObj
            : null,
        playerParams: {
          volume: 100,
          enableShare: false,
          autoStart: false,
          startOutput: '480p',
          html5: true,
        },
        events: {
          '*': eventListener,
          onLoad: 'onLoad',
        },
      });
    }
  }, [loadScript]);

  const initData = async () => {
    try {
      const medias = await getMedias(playlist, currentContentPosition);
      window.playlistObj = {
        titles: medias.titles,
        ph: playerKey,
        medias: medias.contents,
        onClick: 'onClick',
        loop: true,
        timeout: 5,
        autoplay: false,
        sequence: true,
      };
      setLoadScript(true);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    !!playerKey && (
      <SambaContainer>
        <Script
          url="https://player.sambatech.com.br/v3/samba.player.api.js"
          onLoad={initData}
        />

        <div className="embed-responsive embed-responsive-16by9 ">
          <div className="embed-responsive-item" id="player" />
        </div>
        {sambaPlayer && (
          <GoBackButton onClick={() => goBack()}>
            <i className="far fa-undo " />
            <span>Voltar {GOBACKTIME} segundos</span>
          </GoBackButton>
        )}
      </SambaContainer>
    )
  );
};

export default SambaPlaylist;
