import React from 'react';
import {TitleStyle} from './styles';
import PropTypes from 'prop-types';
import {Container} from 'reactstrap';

const Title = (props) => {
  return (
    <Container className="p-0 mt-4">
      <TitleStyle className="title">{props.value}</TitleStyle>
    </Container>
  );
};
Title.prototype = {
  value: PropTypes.string.isRequired,
};

export default Title;
