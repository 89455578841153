import React, { useEffect, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import BannerImg from './bannerImg';
import PropTypes from 'prop-types';

const BannerCarousel = (banners) => {
    const [bannerList] = useState(banners.content.banners.length > 0 ? banners.content.banners : []);
    const [currentSlide, setCurrentSlide] = useState(0);
    const [showNav] = useState(banners.content.banners.length > 1 ? true : false)
    const [widthScreen, setWidthScreen] = useState(window.innerWidth)

    useEffect(() => {
        setWidthScreen(window.innerWidth)
    },[window.innerWidth])
    
    const handlePrev = () => {
        setCurrentSlide(prev => prev ? prev - 1 : 0);
    }

    const handleNext = () => {
        setCurrentSlide(prev => prev < banners.content.banners.length - 1 ? prev + 1 : 0)
    }

    const updateCurrentSlide = (index) => {
        if (currentSlide !== index) {
            setCurrentSlide(index)
        }
    }

    return (
        <>
            <Carousel
                classname="Banner-Home"
                showIndicators={true}
                showStatus={false}
                showThumbs={false}
                selectedItem={currentSlide}
                onChange={updateCurrentSlide}
                autoPlay={bannerList.length > 1 ? true : false}
                infiniteLoop={bannerList.length > 1 ? true : false}
            >
                {bannerList.length > 0 &&
                    bannerList.map((banner, i) => (
                        <BannerImg
                            key={`banner-img-${i}`}
                            image={widthScreen <= 576 && banner && banner.file_mobile  ? banner && banner.file_mobile && banner.file_mobile.urlPublica : banner.file.urlPublica}
                            title={banner.title}
                            description={banner.description}
                            textButton={banner.button_text || ""}
                            url={banner.url}
                        />
                    ))}
            </Carousel>
            <div className={`container slider-controls ${!showNav ? 'd-none' : ''}`}>
                <button onClick={e => handlePrev()}><i className="far fa-arrow-alt-circle-left" /></button>
                <button onClick={e => handleNext()}><i className="far fa-arrow-alt-circle-right" /></button>
            </div>
        </>
    );
};

BannerCarousel.propTypes = {
    banners: PropTypes.array,
};
export default BannerCarousel;
