import { getPage, getEndpoints } from '../services/endpoints';
import  passToPascalCase  from './toPascalCase';

const loadPage = async (slug) => {
  try{
    const page_data = await getPage(slug);
    const page_content = await page_data.data[0];
    
    const response = await page_content;
    const endPoints = [];
    const blocks = [];
    if(response && response.hasOwnProperty('pages_blocks')){
      response.pages_blocks.forEach(block => {
        endPoints.push(getEndpoints(block.block.endpoint))
      });
    
      const resultEndPoints = await Promise.all(endPoints);
      
      response.pages_blocks.forEach((block, index) => {      
        const blockContent = resultEndPoints[index].data;
        const componentName = passToPascalCase(block.block.name);
        const { container } = block;
        const content = {
          block:block.block,
          container,
          componentName,
          content: blockContent
        };

        blocks.push(content);
      });
    }
    return {blocks, page:response &&  response.hasOwnProperty('pages_blocks') ? response.pages_blocks : [] , content: response};
  }
  catch (err) {
    console.error('loadPage', err);

    return {blocks: [], page: {}};
  }
};

export {
  loadPage
};
