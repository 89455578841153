import React from 'react';
import PropTypes from 'prop-types';

import { 
  ReviewItem,
  DadosReview,
  Photo,
  TitleReview,
  DescriptionReview, 
  UserImg,
  Autor
 } from './style';

const Review = ({reviewObject, ...props}) => {
  return (   
    <ReviewItem className="ml-3 mr-3 d-flex flex-row">
      <Photo className="col-2 m-0 p-0">
        <UserImg src={reviewObject.user.photo } alt="user"/>
      </Photo>
      <DadosReview className="col-10 p-4">
        <TitleReview>{reviewObject.titulo}</TitleReview>
        <DescriptionReview dangerouslySetInnerHTML={{ __html: reviewObject.description}} />
        <Autor>{reviewObject.user.name|| ""}</Autor>
      </DadosReview>
    </ReviewItem>
  )
};

Review.propTypes = {
  reviewObject: PropTypes.object.isRequired
}

export default Review;
