import styled from 'styled-components';
import {Link} from 'react-router-dom';

export const CursoItem = styled(Link)`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  color: #000;
  & .container-Curso {
    border: 1px solid #252527;
    height: 100%;
  }
  &:hover .info-curso {
    background-color: #038e83;
    & h2,
    & p {
      color: #fff;
    }
  }
  &:hover {
    text-decoration: none;
    color: #000;
    opacity: 1;
  }
`;

export const TitleSobre = styled.span`
  background-color: #038e83;
  color: #fff;
  padding: 25px 15px;
  margin: 0px 0;
`;
export const BoxSobre = styled.div`
  margin: 0px 0;
  background-color: #fcc06d;
  color: #fff;
  padding: 15px;
  & h3 {
    font-size: 2em;
    font-weight: 100;
  }
  & p {
    font-weight: 300;
  }
  & a {
    color: #fff;
    font-size: 25px;
  }
`;
export const InfoCurso = styled.div`
  display: flex;
  padding: 10px 15px;
  justify-content: flex-start;
  align-items: center;
  font-size: 12px;
  border-bottom: 1px solid #fcc06d26;
  margin: 0 0px;
  transition: ease all 400ms;
  & h2 {
    font-size: 5em;
    font-weight: 100;
    margin: 0;
  }
  & p {
    font-size: 1.3em;
  }
  & .box-dia {
    margin-right: 10px;
  }
  &:before {
    height: 1px;
    width: 90%;
    content: '';
    position: absolute;
    bottom: 0;
    background-color: #fcc06d26;
  }
`;
export const TextContent = styled.div`
  margin: 15px 0px;
`;
export const DadosCurso = styled.div`
  padding: 15px;
`;
export const DataCurso = styled.p`
  margin: 0;
  font-size: 2.1em !important;
  text-align: left;
  color: #000;
`;
export const TitleCurso = styled.h4`
  margin: 0;
  font-size: 13px;
  text-align: left;
  color: #000;
  font-style: italic;
  font-weight: 100;
  margin-top: 10px;
`;
export const DescriptionCurso = styled.div`
  font-weight: bold;
  text-align: left;
  font-size: 1.1em;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  margin-bottom: 30px;
  & p {
    margin-bottom: 0rem;
    display: none;
    &:nth-child(1) {
      display: block;
    }
  }
`;
export const CursoLink = styled.span`
  text-align: left;
  color: #fcc06d;
  display: block;
  font-size: 22px;
`;
export const BlockSection = styled.div`
  margin: 40px 0;
`;
export const TitleCursos = styled.span`
  background-color: #038e83;
  color: #fff;
  padding: 25px 15px;
  margin: 60px 0;
`;
export const BoxCursos = styled.div`
  margin: 60px 0;
  background-color: #252527;
  color: #fff;
  padding: 15px;
  & h3 {
    font-size: 2em;
    font-weight: 100;
  }
  & p {
    font-weight: 300;
  }
  & a {
    color: #fff;
    font-size: 25px;
  }
`;
