import React, {useEffect, useState} from 'react';
import XMLParser from 'react-xml-parser';
import moment from 'moment';
import ScormApi from '../../utils/ScormApi';
import ScormApi2004 from '../../utils/ScormApi.2004';
import getCurrentUser from '../../utils/getCurrentUser';
// import {useCourseStatusContext} from '../../../contexts/CourseStatusProvider';
import {ScormHelper} from '../../utils/scormHelper';

import {
  getCourseStatus,
  postCourse,
  updateCourse,
} from '../../services/endpoints/lms/courses';
import {updateWatcheds} from '../../services/endpoints/general/watcheds';

let isUpdated = false;

const Scorm = ({content, contentType, refreshProgress}) => {
  const [scormUrl, setScormUrl] = useState('');
  // const {updateCourseStatus} = useCourseStatusContext();

  const onChange = async (event, value) => {
    const statusIndex =
      value && value['version'] === '2004'
        ? 'cmi.completion_status'
        : 'cmi.core.lesson_status';

    if (event.toLowerCase() === 'initialize' && !isUpdated) {
      contentType === 'course'
        ? updateCourseScormStatus()
        : updateLessonScormStatus();
    }
    if (value && value[statusIndex] == 'completed') {
      const body = {
        course: {
          id: `${content.id}`,
        },
        user_iri: `${getCurrentUser()}`,
        watcheds_lessons: 0,
        course_status: 'EM_ANDAMENTO',
        completion_at: `${moment(new Date()).format('YYYY-MM-DD hh:mm:ss')}`,
        progress: 50,
        current_lesson: 0,
        current_lesson_time: 0,
      };
      let courseStatusResponse = await getCourseStatus(content.id);
      await updateCourse(courseStatusResponse.data[0].id, {
        ...body,
        course_status: 'CONCLUIDO',
        progress: 100,
      });
      let progress = await getCourseStatus(content.id);
      refreshProgress(progress.data)
    }
  };

  const updateCourseScormStatus = async () => {
    try {
      const body = {
        course: {
          id: `${content.id}`,
        },
        user_iri: `${getCurrentUser()}`,
        watcheds_lessons: 0,
        course_status: 'EM_ANDAMENTO',
        completion_at: `${moment(new Date()).format('YYYY-MM-DD hh:mm:ss')}`,
        progress: 50,
        current_lesson: 0,
        current_lesson_time: 0,
      };

      let courseStatusResponse = await getCourseStatus(content.id);

      if (!courseStatusResponse.data) {
        await postCourse(body);
        courseStatusResponse = await getCourseStatus(content.id);
      }

      // await updateCourse(courseStatusResponse.data[0].id, {
      //   ...body,
      //   // course_status: 'CONCLUIDO',
      //   // progress: 100,
      // });

      isUpdated = true;
      // updateCourseStatus();
    } catch (err) {
      console.error(err);
    }
  };

  const updateLessonScormStatus = async () => {
    try {
      const body = {
        user_iri: `/users/${getCurrentUser().id}`,
        progress: 50,
        content_iri: `/lms/lesson_contents/${content.id}`,
        watched: false,
      };

      await updateWatcheds(body);
      await updateWatcheds({
        ...body,
        progress: 100,
        watched: true,
      });

      isUpdated = true;
      // updateCourseStatus();
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (content && content.file_scorm) {
      const temp = content.file_scorm.urlPublica.split('/');

      window.API = new ScormApi(onChange);
      window.API_1484_11 = new ScormApi2004(onChange);

      // fetch(`/scorm/imsmanifest.xml`)
      //   .then((response) => response.text())
      //   .then((text) => {
      //   const xml = new XMLParser().parseFromString(text);
      //   const identifierRef = xml.getElementsByTagName("item")[0].attributes.identifierref;

      //   const resources = xml.getElementsByTagName("resource");
      //   const rootResource = resources.find((resource) => resource.attributes.identifier === identifierRef);

      //   setScormUrl(`/scorm/${rootResource.attributes.href}`)
      // })

      fetch(
        `${window.location.origin}/${process.env.REACT_APP_SCORM_FOLDER}/${temp[
          temp.length - 1
        ].replace('.zip', '')}/imsmanifest.xml`,
      ).then(async (response) => {
        const data = await response.text();
        const params = await ScormHelper.init(data, [
          `${window.location.origin}/${
            process.env.REACT_APP_SCORM_FOLDER
          }/${temp[temp.length - 1].replace('.zip', '')}`,
        ]).readManifest();

        setScormUrl(params.urlFile);
      });
    }
    refreshProgress(content.course_stats);
    // eslint-disable-next-line
  }, [content]);

  if (!scormUrl || !content) {
    return null;
  }

  return (
    <div className="w-container embed-responsive embed-responsive-16by9 card shadow-sm">
      <iframe
        className="embed-responsive-item"
        allowFullScreen
        webkitallowfullscreen="true"
        mozallowfullscreen="true"
        src={scormUrl}
        scrolling="no"
        frameBorder="0"
        title="scorm"
        allow="geolocation; microphone; camera; encrypted-media; midi"
      />
    </div>
  );
};

export default Scorm;
