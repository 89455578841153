import React, {useState, useEffect} from 'react';
import {getFlarumCookie, getForumUrl} from '../../services/endpoints';
import Base from '../../components/Theme/base';
import Title from '../../components/Theme/Title';
import {Spinner} from 'reactstrap';
import {Container} from './styles';

const Forum = () => {
  const [forumUrl, setForumUrl] = useState('');

  const getForumSettings = async () => {
    try {
      const flarumResponse = await getFlarumCookie();

      document.cookie = `${
        flarumResponse.data.userCookie.split(';')[0]
      }; Domain=${process.env.REACT_APP_DOMAIN}`;

      document.cookie = `locale=pt-BR; Domain=${process.env.REACT_APP_DOMAIN}`;

      const forumResponse = await getForumUrl();
      setForumUrl(forumResponse.value);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getForumSettings();
  }, []);

  return (
    <Base>
      <Title value="Fórum" />
      {!forumUrl ? (
        <Spinner />
      ) : (
        <Container className="container">
          <iframe
            className="forum-iframe"
            src={forumUrl}
            title="Fórum"
            width="100%"
            height="100%"
          />
        </Container>
      )}
    </Base>
  );
};

export default Forum;
