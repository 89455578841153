import styled from 'styled-components';

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f3f3f3;
  border: none;
  box-shadow: 0 10px 20px 0 rgba(14, 14, 14, 0.15);
  transition: transform 400ms ease, border 400ms ease;
  text-align: left;

  &:hover {
    box-shadow: none;
    transform: translate(0px, 6px) scale(1);
  }

  p {
    margin: 10px 0 0;
    padding: 0 10px;
    text-align: center;
    color: #333;
    font-weight: 600;
    font-size: 18px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    min-height: 42px;
  }

  h4 {
    margin: 15px 0;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
  }
`;

export const ImageCont = styled.div`
  overflow: hidden;
  position: relative;
  width: 100%;
  padding-bottom: 60%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    top: 0;
    left: 0;
    position: absolute;
  }
`;

export const BtnAvaliable = styled.button`
  display: block;
  padding: 10px 0;
  justify-content: center;
  margin: 0px 30px 20px 30px;
  align-items: center;
  border: 1px solid #038e83;
  background-color: #038e83;
  transition: transform 200ms ease, color 200ms ease,
    background-color 500ms ease;
  color: #fff;
  font-weight: 500;
  text-align: center;

  &:hover {
    background-color: transparent;
    transform: translate(0px, -5px);
    color: #038e83;
  }
`;

export const BtnUnavaliable = styled.div`
  display: block;
  padding: 10px 4px;
  margin: 0px 30px 20px 30px;
  justify-content: center;
  align-items: center;
  border: 1px solid #6e6e6e;
  background-color: transparent;
  color: #6e6e6e;
  font-weight: 500;
  text-align: center;
`;
