import React, {useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import Base from '../../../../components/Theme/base';
import CursoPage from '../../../../components/Cursos/Online';
import SubTitle from '../../../../components/Theme/Title/SubTitle';
import CourseItem from '../../../../components/Home/Cursos/Distancia/CourseItem';
import {Container, Col, Row} from 'reactstrap';
import api from '../../../../services/api';
import userPlaceholder from './../../../../assets/images/user_placeholder.png';
import {Spinner} from 'reactstrap';
import getCurrentUser from '../../../../utils/getCurrentUser';

const CursoDistancia = (props) => {
  const history = useHistory();
  const [courses, setCourses] = useState([]);
  const [courseActual, setCoursesActual] = useState({});
  const idCourse = window.location.pathname.replace('/online', '');
  const [loading, setLoading] = useState(false);

  function setScroolTop() {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 1000);
  }

  useEffect(() => {
    loadCourse();
  }, [idCourse]);

  const loadCourse = () => {
    setLoading(false);

    api
      .get(idCourse)
      .then((res) => {
        let lessons = [];
        if (res.data.lessons.length > 0) {
          //listagem de videos
          res.data.lessons.forEach((item) => {
            let videos = [];
            let material = [];
            if (item.lesson_contents.length > 0) {
              let contents = item.lesson_contents || [];

              contents.forEach((video, i) => {
                api
                  .get(
                    `/general/watcheds?content_iri=/lms/lesson_contents/${video.id}`,
                  )
                  .then((progress) => {
                    videos.push({
                      lessonContent: video.id,
                      type: video.type || '',
                      position: i,
                      watched_id:
                        progress.data.length > 0 ? progress.data[0].id : null,
                      urlPublica:
                        video.file !== null ? video.file.urlPublica : '',
                      title: video.title,
                      contentType:
                        video && video.file ? video.file.contenttype || '' : '',
                      samba_media_reference: video.samba_media_reference
                        ? video.samba_media_reference
                        : '',
                      vimeo_media_reference: video.vimeo_media_reference
                        ? video.vimeo_media_reference
                        : '',
                    });
                  })
                  .catch((erro) => {
                    console.error(erro);
                  });
              });
            }
            if (item.lesson_support_materials.length > 0) {
              //listagem de materiais
              let suport_material_array = item.lesson_support_materials
                ? item.lesson_support_materials
                : [];

              suport_material_array.forEach((suport_material) => {
                let suport_material_item = suport_material.support_material;

                if(suport_material_item){
                  suport_material_item.support_material_contents.sort((a, b) => (a.position > b.position) ? 1 : -1).forEach(mtr => {
                    material.push({
                      title: mtr.title,
                      url: mtr && mtr.file ? mtr.file.urlPublica : '',
                    });
                  })
                }
              });
            }
            lessons.push({
              _id: `/lms/lessons/${item.id}`,
              id: item.id,
              lesson_stats: item.lesson_stats,
              title: item.title,
              progress:
                item.lesson_stats.length > 0 &&
                item.lesson_stats.filter(
                  (lesson_stat) => lesson_stat.user_iri === getCurrentUser(),
                ).length > 0
                  ? item.lesson_stats.filter(
                      (lesson_stat) =>
                        lesson_stat.user_iri === getCurrentUser(),
                    )[0].progress
                  : 0,
              descricao: item.description,
              complemento: material,
              course_iri: '/lms/courses/' + res.data.id,
              course_id: res.data.id,
              videoaula: videos,
              exams: item.lesson_exams || [],
            });
          });
        }

        if (!res.data.active || !res.data.off_trails) {
          history.push('/');
        }

        var course = {
          _id: `/lms/courses/${res.data.id}?active=true`,
          id: res.data.id,
          scorm: res.data.scorm,
          file_scorm: res.data.file_scorm,
          image: {
            urlPublica:
              res.data.image !== null
                ? res.data.image.urlPublica
                : userPlaceholder,
          },
          nota: 5,
          course_stats: res.data.course_stats,
          duration: res.data.duration,
          data: res.data.created_at,
          views: res.data.views,
          photo:
            res.data.created_by &&
            res.data.created_by.Profile &&
            res.data.created_by.profile.avatar &&
            res.data.created_by.profile.avatar.file
              ? res.data.created_by.profile.avatar.file.urlPublica
              : userPlaceholder,
          autor: res.data.created_by ? res.data.created_by.name : '',
          titulo: res.data.title,
          description: res.data.summary,
          url: `/online/lms/courses/${res.data.id}`,
          aulas: lessons,
          exams: res.data.course_exams.length > 0 ? res.data.course_exams : [],
          force_lessons_sequence: res.data.force_lessons_sequence,
        };
        setCoursesActual(course);
        setLoading(true);
      })
      .catch((err) => {
        console.error('Erro ao Buscar Curso Atual', err);
      });

    return () => {
      setCoursesActual([]);
    };
  };

  useEffect(() => {
    const data = [];
    api
      .get(
        '/lms/courses?order[highlight asc,courses.published_at]=desc&active=true&off_trails=true',
      )
      .then((res) => {
        res.data.forEach((element) => {
          if (element.id !== courseActual.id && element.lessons.length > 0) {
            data.push({
              _id: `/lms/courses/${element.id}`,
              id: element.id,
              image: {
                urlPublica:
                  element.image !== null
                    ? element.image.urlPublica
                    : userPlaceholder,
              },
              nota: 5,
              course_stats: res.data.course_stats,
              duration: element.duration,
              data: element.created_at,
              views: '845',
              photo:
                res.datacreated_by &&
                element.created_by.profileavatar &&
                element.created_by.profile.avatar.file
                  ? element.created_by.profile.avatar.file.urlPublica
                  : userPlaceholder,
              autor: element.created_by ? element.created_by.name : '',
              titulo: element.title,
              description: element.summary,
              url: `/online/lms/courses/${element.id}`,
            });
          }
        });
        setCourses(data);
      })
      .catch((err) => {
        console.error('Erro ao buscar Cursos: ', err);
      });
    return () => {
      setCourses([]);
    };
  }, [courseActual]);

  const [qtd, setQtd] = useState(4);
  function expand(e) {
    setQtd((prevState) => (prevState = prevState + 4));
  }

  return (
    <Base>
      {courses.length >= 0 && (
        <>
          {loading === false ? (
            <div className="mt-30 mb-30 text-center">
              {' '}
              <Spinner />
            </div>
          ) : (
            <CursoPage course={courseActual} reload={loadCourse} />
          )}
          {courses.length > 0 ? (
            <>
              <Container className="p-0 mt-3">
                <SubTitle value="Sugestão de Cursos" />
                <Row>
                  {courses.slice(0, qtd).map((course, i) => (
                    <Col
                      onClick={setScroolTop}
                      key={`courseItem${i}`}
                      xs="12"
                      md="6"
                      lg="3"
                      className="mb-4 px-3 p-md-0">
                      {' '}
                      <CourseItem key={`courseItem${i}`} course={course} />{' '}
                    </Col>
                  ))}
                </Row>
                {courses.length > 0 && courses.length > qtd ? (
                  <Row className="p-4 p-lg-0">
                    <button
                      onClick={expand}
                      className="col-12 col-lg-3 LoadMoreButtom">
                      Carregar Mais
                    </button>
                  </Row>
                ) : (
                  ''
                )}
              </Container>
            </>
          ) : (
            <div className="mb-5" />
          )}
        </>
      )}
    </Base>
  );
};
export default CursoDistancia;
