export const getClosest = function (elem, selector) {
  for (; elem && elem !== document; elem = elem.parentNode) {
    if (elem.matches(selector)) return elem;
  }
  return null;
};

export const getMedias = async (playlist, currentContent) => {
  const data = playlist.filter(
    (item, index) => !!item.samba_media_reference && index >= currentContent,
  );
  if (!!data.length) {
    const arr = [];
    const titles = [];

    data.forEach((item) => {
      titles.push(item.title);
      arr.push({
        id: item.samba_media_reference,
        title: item.title,
      });
    });
    return {contents: arr, titles: titles};
  } else {
    return [];
  }
};

export const findMediaPosition = (array, key, value) => {
  for (let i = 0; i < array.length; i++) {
    if (array[i][key] === value) {
      return i;
    }
  }
  return null;
};
