const format = {
  data: (date, part_date) => {
    let stringMonths = [
      'Janeiro',
      'Fevereiro',
      'Março',
      'Abril',
      'Maio',
      'Junho',
      'Julho',
      'Agosto',
      'Setembro',
      'Outubro',
      'Novembro',
      'Dezembro',
    ];
    let stringDays = [
      'Domingo',
      'Segunda',
      'Terça',
      'Quarta',
      'Quinta',
      'Sexta',
      'Sábado',
    ];

    if (typeof date !== 'undefined') {
      let dateNumber = date.split(' ')[0];
      let hourNumber = date.split(' ')[1];
      let array_date = dateNumber.split('/');
      let full_date = new Date(
        array_date[1] + '/' + array_date[0] + '/' + array_date[2],
      );

      switch (part_date) {
        case 'stringDay':
          return stringDays[full_date.getDay()];
        case 'numberDay':
          return dateNumber.slice(0, 2);
        case 'numberDayDate':
          return array_date[0];
        case 'stringMonth':
          return stringMonths[full_date.getMonth()];
        case 'hour':
          return hourNumber.slice(0, 5);
        case 'numberYear':
          return array_date[2];
        default:
      }
    }
  },
};
export default format;

export const priceFormat = (number) => {
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(number);
};
