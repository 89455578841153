import styled from 'styled-components';

export const Buttons = styled.div`
  display: flex;
  justify-content: space-between;

  & .add-course {
    background-color: #fcc06d;
  }

  & .button {
    text-decoration: none;
  }
`;

export const Cupom = styled.div`
  margin: 20px 0;

  & .input {
    width: 50%;
    font-size: 20px;
  }
`;
