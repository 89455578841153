import React, {useState, useEffect} from 'react';
import Base from '../../components/Theme/base';
import api from '../../services/api';
import Loading from '../../components/Theme/Loading';
import Title from '../../components/Theme/Title';
import CourseDetailItem from './CouseItem';
import {Container, Row, Col} from 'reactstrap';
import {DescriptionCursoFull, ToLearnItem} from './style';
import stripTags from 'striptags';
import {useCheckout} from '../../store/Checkout';
import {useLocation, useHistory} from 'react-router-dom';
import factoryCourse from './factoryCourse';
import {isProfileImcompletedError} from '../../utils/errorHandlings';

const CourseDetail = () => {
  const getId = () => {
    const idUrl = pathname.split('/');
    return state ? state.id : idUrl[idUrl.length - 1];
  };
  const history = useHistory();
  const {state, pathname} = useLocation();
  const [loading, setLoading] = useState(true);
  const idCourse = getId();
  const [course, setCourse] = useState({});
  const {PushItem, setIsProfileImcompleted, setLastOpenCartUrl} = useCheckout();

  useEffect(() => {
    setLoading(true);
    api
      .get(`/lms/courses/${idCourse}`)
      .then((response) => {
        if (response.data && response.data.free) {
          history.push(`/online/lms/courses/${response.data.id}`);
        }

        setCourse(factoryCourse(response));
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error('ERROR', error);
      });
  }, []);

  const handleBuyNow = async (course) => {
    try {
      await PushItem(course.id);
      history.push('/cart');
    } catch (err) {
      console.error(err);
      if (
        isProfileImcompletedError(
          err && err.response && err.response.data && err.response.data,
        )
      ) {
        setIsProfileImcompleted(true);
        setLastOpenCartUrl(history.location.pathname);
        history.push('/meusdados');
      }
      alert(
        (err &&
          err.response &&
          err.response.data &&
          err.response.data.detail) ||
          'Ocorreu um erro.',
      );
    }
  };

  return (
    <Base>
      {loading ? (
        <Loading />
      ) : (
        <Container className="mb-5">
          <Title value="Cursos Online" />
          <Row>
            <Col xs={12} lg={6} className="mb-4 mb-lg-0">
              <DescriptionCursoFull>{course.titulo}</DescriptionCursoFull>

              {course.overview && (
                <>
                  <h3>O que você irá aprender</h3>
                  <ToLearnItem
                    dangerouslySetInnerHTML={{
                      __html: stripTags(course.overview),
                    }}
                  />
                </>
              )}
            </Col>
            <Col xs={12} lg={6}>
              <CourseDetailItem course={course} handleBuyNow={handleBuyNow} />
            </Col>
          </Row>
        </Container>
      )}
    </Base>
  );
};

export default CourseDetail;
