import React from 'react';
import { InternalTitleStyle } from './styles';
import PropTypes from 'prop-types';
import {  Container } from 'reactstrap';


const InternalTitle = (props) => {
 
  return (
    <Container className="p-lg-0 pt-lg- mt-lg-5">
      <InternalTitleStyle>{props.value}</InternalTitleStyle>
    </Container>
    
  );
};
InternalTitle.prototype = {
  value:PropTypes.string.isRequired
}

export default InternalTitle;