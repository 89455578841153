let Components = {};

Components['BannerMain'] = require('../Theme/Carousel').default;
Components['BlockAbout'] = require('../Home/Sobre').default;
Components['CoursesOnline'] = require('../Home/Cursos/Distancia').default;
Components['CoursesPresentials'] = require('../Home/Cursos/Presencial').default;
Components['Testimonials'] = require('../Home/Depoimentos').default;
Components['Faq'] = require('../Home/Faq').default;

export default Components
