import React, {useState} from 'react';
import PropTypes from 'prop-types';
import stripTags from 'striptags';

import {
  FaqItem,
  DadosFaq,
  TitleQuestion,
  DescriptionFaq,
  Checkbox,
  LabelButtom,
} from './style';

const Faq = ({faqObject, ...props}) => {
  const [active, setActive] = useState('active');
  function expand(e) {
    setActive((prevState) => !prevState);
  }

  return (
    <FaqItem className="faqItem  col-12 d-flex flex-column p-0">
      <TitleQuestion className={`${active ? '' : 'active'} pl-2 `}>
        <div
          dangerouslySetInnerHTML={{
            __html: stripTags(faqObject.titulo),
          }}
        />

        <LabelButtom
          htmlFor={`check-${props.index}`}
          onClick={expand}
          data-index={props.index}
          className="m-0 col-2 col-lg-1 d-flex align-items-center p-0 justify-content-end">
          <i className="fal fa-plus-circle" />
        </LabelButtom>
      </TitleQuestion>
      <Checkbox id={`check-${props.index}`} type="checkbox" />
      <DadosFaq className="col-12 contentFaq">
        <DescriptionFaq>
          <div
            dangerouslySetInnerHTML={{
              __html: stripTags(faqObject.answer),
            }}
          />
        </DescriptionFaq>
      </DadosFaq>
    </FaqItem>
  );
};

Faq.propTypes = {
  faqObject: PropTypes.object.isRequired,
  index: PropTypes.number,
};

export default Faq;
