import React, { useEffect, useState } from 'react';
import Review from '../../Depoimento/Review';
import userPlaceholder from '../../../assets/images/user_placeholder.png';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Carousel as CarouselReviews } from 'react-responsive-carousel';
import { BlockSection, TitleReviews, BoxReviews } from './style';
import api from '../../../services/api';

const BlockReviews = (props) => {
  const [reviews, setReviews] = useState([]);
  useEffect(() => {
    const rev = [];
    if (props.content && props.content.length > 0) {
      props.content.forEach(item => {
        if (item.publish) {
          rev.push(
            {
              _id: item.user_iri,
              titulo: item.title,
              description: item.description,
              user: {
                name: item.user ? item.user.name : "",
                photo: item.user && item.user.hasOwnProperty('profile') && item.user.profile.hasOwnProperty('avatar') &&
                  item.user.profile.avatar.hasOwnProperty('file') && item.user.profile.avatar.file !== null
                  ? item.user.profile.avatar.file.urlPublica : userPlaceholder
              }
            }
          );
        }
      })
      setReviews(rev);
    }


  }, [])

  return (
    <BlockSection className="container CourseBlock ReviewBlock p-0">
      {reviews.length > 0 && (
        <Row >
          <Col xs="12" lg="3" className="d-flex flex-column align-items-start">
            <TitleReviews>Depoimentos</TitleReviews>
            <BoxReviews className="w-100">
              <h3>{props.block.title ? props.block.title : "O que dizem sobre nós"}</h3>
              <p>Mais Depoimentos</p>
              <Link className="d-none d-lg-block" to='/depoimentos'><i className="far fa-arrow-alt-circle-right" /></Link>
            </BoxReviews>
          </Col>
          <Col xs="12" lg="9" className="p-0 ">
            <CarouselReviews centerMode centerSlidePercentage={window.innerWidth <= 767 ? 100 : 50} showIndicators={false} showStatus={false} showThumbs={false}>
              {reviews.map((review, i) =>
                <Review key={`revieweItem${i}`} reviewObject={review} />
              )}
            </CarouselReviews>

          </Col>
        </Row>)}
    </BlockSection>
  );
};


export default BlockReviews;
