import React, {useEffect, useState} from 'react';
import Base from '../../components/Theme/base';
import {Container, Spinner, Row, Col, Input} from 'reactstrap';
import Title from '../../components/Theme/Title';
// import api from '../../services/api';
// import userPlaceholder from '../../assets/images/user_placeholder.png';
import Cart from './CartItem';
import {useCheckout} from '../../store/Checkout';
import {Cupom, Buttons} from './style';
import {Link} from 'react-router-dom';
import Resume from '../../components/Cart/Resume';
import factoryCourse from '../CheckoutItem/factoryCourse';

const Checkout = () => {
  const [loading, setLoading] = useState(true);
  const [cupom, setCupom] = useState();
  const {
    items,
    getSubtotal,
    getDiscount,
    getTotal,
    PushDiscount,
  } = useCheckout();

  useEffect(() => {
    const load = async () => {
      await new Promise((resolve) => setTimeout(resolve, 2000));
      setLoading(false);
    };
    load();
  }, []);

  const handleAddCupom = () => {
    const temp = parseInt(cupom);
    PushDiscount(temp);
  };

  return (
    <Base>
      <Container>
        <Title value="Meu Carrinho" />
        {loading ? (
          <Spinner />
        ) : (
          <Row className="justify-content-between">
            {items.length > 0 ? (
              <>
                <Col xs={12} lg={6}>
                  {items.map((item, i) => (
                    <Cart key={item.id + i} course={factoryCourse(item)} />
                  ))}
                </Col>
                <Col xs={12} lg={5} className="mt-4 mt-lg-0">
                  <Row className="d-flex flex-column">
                    <Col>
                      <Resume
                        items={items}
                        getSubtotal={getSubtotal}
                        getDiscount={getDiscount}
                        getTotal={getTotal}
                      />
                    </Col>
                    {/* <Col>
                      <Cupom className="d-flex justify-content-between">
                        <Input
                          type="number"
                          className="input"
                          onChange={(e) => setCupom(e.target.value)}
                          placeholder="Insira seu cupom"
                        />
                        <button
                          className="LoadMoreButtom m-0 px-3"
                          onClick={handleAddCupom}>
                          Adicionar Cupom
                        </button>
                      </Cupom>
                    </Col> */}
                    <Col>
                      <Buttons className="my-4">
                        <Link
                          to="/online/lms/courses"
                          className="LoadMoreButtom add-course m-0 mr-3 px-4 button">
                          Adicionar mais cursos
                        </Link>
                        <Link
                          to="/checkout"
                          className="LoadMoreButtom buy-now m-0 px-4 button">
                          Comprar agora
                        </Link>
                      </Buttons>
                    </Col>
                  </Row>
                </Col>
              </>
            ) : (
              <div>
                <h2>Ainda não possui itens no carrinho. :(</h2>
              </div>
            )}
          </Row>
        )}
      </Container>
    </Base>
  );
};

export default Checkout;
