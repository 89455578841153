import styled from 'styled-components';
import {Field} from 'formik';

export const Pagamento = styled.div`
  & .formas-pagamento {
    margin-bottom: 50px;

    & .boleto i {
      font-size: 45px;
      color: #fcc06d;
    }
  }
`;
export const Review = styled.div`
  .review-credit-card {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px 0;
  }
  .review-items {
    display: grid;
    grid-template-columns: 0.5fr 1.5fr 1fr;
    grid-gap: 20px 30px;
    margin-bottom: 40px;
  }
`;

export const StyledField = styled(Field)`
  border-bottom: none !important;
  background-color: #e1e1e1;
  padding: 15px 10px;
  width: 100%;
  font-size: 22px;
  line-height: 1em;
  &::placeholder {
    color: #7f7f7f;
  }
`;

export const Label = styled.label`
  font-size: 18px;
  margin: 20px 0 10px 0;
  color: #3f3f3f;
`;

export const ContainerForm = styled.div`
  & input {
    border-bottom: none !important;
    background-color: #e1e1e1;
    padding: 15px 10px;
    width: 100%;
    font-size: 22px;
    line-height: 1em;
    &::placeholder {
      color: #7f7f7f;
    }
  }

  & select {
    box-sizing: content-box;
    font-size: 20px;
    line-height: 2em;
    border: none;
  }

  & .Form-Error {
    font-size: 14px;
    color: red;
    margin-top: 2px;
  }
`;
