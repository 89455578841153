import React from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import PrivateRoute from './components/Cursos/Online/PrivateRoute';
import Home from './pages/Home';
import Internal from './pages/Internal';
import Distancia from './pages/Cursos/Online';
//import Presencial from './pages/Cursos/Presencial';
import CursoDistancia from './pages/Cursos/Internas/Online';
//import CursoPresencial from './pages/Cursos/Internas/Presencial';
import Live from './pages/Live';
import Faq from './pages/Faq';
import Depoimentos from './pages/Depoimentos';
import Contato from './pages/Contato';
import Sobre from './pages/Sobre';
import MeusCursos from './pages/MeusCursos';
import Trilhas from './pages/Trilhas';
import Login from './pages/Login';
import MeusDados from './pages/MeusDados';
import Cadastrar from './pages/Cadastrar';
import Forum from './pages/Forum';
import TrilhaInterna from './pages/Trilhas/Internas';
import CheckoutItem from './pages/CheckoutItem';
import Cart from './pages/Cart';
import Ranking from './pages/Ranking';
import Premios from './pages/Premios';
import Checkout from './pages/Checkout';
import CheckoutReview from './pages/Checkout/Review';

const Routes = () => (
  <BrowserRouter>
    <Switch>
      {/* REMOVER PARA HABILITAR ECOM */}
      <Route component={CursoDistancia} exact path="/online/lms/courses/:id" />

      {/* DESCOMENTAR PARA HABILITAR ECOM + Descomentar useEffect dentro do Checkout.js*/}
      {/* <PrivateRoute
        component={CursoDistancia}
        exact
        path="/online/lms/courses/:id"
      /> */}

      <Route path="/" exact component={Home} />
      <Route path="/online/lms/courses" exact component={Distancia} />
      <Route path="/checkout/" exact component={Checkout} />
      <Route path="/checkout/review" exact component={CheckoutReview} />
      <Route
        path="/online/lms/courses/pay/:id"
        exact
        component={CheckoutItem}
      />
      <Route path="/cart" exact component={Cart} />
      {/* <Route path="/online/lms/courses/:id" exact component={CursoDistancia} /> */}

      {/* rotas de cursos presenciais
        <Route path="/presencial/lms/classroom_courses" exact component={Presencial} />
        <Route path="/presencial/lms/classroom_courses/:id" exact component={CursoPresencial} />  
        */}

      <Route path="/live" exact component={Live} />

      <Route path="/faq" exact component={Faq} />

      <Route path="/depoimentos" exact component={Depoimentos} />

      <Route path="/contato" exact component={Contato} />

      <Route path="/sobre" exact component={Sobre} />

      <Route path="/meuscursos" exact component={MeusCursos} />

      <Route path="/trails" exact component={Trilhas} />
      <Route path="/trails/:id" exact component={TrilhaInterna} />

      <Route path="/login" exact component={Login} />

      <Route path="/meusdados" exact component={MeusDados} />
      <Route path="/forum" exact component={Forum} />

      <Route path="/cadastrar" exact component={Cadastrar} />
      <Route path="/page/:slug" exact component={Internal} />

      <Route path="/ranking" exact component={Ranking} />
      <Route path="/premios" exact component={Premios} />

      <Route component={Home} />
    </Switch>
  </BrowserRouter>
);

export default Routes;
