import React, {useCallback, useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Row, Col} from 'reactstrap';
import {
  TitleVideo,
  FeaturedBoxVideo,
  NextVideo,
  VideoStyle,
  SingleVideoThumb,
  WatchedIcon,
} from './styles';
import {HandleRefreshContextVideo} from './handleRefreshContext';
import {getWatched} from '../../../services/endpoints';

const SingleVideo = ({aula, lesson, isActualVideo, indice}) => {
  const {changeVideo} = useContext(HandleRefreshContextVideo);
  const [isVideoWatched, setIsVideoWatched] = useState(false);

  useEffect(() => {
    const isLessonWatched = async () => {
      try {
        const {data} = await getWatched(
          `/lms/lesson_contents/${aula.lessonContent}`,
        );
        setIsVideoWatched(data && data[0] && data[0].watched);
      } catch (error) {
        console.error('CheckedInfo.isLessonChecked:', error);
      }
    };

    isLessonWatched();
  }, [aula]);

  return (
    <NextVideo isVideoWatched={isVideoWatched} isActualVideo={isActualVideo}>
      <Row
        className={`pt-4 pb-4 mx-0 video-line`}
        onClick={(e) => changeVideo(indice)}>
        <Col xs="4" className="d-flex align-items-center p-0 ">
          <FeaturedBoxVideo>
            <SingleVideoThumb
              url={(lesson.thumbnail && lesson.thumbnail.urlPublica) || ''}
            />

            {/* {aula.type === "SAMBA_MEDIAss" &&
              <SambaVideo reference={aula.samba_media_reference} />} */}
            {aula.type === 'FILE' && (
              <VideoStyle>
                <source src={aula.urlPublica} type="video/mp4" />
              </VideoStyle>
            )}
          </FeaturedBoxVideo>
        </Col>
        <Col xs="8">
          <TitleVideo>{aula.title}</TitleVideo>
        </Col>
      </Row>
      <WatchedIcon className={isVideoWatched ? 'fas fa-check' : ''} />
    </NextVideo>
  );
};
SingleVideo.propTypes = {
  aula: PropTypes.object.isRequired,
  isActualVideo: PropTypes.bool.isRequired,
  indice: PropTypes.number.isRequired,
};

export default SingleVideo;
