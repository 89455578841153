import React, {useContext,} from 'react';
import PropTypes from 'prop-types';
import { TextContent, TitleSobre,BoxSobre } from './styles';
import CourseRefreshContext from './coursesContext';



const CategoriasCursos = ({list,...props}) => {
const setListCourses = useContext(CourseRefreshContext);
  
  return(
    <TextContent onClick={(e) => setListCourses(list.title)} className="col-6 col-sm-4 col-md-4 col-lg-2 d-flex align-items-start flex-column">
        <TitleSobre>{list.qtd}</TitleSobre>
        <BoxSobre className="BoxSobre">
            <h3>{list.title}</h3> 
            <p>ver</p>
            <i className="far fa-arrow-alt-circle-right"/>
        </BoxSobre>
    </TextContent>
  )
    
};
CategoriasCursos.propTypes = {
    list: PropTypes.object.isRequired
}
export default CategoriasCursos;
