import styled from 'styled-components';


export const FormStyle = styled.div`
    & input, textarea {
      background-color: transparent;
    }

    & .LoadMoreButtom{
        margin: 0;
        font-weight: bold;
    }
    & button:disabled::before {
        content: "";
        color: #fff;
        font-family: sans-serif;
        animation: spin 0.7s linear infinite;
        position: absolute;
        left: 10px;
        top: 10px;
        border: 0.15em solid #fff;
        width: 1.4em;
        height: 1.4em;
        border-radius: 50%;
        border-left-color: transparent;
      }
      @keyframes spin {
        to {
          transform: rotate(360deg);
        }
      }
    
`;