import XMLParser from 'react-xml-parser';

export class ScormHelper {
  #scorm;
  #folder;
  #manifest;

  constructor(scorm, folder) {
    this.#scorm = scorm;
    this.#folder = folder;
  }

  static init(scorm, folder) {
    return new ScormHelper(scorm, folder);
  }

  _getSchemaVersion() {
    const version = this.#manifest.getElementsByTagName('schemaversion')[0];

    return version && version.value ? version.value : 2004;
  }

  _getIdentifierRef() {
    const ref = this.#manifest.getElementsByTagName('item')[0];
    return ref && ref.attributes.identifierref;
  }

  _getResources() {
    return this.#manifest.getElementsByTagName('resource');
  }

  _getRootResource() {
    const resources = this._getResources();
    const identifierRef = this._getIdentifierRef();
    return resources.find(
      (resource) =>
        resource && resource.attributes.identifier === identifierRef,
    );
  }

  _getScormInitialFile() {
    const resource = this._getRootResource();
    if (!resource) return null;

    return resource.attributes.href;
  }

  //   async _fetchJsonTotal(url) {
  //     try {
  //       const response = await fetch(url);
  //       const data = await response.json();
  //       return data.slides?.length || 0;
  //     } catch (_) {
  //       return 0;
  //     }
  //   }

  //   async _getTotalSlides() {
  //     const resource = this._getRootResource();
  //     if (!resource) return null;

  //     const files = resource.getElementsByTagName('file');
  //     const file = files[0];
  //     const jsonUrl = file ? file.attributes.href : null;

  //     if (!jsonUrl) {
  //       const initialFile = this._getScormInitialFile().replace('.html', '.json');
  //       if (!initialFile) return Promise.resolve((resolve) => resolve(0));

  //       return await this._fetchJsonTotal(
  //         [...this.#folder, 'scos', initialFile].join('/'),
  //       );
  //     }

  //     // return await this._fetchJsonTotal([...this.#folder, jsonUrl].join('/'));
  //   }

  async readManifest() {
    this.#manifest = new XMLParser().parseFromString(this.#scorm);
    const scorm = {
      version: this._getSchemaVersion(),
      initialFile: this._getScormInitialFile(),
      urlFile: [...this.#folder, this._getScormInitialFile()].join('/'),
      //   totalSlides: await this._getTotalSlides(),
    };

    return scorm;
  }
}
