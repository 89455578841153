import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {loadPage} from '../../../utils/loadPage';
import CPHome from '../../ComponentFactory';

const Factory = ({slug}) => {
  const [loading, setLoading] = useState(true);
  const [containers, setContainers] = useState([]);

  useEffect(() => {
    async function init() {
      try {
        if (slug) {
          const contents = await loadPage(slug);
          setContainers(contents.blocks);
          //setPage(contents.page)
          setLoading(false);
        }
      } catch (err) {
        console.error('error', err);
        setLoading(false);
      }
    }

    init();

    return () => {};
  }, []);

  return (
    !loading &&
    containers.length > 0 && (
      <section>
        {containers.map((block, index) => (
          <div key={`page-${index}`} className="container">
            <CPHome
              page
              block={block.block}
              componentName={block.componentName}
              content={block.content}
            />
          </div>
        ))}
      </section>
    )
  );
};

Factory.propTypes = {
  slug: PropTypes.any.isRequired,
};

export default Factory;
