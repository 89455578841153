import React from 'react';
import PropTypes from 'prop-types';
// import { Link } from 'react-router-dom';


const  Anexo= (props) => {
  return (
    <a href={props.url} target="_blank" className="d-flex"><i className="far fa-file-pdf mr-3"></i> {props.title_file}</a>
  );
};
Anexo.propTypes ={
    title_file: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired
}


export default Anexo;
