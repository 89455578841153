import React from 'react';
import { Loading } from './styles';

const ContainerLoading = (props) => {
 
  return (
    <Loading>
      <div className="loading-container">
        <div className="loading"></div>
      </div>
    </Loading>
    
    
  );
};


export default ContainerLoading;