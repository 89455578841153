import styled from 'styled-components';

export const BannerImg = styled.div`
  width: 100%;
`;
export const ContentPage = styled.div`
  width: 100%;
  & img {
    width: 100% !important;
    margin-bottom: 15px;
  }
  & div.sc-dTdPqK {
    display: initial;
  }
  .content {
    margin-top: 100px;
    h1 {
      font-size: 2em;
    }
    h2 {
      font-size: 2.5em;
      padding-right: 1em;
      margin: 0 auto;
      line-height: 1;
      text-align: start;
      color: #444446;
    }
    p {
      margin-bottom: 2em;
    }
  }
  @media (max-width: 991px) {
    .content {
      margin-top: 15px;
    }
  }
`;
