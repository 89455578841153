import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import api from '../../../../services/api';
import stripTags from 'striptags';
import format from '../../../../utils/format';
import ContainerLoading from '../../../Theme/Loading';

import {
  CursoItem,
  Cursoimage,
  InfoCurso,
  Stars,
  Duration,
  Views,
  DadosCurso,
  Professor,
  ImgProfessor,
  NomeProfessor,
  DataCurso,
  TitleCurso,
  DescriptionCurso,
  CursoLink,
  UserImg,
  Icon,
  ContainerPriceCourse,
  PriceCourse,
  DiscountPrice,
  NormalPrice,
  Info,
  InfoItem,
} from './style';

const CourseItem = ({course, noPrice, ...props}) => {
  const [evaluation, setEvaluation] = useState(course.nota);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    api
      .get(
        `/general/currentUserEvaluations?content_iri=/lms/courses/${course.id}`,
      )
      .then((response) => {
        const res = response.data || [];
        const ratings =
          res.filter((el) => el.type === 'RATING').length > 0
            ? res.filter((el) => el.type === 'RATING')[0].average
            : 5;
        setEvaluation({ratings});
        setLoading(true);
      })
      .catch((err) => {
        console.error('Erro na nota', err);
      });
  }, []);

  const stars = [];
  for (var i = 0; i < 5; i++) {
    evaluation.ratings > i ? stars.push('fas') : stars.push('far');
  }

  return loading === false ? (
    <ContainerLoading />
  ) : (
    <CursoItem to={course.url} className={`ml-0 mx-sm-2 ml-lg-4 mr-0`}>
      <Cursoimage className={props.situation}>
        {course.image && course.image.urlPublica && (
          <UserImg src={course.image.urlPublica} alt="{props.titulo}" />
        )}
      </Cursoimage>
      <InfoCurso>
        <Stars>
          {stars.map((star, i) => (
            <i key={`star-${i}`} className={`${star} fa-star`} />
          ))}
          <span className="font-italic"> {evaluation.ratings}/5</span>
        </Stars>
        <Duration>
          <Icon className="far fa-clock" />
          {course.duration}
        </Duration>
        <Views>
          <Icon className="fas fa-eye" />
          {course.views}
        </Views>
      </InfoCurso>
      <DadosCurso>
        <TitleCurso>{course.titulo}</TitleCurso>
        {/*<DescriptionCurso dangerouslySetInnerHTML={{ __html: stripTags(course.description) }} />*/}
        {/*
          <Info>
          <InfoItem>
            Disponibilidade do curso: 3 meses após a efetuação do pagamento
          </InfoItem>
        </Info>
        */}
        <ContainerPriceCourse>
          <CursoLink className={
              props.categoryCourse !== ''
                ? noPrice
                  ? 'd-flex justify-content-between align-items-center w-100'
                  : 'd-flex justify-content-between align-items-center'
                : ''
          }>
            <i className="far fa-arrow-alt-circle-right" />
            {props.categoryCourse !== '' && props.categoryCourse}
          </CursoLink>
          {course.price > 0 && !noPrice && (
            <PriceCourse>
              <DiscountPrice>R$ {course.price.toFixed(2).replace('.',',')}</DiscountPrice>
              <NormalPrice>R$ {course.promotional_price.toFixed(2).replace('.',',')}</NormalPrice>
            </PriceCourse>
          )}
        </ContainerPriceCourse>
      </DadosCurso>
    </CursoItem>
  );
};

CourseItem.propTypes = {
  course: PropTypes.object.isRequired,
  situation: PropTypes.string,
};

export default CourseItem;
