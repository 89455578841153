import React, { useState, useEffect, memo } from 'react';
import Base from '../../components/Theme/base';
import Title from '../../components/Theme/Title';
import Review from '../../components/Depoimento/Review';
import { Container, Row, Col } from 'reactstrap';
import api from '../../services/api'
import userPlaceholder from '../../assets/images/user_placeholder.png';
import { Spinner } from 'reactstrap';
import { ModalExamSytled } from './style'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup';

const Depoimentos = () => {

  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [qtd, setQtd] = useState(6);
  useEffect(() => {
    setLoading(true);
    const rev = [];
    api.get(`/cms/testimonials?publish=true&perPage=${qtd}`)
      .then((response) => {
        response.data.forEach(item => {
          if (item.publish) {
            rev.push(
              {
                _id: item.user_iri,
                titulo: item.title,
                user: {
                  name: item.user ? item.user.name : "",
                  photo: item.user && item.user.hasOwnProperty('profile') && item.user.profile.hasOwnProperty('avatar') &&
                    item.user.profile.avatar.hasOwnProperty('file') && item.user.profile.avatar.file !== null
                    ? item.user.profile.avatar.file.urlPublica : userPlaceholder
                },
                description: item.description

              }
            );
          }
        })
        setReviews(rev);
        setLoading(false);
      })

      .catch((err) => {
        setLoading(false);
        console.error('Erro ao buscar testimonials', err);
      })


  }, [qtd])
  const Schema = Yup.object().shape({
    title: Yup.string()
      .min(2, 'Muito Curto!')
      .max(100, 'Muito Longo!')
      .required('Campo obrigatório'),
    testimonial: Yup.string()
      .min(2, 'Muito Curto!')
      .max(500, 'Muito Longo!')
      .required('Campo obrigatório'),
  });

  const TestimonialForm = () => {
    return (
      <div>
        <h1>Enviar Depoimento</h1>
        <Formik
          initialValues={{
            title: '',
            testimonial: '',
          }}
          validationSchema={Schema}
          onSubmit={values => {
            // same shape as initial values
            setSubmitting(true);
            api.post(`/cms/testimonials`, {
              user_iri: atob(window.localStorage.getItem('user-endpoint')),
              description: values.testimonial,
              title: values.title
            })
              .then((res) => {
                setTimeout(() => {
                  setModal(false);
                  setSubmitting(false);
                }, 500)
              })
              .catch((err) => {
                setSubmitting(false);
                console.error("Erro ao enviar depoimento: ", err);
              })




          }}
        >
          {({ errors, touched }) => (
            <Form>
              <Col xs="12" className="mb-4 pb-4">
                <div className="has-float-label">
                  <Field id="input_title" name='title' className="w-100" placeholder="Título" />
                  <label htmlFor="input_title"><b>Título</b></label>
                  {errors.title && touched.title && errors.title ? (
                    <div className="Form-Error">{errors.title}</div>
                  ) : null}
                </div>
              </Col>
              <Col xs="12" className="mt-4">
                <div className="has-float-label">
                  <Field id="input_testimonial" component="textarea" name='testimonial' className="w-100" placeholder="" />
                  <label htmlFor="input_testimonial"><b>Depoimento</b></label>
                  {errors.testimonial && touched.testimonial && errors.testimonial ? (
                    <div className="Form-Error">{errors.testimonial}</div>
                  ) : null}
                </div>
              </Col>
              <Col xs="12" >
                {!submitting ?
                  <button type="submit"
                    className="col-12 col-lg-3 LoadMoreButtom">Salvar</button> :
                  <div className="col-12 col-lg-3 LoadMoreButtom  disabled text-center" disabled>
                    <div className="spinner-border text-primary" role="status">
                      <span className="sr-only mr-1">Loading...</span>
                    </div>Salvando...</div>
                }
              </Col>
            </Form>
          )}
        </Formik>
      </div>
    )
  };


  function expand(e) {
    setQtd(prevState => prevState += 3);
  }
  return (
    <Base>
      <Title value="Depoimentos" />
      <Container>
        <Row className="d-flex justify-content-start w-100 flex-wrap">
          <Col xs={12} className="d-flex justify-content-start ">
            <Row className="p-lg-0">
              <button onClick={e => setModal(true)} className="col-12 m-0 mb-4 LoadMoreButtom">Deixe o seu depoimento</button>
            </Row>
          </Col>
        </Row>
      </Container>

      <Container className="p-0 mb-5 pb-5">
        {reviews && reviews.length > 0 ?
          <Row>
            {reviews.map((review, i) =>
              <Col key={`revieweItem${i}`} xs="12" md="6" lg="4" className="mb-5 px-0">
                <Review reviewObject={review} />
              </Col>
            )}
          </Row>
          :
          <Row>
            <h2>Nenhum depoimento encontrado</h2>
          </Row>}
        {loading && <div className="mt-30 mb-30 text-center"><Spinner /></div>}
        {reviews.length > 0 ?
          <Row className="p-4 p-lg-0">
            <button onClick={expand} className="col-12 col-lg-3 LoadMoreButtom">Carregar Mais</button>
          </Row> : ''}
      </Container>

      {modal === true && (
        <ModalExamSytled className="d-flex justify-content-center">
          <Container className="exam-style mx-2 ">
            <Row className="d-flex justify-content-end">
              <button onClick={e => setModal(false)} className="close-button">
                <i className="fas fa-times"></i>
              </button>
            </Row>
            <TestimonialForm />
          </Container>
        </ModalExamSytled>)}

    </Base>
  )

};
export default memo(Depoimentos);
