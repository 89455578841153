import userPlaceholder from '../../assets/images/user_placeholder.png';

const factoryCourse = (response) => {
  return {
    _id: `/lms/courses/${response.data.id}`,
    id: response.data.id,
    image: {
      urlPublica:
        response.data.image !== null
          ? response.data.image.urlPublica
          : userPlaceholder,
    },
    overview: response.data.overview,
    price: response.data.price,
    promotional_price: response.data.promotional_price,
    enrollments: response.data.online_course_enrollments,
    nota:
      response.data.evaluations.filter((el) => el.type === 'RATING').length > 0
        ? {
            ratings: response.data.evaluations.filter(
              (el) => el.type === 'RATING',
            )[0].average,
          }
        : {ratings: 5},
    duration: response.data.duration || '',
    data: response.data.created_at || '',
    views: response.data.views,
    photo: userPlaceholder,
    autor: response.data.created_by ? response.data.created_by.name : '',
    titulo: response.data.title || '',
    description: response.data.summary || '',
    url: `online/lms/courses/${response.data.id}`,
    evaluations: response.data.evaluations || [],
  };
};

export default factoryCourse;
