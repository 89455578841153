import React, {useState, useEffect} from 'react';
import {Spinner, Col, Row, Container} from 'reactstrap';
import Base from '../../components/Theme/base';
import Title from '../../components/Theme/Title';
import {useCheckout} from '../../store/Checkout';
import Resume from '../../components/Cart/Resume';
import Boleto from './Boleto';
import CreditCard from './CreditCard';
import Pagseguro from './PagSeguro';
import {Pagamento} from './style';

const Checkout = () => {
  const [loading, setLoading] = useState(false);
  const [methods, setMethods] = useState();
  const {
    cart,
    items,
    getSubtotal,
    getDiscount,
    getTotal,
    getPaymentsForm,
    setPaymentsForm,
  } = useCheckout();

  useEffect(() => {
    if (cart) {
      (async () => {
        try {
          setLoading(true);
          const {data} = await getPaymentsForm(cart.id);
          const url = data.find((item) => item.method === 'pagseguro_direct')
            .options.script;
          const script = document.createElement('script');
          script.src = url;
          script.async = true;
          document.body.appendChild(script);
          setLoading(false);
        } catch (error) {
          console.error(error.response.data);
          alert(
            (error &&
              error.response &&
              error.response.data &&
              error.response.data.detail) ||
              'Ocorreu um erro.',
          );
          setLoading(false);
        }
      })();
    }
  }, [cart]);

  // const getMethod = (name) => {
  //   console.log(methods.find((item) => item.method === name));
  //   return methods.find((item) => item.method === name);
  // };

  return (
    <Base>
      <Title value="Formas de pagamento" />
      {loading ? (
        <Spinner />
      ) : (
        <Container>
          <Row className="justify-content-between my-5">
            {items.length > 0 ? (
              <>
                <Col xs={12} lg={6} className="p-0">
                  <Pagamento>
                    <Pagseguro />
                    {/* {getMethod('markup:creditcard') && <CreditCard />} */}
                    {/* {getMethod('markup:boleto') && (
                        <Boleto
                          order={cart}
                          setPaymentsForm={setPaymentsForm}
                        />
                      )} */}
                  </Pagamento>
                </Col>
                <Col xs={12} lg={5} className="mt-4 mt-lg-0">
                  <Resume
                    items={items}
                    getSubtotal={getSubtotal}
                    getDiscount={getDiscount}
                    getTotal={getTotal}
                  />
                </Col>
              </>
            ) : (
              <div>
                <h2>Ainda não possui itens no carrinho. :(</h2>
              </div>
            )}
          </Row>
        </Container>
      )}
    </Base>
  );
};

export default Checkout;
