class ScormApi {
  state = {};
  errorString = {};
  lastError = 0;
  listener;

  constructor(listener = () => {}) {
    this.listener = listener;
    this.state['cmi.core.student_id'] = Math.random();
    this.state['version'] = '1.2';
    this.state['cmi.core.student_name'] = Math.random();
    this.state['cmi.core.lesson_mode'] = '123';
    this.state['cmi.core.lesson_location'] = '0';
    this.state['cmi.core.lesson_status'] = 'not attempted';
    this.state['cmi.core.credit'] = '123';
    this.state['cmi.core.score.raw'] = '123';
    this.state['cmi.core.score.max'] = '123';
    this.state['cmi.core.score.min'] = '123';
    // eslint-disable-next-line
    const confirmShow = false;

    let totalTime = 12;
    let totalTimeHour = totalTime / 144000;
    totalTime %= 144000;
    let totalTimeMinte = totalTime / 6000;
    totalTime %= 6000;
    let totalTimeSecond = totalTime / 100;
    totalTime %= 100;

    this.state['cmi.core.total_time'] =
      '' +
      totalTimeHour +
      ':' +
      totalTimeMinte +
      ':' +
      totalTimeSecond +
      '.' +
      totalTime;
    this.state['cmi.core.entry'] = '123';
    this.state['cmi.suspend_data'] = {};
    this.state['cmi.launch_data'] = '123';
    this.state['cmi.core._children'] =
      'student_id,student_name,lesson_location,credit,lesson_status,entry,score,total_time,lesson_mode,exit,session_time';
    this.state['cmi.core.score._children'] = 'raw,min,max';
    this.state['cmi.core.session_time'] = '0000:00:00.00';
    this.state['cmi.core.exit'] = '';
    this.state['cmi.student_data._children'] =
      'mastery_score,time_limit_action,max_time_allowed';
    this.state['cmi.student_data.mastery_score'] = '123';
    this.state['cmi.student_data.max_time_allowed'] = '123';
    this.state['cmi.student_data.time_limit_action'] = '123';

    this.errorString['0'] = 'No error';
    this.errorString['101'] = 'General Exception';
    this.errorString['201'] = 'Invalid Argument Error';
    this.errorString['202'] = 'Element cannot have children';
    this.errorString['203'] = 'Element not an array.  Cannot have count';
    this.errorString['301'] = 'Not initialized';
    this.errorString['401'] = 'Not implemented error';
    this.errorString['402'] = 'Invalid set value, element is a keyword';
    this.errorString['403'] = 'Element is read only';
    this.errorString['404'] = 'Element is write only';
    this.errorString['405'] = 'Incorrect Data Type';
  }

  LMSInitialize(arg) {
    console.log('Initialize', arg);
    this.listener('Initialize');
    return true;
  }
  LMSFinish() {
    console.log('Finished');
    this.listener('Finished');
    return true;
  }
  LMSGetValue(index) {
    this.listener('GetValue', this.state[index]);
    if (!this.state[index]) {
      console.error(`Indice não encontrado: ${index}`);
    }
    return this.state[index];
  }
  LMSSetValue(argName, argValue) {
    this.state = {...this.state, ...{[argName]: argValue}};
    this.listener('SetValue', this.state);
    return argValue;
  }
  LMSCommit() {
    this.listener('Commit').then((value) => {
      console.log('>>>>', value);
    });
    this.listener('Commit');
    return true;
  }
  LMSGetLastError() {
    return this.lastError;
  }
  LMSGetDiagnostic(errorCode) {
    this.listener('Diagnostic', errorCode);
    return errorCode;
  }

  LMSGetErrorString(errorCode) {
    console.error('ERROR IN THE SCORM API: ', errorCode);
    return errorCode;
  }
}

export default ScormApi;
