import styled from 'styled-components';

export const SambaContainer = styled.div``;

export const GoBackButton = styled.button`
  display: flex;
  align-items: center;
  border: none;
  padding: 5px 10px;
  margin-top: 10px;
  border: 1px solid #ccc;
  i {
    margin-right: 8px;
  }
  :hover {
    background-color: #ddd;
  }
`;
