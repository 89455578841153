import React from 'react';
import img from '../../../assets/images/sobre-bg.png';
import {Row} from 'reactstrap';
import {Link} from 'react-router-dom';
import {
  BlockSection,
  TitleSobre,
  BoxSobre,
  Background,
  TextContent,
} from './style';
import Slider from 'react-slick';
import stripTags from 'striptags';

const BlockSobre = (props) => {
  const banners = props.content.banners;
  var settings = {
    dots: false,
    infinite: false,
    slidesToScroll: 1,
  };
  return (
    <BlockSection className="container SobreBlock">
      {/* <Slider {...settings} > */}
      {banners.slice(0, 1).map((banner, i) => (
        <Row key={`banner-${i}`}>
          <TextContent className="col-3 d-flex align-items-start flex-column">
            {banner.title && <TitleSobre>{banner.title}</TitleSobre>}
            <BoxSobre>
              {banner.description && (
                <h3
                  dangerouslySetInnerHTML={{
                    __html: stripTags(banner.description),
                  }}
                />
              )}
              <p>Saiba Mais</p>
              <Link to={banner.url}>
                <i className="far fa-arrow-alt-circle-right" />
              </Link>
            </BoxSobre>
          </TextContent>
          <Background xs="12" className="p-0 conatainer ">
            <img
              src={banner.file !== null ? banner.file.urlPublica : img}
              alt="Sobre"
            />
          </Background>
        </Row>
      ))}
      {/* </Slider> */}
    </BlockSection>
  );
};

export default BlockSobre;
