import React from 'react';
import {Col, Row} from 'reactstrap';
import {Item} from './style';
import userPlaceholder from '../../assets/images/user_placeholder.png';

const RankItem = ({position, avatar, name, score, authenticated}) => {
  let tempPosition = '';

  switch (position) {
    case 1:
      tempPosition = 'first';
      break;
    case 2:
      tempPosition = 'second';
      break;
    case 3:
      tempPosition = 'third';
      break;
    default:
      break;
  }

  return (
    <Item className={`${authenticated ? 'authenticated-border' : ''}`}>
      <Col xs={1} className={`position ${tempPosition}`}>
        {position}
      </Col>
      <Col xs={3} md={1}>
        <img src={avatar || userPlaceholder} alt="" />
      </Col>
      <Col
        xs={5}
        md={7}
        className={`name ${authenticated ? 'authenticated' : ''}`}>
        {name}
      </Col>
      <Col xs={2} className="score">
        {score} pts
      </Col>
      <Col xs={1} className={`medal ${tempPosition}`}>
        <i className="fal fa-medal" />
      </Col>
    </Item>
  );
};

export default RankItem;
