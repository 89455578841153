const UtilElement = {
    hasClass : (el, value) => {
        let element = document.querySelector(el);
        if(element.className.indexOf(value) > -1){
            return true;
        }else{
            return false;
        }   
            
    },
    addClass : (el, value) => {
        let element = document.querySelector(el);
        if(element.className.indexOf(value) === -1){
                document.querySelector(el).className += value;
        }
              
    },
    removeClass : (el, value) => {
        let element = document.querySelector(el);
        if(element.className.indexOf(value) > -1){
            var a = document.querySelector(el).className.replace(value,'');
            document.querySelector('body').className = a;
        }          
    }
}
 
  
export default UtilElement;
  