import React, {useState, useContext, useEffect} from 'react';
import PropTypes from 'prop-types';
import {QuestionStyle, QuestionActions, UserPhotoStyle} from './styles';
import {Col} from 'reactstrap';
import stripTags from 'striptags';
import NewQuestion from './NewQuestion';
import EditQuestion from './EditQuestion';
import format from '../../../utils/format';
import api from '../../../services/api';
import ContainerLoading from '../../Theme/Loading';
import {HandleRefreshContext} from './handleRefreshContext';

const Question = ({pergunta, ...props}) => {
  //const [newAnswer, setNewAnswer] = useState(false);
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState(false);
  const [delet, setDelete] = useState(false);
  const [expand, setExpand] = useState(false);
  const {handleRefreshComments} = useContext(HandleRefreshContext);
  const [deleting, setDeleting] = useState(false);
  /*const user = {
    photo: atob(localStorage.getItem(`user-photo`)),
    nome: atob(localStorage.getItem(`user-name`)),
  };*/
  useEffect(() => {
    setTimeout(() => {
      setLoading(true);
    }, 1000);
  });

  function handleDelete() {
    setDeleting(true);
    api
      .delete(pergunta._id)
      .then(() => {
        handleRefreshComments();
        setDelete(false);
        setExpand(false);
        setDeleting(false);
      })
      .catch((error) => {
        handleRefreshComments();
        setDelete(false);
        setExpand(false);
        setDeleting(false);
      });
  }

  return loading === false ? (
    <ContainerLoading />
  ) : (
    <>
      <QuestionStyle className="row d-flex justify-content-between my-3">
        {/*
            <UserPhotoStyle className="col-2 col-md-1 pr-0">
              <div className="user-img">
                {typeof pergunta.user.photo != 'undefined' && (
                  <img src={pergunta.user.photo} alt={pergunta.user.name} />
                )}
              </div>
            </UserPhotoStyle>
        */}
        <div className="col-12 box-question">
          <div className="content-question ">
            <Col
              lg="12"
              className={`headerQuestion p-0 d-flex align-items-md-center ${
                expand ? 'mb-md-3' : ''
              }`}>
              {
                //<h4>{pergunta.user.name}</h4>
              }
              <span className="data d-flex align-items-center">
                {format.data(pergunta.data, 'numberDay')}{' '}
                {format.data(pergunta.data, 'stringMonth').slice(0, 3)}{' '}
                {format.data(pergunta.data, 'numberYear')} {' | '}
                {format.data(pergunta.data, 'hour')}{' '}
              </span>
              <p
                className="preview-anotation"
                dangerouslySetInnerHTML={{__html: pergunta.content}}
              />
              <div
                className={`d-flex align-items-center mt-3 mt-md-0 anotationsActions ${
                  expand ? 'mb-3 mb-md-0' : ''
                }`}>
                {!delet ? (
                  <>
                    {expand && (
                      <>
                        <div className="mr-4 my-0 d-flex align-items-center">
                          <span>
                            Editar{' '}
                            <i
                              className="fas fa-edit ml-2"
                              onClick={() => setEdit(!edit)}
                            />
                          </span>
                        </div>
                        <div className="mr-4 my-0 d-flex align-items-center">
                          <span>
                            Excluir{' '}
                            <i
                              className="fas fa-trash-alt ml-2"
                              onClick={() => setDelete(!delet)}
                            />
                          </span>
                        </div>
                      </>
                    )}
                    <div className="div-expand">
                      <i
                        className={`fal fa-${
                          expand ? 'minus' : 'plus'
                        }-circle mr-1 buttonExpand`}
                        onClick={() => setExpand(!expand)}
                      />
                    </div>
                  </>
                ) : (
                  <div className="anotationsActions">
                    <button
                      className="button buttonConfirm py-1 mx-2 px-2 px-md-3 mx-md-2 px-lg-4"
                      onClick={handleDelete}
                      disabled={deleting}>
                      {!deleting ? 'Confirmar' : 'Apagando...'}
                      <i className={`far fa-check-circle mr-1`} />
                    </button>
                    <button
                      className="button buttonCancel py-1 mx-2 px-2 px-md-3 mx-md-2 px-lg-4"
                      onClick={() => setDelete(false)}
                      disabled={deleting}>
                      Cancelar <i className={`far fa-times-circle mr-1`} />
                    </button>
                  </div>
                )}
              </div>
            </Col>
            {expand && (
              <>
                {!edit ? (
                  <>
                    <div className="questionContent">
                      <p dangerouslySetInnerHTML={{__html: pergunta.content}} />
                    </div>
                  </>
                ) : (
                  <EditQuestion pergunta={pergunta} setEdit={setEdit} />
                )}
              </>
            )}
          </div>
          {/*<QuestionActions className="mt-2">
            {pergunta.question &&
              <button
                onClick={() => setNewAnswer((prev) => !prev)}
                className="btn-resp">
                Responder
            </button>}
            {pergunta.answers.length > 0 ? (
              <label htmlFor={`answer-${props.index}`} className="btn-see-resp">
                Ver Respostas ({pergunta.answers.length}){' '}
                <i className="fas fa-sort-down" />
              </label>
            ) : (
                ''
              )}
          </QuestionActions>
          <input
            id={`answer-${props.index}`}
            type="checkbox"
            value="answer"
            className="d-none"
          />
          {pergunta.answers.length > 0 && (
            <span className={`mr-5 sub-resp ${newAnswer ? 'active' : ''}`}>
              <NewQuestion
                answer={true}
                parent={pergunta.id}
                lesson_id={pergunta.lesson_id}
                user={user}
              />
              {pergunta.answers.map((sub_perg, i) => (
                <Question key={`qt-${i}`} pergunta={sub_perg} />
              ))}
            </span>
          )}
          {pergunta.answers.length <= 0 && (
            <span className={`mr-5 sub-resp ${newAnswer ? 'active' : ''}`}>
              <NewQuestion
                answer={true}
                parent={pergunta.id}
                lesson_id={pergunta.lesson_id}
                user={user}
              />
            </span>
          )}*/}
        </div>
      </QuestionStyle>
    </>
  );
};
Question.propTypes = {
  pergunta: PropTypes.object.isRequired,
  index: PropTypes.string,
};

export default Question;
